import { useState } from 'react';
import { Form, InputGroup, Dropdown } from 'react-bootstrap';
import { items } from './components/itemsHomeMenu';
import './homePage.css';
import { useAuth } from '../../../app/modules/auth';
import { useNavigate } from 'react-router-dom';

// Função para organizar permissões por 'arquivo'
function organizaPermissoes(permissions) {
  const permissionMap = {};
  permissions.forEach((permission) => {
    permissionMap[permission.arquivo] = true;
  });
  return permissionMap;
}

export function HomePage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const [openSubMnu, setOpenSubMnu] = useState<number | null>(null);

  // Filtrando as permissões
  const permissoes = currentUser?.usuariosPermissoes || [];
  const permissionMap = organizaPermissoes(permissoes);

  // Função para renderizar itens com base na permissão
  const renderMenuItemIfPermission = (permissions, menuItem) => {
    // Se o usuário for ADMIN, retornar o item sem verificar permissões
    if (currentUser?.tipouser === 'ADMIN') {
      return menuItem;
    }

    // Se não houver permissões para o item, permitir que todos vejam
    if (!permissions || permissions.length === 0) {
      return menuItem; // Todos podem ver
    }

    // Verificar se o usuário tem permissão
    const hasPermission = permissions.some(permission => permissionMap[permission]);
    return hasPermission ? menuItem : null;
  };


  // Filtrando itens com base no termo de busca
  const filteredItems = items.filter((item) => {
    const matchesMainItem = item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSubMenu = item.subMenu && item.subMenu.some(subItem =>
      subItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return matchesMainItem || matchesSubMenu;
  });

  return (
    <div className="home-page-container">
      <InputGroup className="mb-3 search-bar">
        <Form.Control
          placeholder="Pesquisar..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          aria-label="Pesquisar"
          aria-describedby="basic-addon1"
        />
        <span className="input-group-text" id="basic-addon1">
          <i className="ki-solid ki-search-list fs-1" />
        </span>
      </InputGroup>

      <div className="card-grid">
        {filteredItems.map((item) =>
          renderMenuItemIfPermission(item.permissions, (
            <a
              href={item.path || '#'}
              key={item.id}
              className="card hover-elevate-up shadow-sm parent-hover quick-access-card"
              onMouseEnter={() => {
                setOpenSubMenu(item.id);
              }}
              onMouseLeave={() => {
                setOpenSubMenu(null);
              }}
            >
              <div className="card-body d-flex align-items">
                <span className={`svg-icon fs-1 ki-solid ${item.icon}`} />
                <span className="text-gray-700 parent-hover-primary fs-6 fw-bold">
                  {item.name}
                </span>

                {item.subMenu && (
                  <Dropdown show={openSubMenu === item.id}>
                    <Dropdown.Menu style={{ position: 'absolute', zIndex: 1050 }}>
                      {item.subMenu.map((subItem) =>
                        renderMenuItemIfPermission(subItem.permissions || [], (
                          <div key={subItem.id} style={{ position: 'relative' }}>
                            <Dropdown.Item
                              onClick={() => {
                                navigate(subItem.path || '#');
                              }}
                              onMouseEnter={() => {
                                if (subItem.subMnu) {
                                  setOpenSubMnu(subItem.id);
                                } else {
                                  setOpenSubMnu(null);
                                }
                              }}
                            >
                              {subItem.name}
                            </Dropdown.Item>

                            {
                              subItem.subMnu && (
                                <Dropdown show={openSubMnu === subItem.id} align="end">
                                  <Dropdown.Menu style={{ position: 'absolute', left: '100%', top: '0', zIndex: 1050 }}>
                                    {subItem.subMnu.map(subMnuItem =>
                                      renderMenuItemIfPermission(subMnuItem.permissions, (
                                        <Dropdown.Item
                                          key={subMnuItem.id}
                                          onClick={() => {
                                            navigate(subMnuItem.path);
                                          }}
                                        >
                                          {subMnuItem.name}
                                        </Dropdown.Item>
                                      ))
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )
                            }
                          </div>
                        ))
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </a>
          ))
        )}
      </div>
    </div >
  );
}