import {Table, TabContainer, Modal, Button, Card, Row, Col, Container} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../../../modules/auth';
import React, {useState, useEffect, useRef} from 'react';
import InputMask from 'react-input-mask';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Swal from 'sweetalert2';

import {TableProdutosPrevenda} from './components/TableProdutosProposta';
import {TableFormasDePagamento} from './components/TableFormasDePagamento';
import {submitFormPrevenda} from '../../useUtils/useSubmitFormPrevenda';

import {
  ClienteOption,
  AmbienteOption,
  ProdutoInfo,
  Estoque,
  FormaPagamentoInfo,
  TabelaDadosItens,
  TabelaDadosFormasPagamento,
  formPreVenda,
  preVendaValidationSchema,
} from './types/interface';
import {calcularEstoqueTotal, fetchInfoProduto} from './service/propostaPedido';
import useOptionsTipoEntrega from '../../selectOptions/useOptionsTipoEntrega';
import useBuscaClienteSelect from '../../useUtils/useBuscaClienteSelect';
import useBuscaClienteCredito from '../../useUtils/useBuscaClienteCredito';
import useOptionsOrigemPrevenda from '../../selectOptions/useOptionsOrigemPrevenda';
import useOptionsAlmoxarifado from '../../selectOptions/useOptionsAlmoxarifado';
import useOptionsFormaPagamento from '../../selectOptions/useOptionsFormaPagamento';
import useOptionsTabelaDePrecoProduto from '../../selectOptions/useOptionsTabelaDePrecoProduto';
import useOptionsCondicoesPagamento from '../../selectOptions/useOptionsCondicoesPagamento';
import useOptionsEmpresas from '../../selectOptions/useOptionsEmpresas';
import {fetchTipoProdutoOptions} from '../../selectOptions/useOptionsTipoProduto';
import fetchMarcaProductOptions from '../../selectOptions/useOptionsMarcaProduto';
import useBuscaParceiro from '../../useUtils/useBuscaParceiro';
import useBuscaCloser from '../../useUtils/useBuscaCloser';
import useBuscaOrcament from '../../useUtils/useBuscaOrcament';
import useBuscaCepPreVenda from '../../useUtils/useBuscaCepPrevenda';
import {DropdownMultipleChoice, IProduto} from '../../../shared';
import {AsyncSelectConsultor, AsyncSelectProduct, AsyncSelectCliente} from '../../../shared';
import {formatarData} from '../../../shared/core';
import './styles/PropostaPedidoCSS.scss';
import icon_img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg';
import useOptionsTipoVenda from '../../selectOptions/useOptionsTipoVenda';
import {reduceHooks} from 'react-table';

export function CadastrarPrevenda({tipo}) {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {currentUser} = useAuth();
  const {optionsTipoEntrega} = useOptionsTipoEntrega();
  const {options} = useOptionsEmpresas();
  const {id} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingPayload, setLoadingPayload] = useState(false);

  const [obsStatus, setObsStatus] = useState(0);
  const [obsStatusPrd, setObsStatusPrd] = useState(0);
  const [produtoNaoEncontradoStatus, setProdutoNaoEncontradoStatus] = useState(1);
  const [pagamentoPrincipal, setPagamentoPrincipal] = useState(0);
  const [projetoStatus, setProjetoStatus] = useState(0);
  const [freteStatus, setFreteStatus] = useState(0);
  const [isEntregaParcial, setIsEntregaParcial] = useState(1);
  const [precoPadrao, setPrecoPadrao] = useState(0);
  const [idTipoVenda, setIdTipoVenda] = useState(1);

  //CLIENTE//
  const [selectedCliente, setSelectedCliente] = useState<ClienteOption | null>(null);
  const [showFiltroCliente, setShowFiltroCliente] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState('clienteFantasia');

  //CONSULTOR//
  const [selectedConsultor, setSelectedConsultor] = useState<ClienteOption | null>(null);

  const [showFiltroConsultor, setShowFiltroConsultor] = useState(false);
  const [filtroConsultor, setFiltroConsultor] = useState('funcionario');
  // CLOSER //
  const [selectedCloser, setSelectedCloser] = useState<ClienteOption | null>(null);
  const [showFiltroCloser, setShowFiltroCloser] = useState(false);
  const [filtroCloser, setFiltroCloser] = useState('closer');
  // PARCEIRO //
  const [selectedParceiro, setSelectedParceiro] = useState<ClienteOption | null>(null);
  const [showFiltroParceiro, setShowFiltroParceiro] = useState(false);
  const [filtroParceiro, setFiltroParceiro] = useState('parceiro');
  // ORCAMENTISTA //
  const [selectedOrcamentista, setSelectedOrcamentista] = useState<ClienteOption | null>(null);
  const [showFiltroOrcamentista, setShowFiltroOrcamentista] = useState(false);
  const [filtroOrcamentista, setFiltroOrcamentista] = useState('orcamentista');

  // PRODUTO //
  const [selectedProductAsync, setSelectedProductAsync] = useState<ClienteOption | null>(null);
  const [showFiltroProduto, setShowFiltroProduto] = useState(false);
  const [filtroProdutos, setFiltroProdutos] = useState('produto');
  // AMBIENTE //
  const [selectedAmbienteAsync, setSelectedAmbienteAsync] = useState<ClienteOption | null>(null);
  const [showFiltroAmbiente, setShowFiltroAmbiente] = useState(false);
  const [filtroAmbiente, setFiltroAmbiente] = useState('ambiente');
  const [infosPagamento, setInfosPagamento] = useState<FormaPagamentoInfo[]>([]);
  const [valorAReceber, setValorAReceber] = useState(0);

  const [exibirProjetos, setExibirProjetos] = useState(true);
  const [iconeProjetos, setIconeProjetos] = useState('bi bi-arrow-down-square-fill');

  const [exibirDadosEntrega, setExibirDadosEntrega] = useState(true);
  const [iconeDadosEntrega, setIconeDadosEntrega] = useState('bi bi-arrow-down-square-fill');

  const [exibirDestinatario, setExibirDestinatario] = useState(true);
  const [iconeDestinarario, setIconeDestinarario] = useState('bi bi-arrow-down-square-fill');

  const [isPrecoUnitarioEditable, setIsPrecoUnitarioEditable] = useState(false);
  const [quantidade, setQuantidade] = useState(0);
  const [estoque, setEstoque] = useState<Estoque[]>([]);
  const [marca, setMarca] = useState('');
  const [unidade, setUnidade] = useState('');
  const [precoCusto, setPrecoCusto] = useState<number | null>(null);
  const [margem, setMargem] = useState<number | null>(null);
  const [margemTotal, setMargemTotal] = useState(0);
  const [precoCustoTotal, setPrecoCustoTotal] = useState(0);
  const [temCustoNegativo, setTemCustoNegativo] = useState(false);
  const [descontoProdutoSelecionado, setDescontoProdutoSelecionado] = useState(0.0);
  const [precoUnitarioProdutoSelecionado, setPrecoUnitarioProdutoSelecionado] = useState('0.00');
  const [tipoProduto, setTipoProduto] = useState('');
  const [totalProdutoSelecionado, setTotalProdutoSelecionado] = useState(0);
  const [estoqueRestante, setEstoqueRestante] = useState(0);
  const [observacaoProduto, setObservacaoProduto] = useState('');
  const [totalGeral, setTotalGeral] = useState(0);
  const [tabelaDados, setTabelaDados] = useState<TabelaDadosItens[]>([]);
  const [valorEspecial, setValorEspecial] = useState(0.0);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductValue, setSelectedProductValue] = useState<number | null>(null);
  const [selectedProductDetails, setSelectedProductDetails] = useState<ProdutoInfo | null>(null);
  const [infosProd, setInfosProd] = useState<ProdutoInfo[]>([]);
  const [estoqueTotal, setEstoqueTotal] = useState<number>(0);
  const [selectedAmbiente, setSelectedAmbiente] = useState({id: '', desc: ''});
  const [ambienteComplemento, setAmbienteComplemento] = useState('');
  const [ordenacao, setOrdenacao] = useState(0);
  const [updateProduto, setUpdateProduto] = useState(false);
  const [productOrder, setProductOrder] = useState(0);
  const [showChangeSkuButton, setShowChangeSkuButton] = useState(false);

  const [innerFlow, setInnerFlow] = useState(false);
  const [showModalInnerFlow, setShowModalInnerFlow] = useState(false);
  const empresaPadrao = currentUser?.usuarioEmpresaPadraoLogin?.codigoEmpresa;

  const [filterProduct, setFilterProduct] = useState(0);
  const [products, setProducts] = useState([]);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const tableEndRef = useRef(null);

  const [optionsMarca, setOptionsMarca] = useState<{value: string; label: string}[]>([]);
  const [optionsTipoProduto, setOptionsTipoProduto] = useState<{value: string; label: string}[]>(
    []
  );

  const [selectedOptionsMarca, setSelectedOptionsMarca] = useState<string[]>([]);
  const [selectedOptionsTipoProduto, setSelectedOptionsTipoProduto] = useState<string[]>([]);
  const [selectedOptionFaixaPreco, setSelectedOptionFaixaPreco] = useState<string | null>(null);
  const [selectedOptionLinhaProduto, setSelectedOptionsLinhaProduto] = useState<string | null>(
    null
  );

  const [isOpenMarca, setIsOpenMarca] = useState(false);
  const [isOpenTipoProduto, setIsOpenTipoProduto] = useState(false);
  const [isOpenFaixaPreco, setIsOpenFaixaPreco] = useState(false);
  const [isOpenLinhaProduto, setIsOpenLinhaProduto] = useState(false);

  const statusMappingFaixaPreco = [
    {value: '1', label: '< 10'},
    {value: '2', label: '11 - 20'},
    {value: '3', label: '21 - 50'},
    {value: '4', label: '51 - 100'},
    {value: '5', label: '101 - 300'},
    {value: '6', label: '> 300'},
  ];

  // AINDA VAI SER IMPLEMENTADO
  const statusMappingLinhaProduto = [
    {value: '1', label: 'Linha 1'},
    {value: '2', label: 'Linha 2'},
    {value: '3', label: 'Linha 3'},
    {value: '4', label: 'Linha 4'},
    {value: '5', label: 'Linha 5'},
    {value: '6', label: 'Linha 6'},
  ];

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setPage(0);
        const statusMappingMarca = await fetchMarcaProductOptions();
        const mappedOptionsMarca = statusMappingMarca.map(({value, label}) => ({
          value: String(value),
          label: String(label),
        }));
        setOptionsMarca(mappedOptionsMarca);

        const statusMappingtipoProduto = await fetchTipoProdutoOptions();
        const mappedOptionsTipoProduto = statusMappingtipoProduto.map(({value, label}) => ({
          value: String(value),
          label: String(label),
        }));
        setOptionsTipoProduto(mappedOptionsTipoProduto);
      } catch (err) {
        console.error(err);
      }
    };
    fetchOptions();
  }, []);

  const toggleDropdown = (setter) => setter((prev) => !prev);

  const handleSelectMarca = (option) => {
    setPage(0);
    setSelectedOptionsMarca((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleSelectTipoProduto = (option) => {
    setPage(0);
    setSelectedOptionsTipoProduto((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleSelectFaixaPreco = (option) => {
    setPage(0);
    setSelectedOptionFaixaPreco(option); // Atualiza a opção de faixa de preço selecionada
  };

  const handleSelectLinhaProduto = (option) => {
    setPage(0);
    setSelectedOptionsLinhaProduto(option); // Atualiza a opção de linha de produto selecionada
  };

  const [filtrarEncarte, setFiltrarEncarte] = useState(false);
  const handleToggleFiltrarEncarte = () => {
    setPage(0);
    setFiltrarEncarte(!filtrarEncarte);
  };

  const [filtrarEstoque, setFiltrarEstoque] = useState(false);
  const handleToggleFiltrarEstoque = () => {
    setPage(0);
    setFiltrarEstoque(!filtrarEstoque);
  };

  const [filtrarPromocao, setFiltrarPromocao] = useState(false);
  const handleToggleFiltrarPromocao = () => {
    setPage(0);
    setFiltrarPromocao(!filtrarPromocao);
  };

  const loadMore = () => {
    // Incrementa o valor da página, carregando a próxima página de dados
    setPage((prevPage) => prevPage + 1);
  };

  // Hook useEffect que será executado sempre que o valor da página (page) mudar
  useEffect(() => {
    // Verifica se a página não é a inicial (0), para evitar carregamentos desnecessários na primeira renderização
    if (page !== 0) {
      asyncFilterProducts();
    }
  }, [page]);

  const asyncFilterProducts = async () => {
    setHasMore(true);

    // Mapear os ids selecionados das marcas e tipos de produto
    const idsMarcas = selectedOptionsMarca.map((option) => parseInt(option));
    const idsTipoProduto = selectedOptionsTipoProduto.map((option) => parseInt(option));

    let precoInicial = 0;
    let precoFinal = 1000000;
    switch (selectedOptionFaixaPreco) {
      case '1':
        precoInicial = 0;
        precoFinal = 10;
        break;
      case '2':
        precoInicial = 11;
        precoFinal = 20;
        break;
      case '3':
        precoInicial = 21;
        precoFinal = 50;
        break;
      case '4':
        precoInicial = 51;
        precoFinal = 100;
        break;
      case '5':
        precoInicial = 101;
        precoFinal = 300;
        break;
      case '6':
        precoInicial = 300;
        precoFinal = 1000000;
        break;
      default:
        precoInicial = 0;
        precoFinal = 1000000;
    }

    // Parâmetros adicionais
    const isEncarte = filtrarEncarte;
    const hasEstoque = filtrarEstoque;

    // Construir a URL com os parâmetros
    const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscar`;

    // Criar uma instância de URLSearchParams
    const params = new URLSearchParams();

    // Adicionar idsMarcas
    idsMarcas.forEach((id) => params.append('idsMarcas', id.toString()));

    // Adicionar idsTipoProduto
    idsTipoProduto.forEach((id) => params.append('idsTipoProduto', id.toString()));

    // Adicionar outros parâmetros
    params.append('precoInicial', precoInicial.toString());
    params.append('precoFinal', precoFinal.toString());
    params.append('isEncarte', isEncarte.toString());
    params.append('hasEstoque', hasEstoque.toString());
    params.append('page', page.toString());

    try {
      const response = await axios.get(url, {params});

      if (response.status === 200) {
        // Atualiza o estado dos produtos com os dados retornados
        // Se a página for 0, substitui o estado anterior, caso contrário, concatena os novos produtos
        setProducts((prev) =>
          page === 0 ? response.data.content : [...prev, ...response.data.content]
        );

        // Verifica se há mais páginas disponíveis para carregar
        setHasMore(page < response.data.totalPages - 1);
      } else if (response.status === 204) {
        // Limpa a lista de produtos e define que não há mais itens para carregar
        setProducts([]);
        setHasMore(false);

        Swal.fire({
          icon: 'info',
          title: `Não existe registros para o filtro de busca selecionado`,
          timer: 3000,
          timerProgressBar: true,
        });
      } else if (response.status === 500) {
        // Limpa a lista de produtos e define que não há mais itens para carregar
        setProducts([]);
        setHasMore(false);

        Swal.fire({
          icon: 'error',
          title: `Erro ao buscar produtos com os filtros selecionado`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      // Em caso de erro na requisição, limpa a lista de produtos e define que não há mais itens para carregar
      setProducts([]);
      setHasMore(false);

      Swal.fire({
        icon: 'error',
        title: `Erro ao buscar produtos com os filtros selecionado`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.error(
        'Error fetching filtered products:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const tipoEntregaPadrao = optionsTipoEntrega.find(
    (option) => Number(option.tipoEntregaPadrao) === 1
  );
  const lojaOrigemPadrao = options.find((option) => Number(option.value) === empresaPadrao);

  const [selectedTipoEntrega, setSelectedTipoEntrega] = useState({id: '', desc: ''});
  const [selectedLojaOrigem, setSelectedLojaOrigem] = useState({id: empresaPadrao || '', desc: ''});

  const [selectedTipoEntregaTodos, setSelectedTipoEntregaTodos] = useState({id: '', desc: ''});
  const [selectedLojaOrigemTodos, setSelectedLojaOrigemTodos] = useState({
    id: empresaPadrao || '',
    desc: '',
  });

  const [selectedTabelaDePrecoProduto, setSelectedTabelaDePrecoProduto] = useState({
    id: '',
    desc: '',
  });

  const [editingProductIndex, setEditingProductIndex] = useState<number | null>(null);
  const [showModalEstoque, setShowModalEstoque] = useState(false);
  const [showModalConfirmEntrega, setShowModalConfirmEntrega] = useState(false);
  const [totalBruto, setTotalBruto] = useState(0);
  const [valorRecebido, setValorRecebido] = useState('0.00');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [editing, setEditing] = useState(false);

  const [tabelaDadosPagamento, setTabelaDadosPagamento] = useState<TabelaDadosFormasPagamento[]>(
    []
  );
  // const [ativaMargem, setAtivaMargem] = useState<number>(1);

  // const buscaCliente = useBuscaClienteSelect();
  // const buscaSaldoCliente = useBuscaClienteCredito();
  const {optionsOrigemPrevenda} = useOptionsOrigemPrevenda();
  const {optionsTipoVenda} = useOptionsTipoVenda();
  const {optionsAlmoxarifado} = useOptionsAlmoxarifado();
  const {optionsFormaPagamento} = useOptionsFormaPagamento();
  const {optionsCondicoesPagamento} = useOptionsCondicoesPagamento();
  const {optionsTabelaDePrecoProduto, limparOpcoesTabelaPrecoProduto} =
    useOptionsTabelaDePrecoProduto(selectedProductId);

  const formik = useFormik({
    initialValues: formPreVenda,
    validationSchema: preVendaValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      submitFormPrevenda(
        id,
        values,
        selectedCliente,
        selectedParceiro,
        selectedCloser,
        selectedConsultor,
        selectedOrcamentista,
        infosPagamento,
        infosProd,
        currentUser,
        totalGeral,
        setLoading,
        valorAReceber,
        obsStatus,
        // obsStatusPrd,
        freteStatus,
        projetoStatus,
        produtoNaoEncontradoStatus,
        setSubmitting,
        setStatus,
        navigate,
        tipo,
        ordenacao,
        margemTotal,
        updateProduto,
        innerFlow,
        isEntregaParcial,
        idTipoVenda
      );
    },
  });

  const tipoEntregaParaTodos = () => {
    const novoInfoProd = infosProd.map((infoProduto) => ({
      ...infoProduto,
      tipoEntregaDesc: selectedTipoEntregaTodos.desc,
      tipoEntregaId: Number(selectedTipoEntregaTodos.id),
      idloja: Number(selectedLojaOrigemTodos.id),
      descloja: selectedLojaOrigemTodos.desc,
    }));

    const filteredInfosProd = infosProd.filter(
      (infoProduto) => !novoInfoProd.some((newInfo) => newInfo.idProduto === infoProduto.idProduto)
    );
    setInfosProd((prevInfosProd) => [...filteredInfosProd, ...novoInfoProd]);
    setShowModalConfirmEntrega(false);
  };

  //HANDLES

  const handlePagamentoPrincipal = () => {
    setPagamentoPrincipal((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleProjetoStatus = () => {
    setProjetoStatus((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleObsStatus = () => {
    setObsStatus((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleObsStatusPrd = () => {
    setObsStatusPrd((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleFilterProduct = () => {
    setFilterProduct((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleInnerFlow = () => {
    if (!innerFlow) {
      // Só abre o modal quando estiver ativando (marcando o checkbox)
      setShowModalInnerFlow(true);
    } else {
      // Quando desativar (desmarcar o checkbox), muda diretamente sem mostrar o modal
      setInnerFlow(false);
    }
  };

  const handleFreteStatus = () => {
    setFreteStatus((prevState) => (prevState === 0 ? 1 : 0));

    if (freteStatus === 1) {
      formik.setFieldValue('valorFrete', 0.0);
    }
  };

  const handleEntregaParcial = () => {
    setIsEntregaParcial((prevState) => (prevState === 1 ? 0 : 1));
  };

  const handleStatusSelectChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleCifFob = (event) => {
    formik.setFieldValue('cifFob', event.target.value);
  };
  const handleSelectChange = (event, fieldName) => {
    formik.setFieldValue(fieldName, event.target.value);
  };

  const handleRemoveProduct = (ordem: number) => {
    setUpdateProduto(true);
    setInfosProd((prevInfosProd) =>
      prevInfosProd.filter((produto) => produto.ordem.toString() !== ordem.toString())
    );
    handleStopEditing();
  };

  const handleRemovePagamento = (index: number) => {
    setInfosPagamento((prevInfosPagamento) => prevInfosPagamento.filter((_, i) => i !== index));
  };

  const handleProdutoNaoEncontradoStatus = () => {
    setProdutoNaoEncontradoStatus((prevState) => (prevState === 1 ? 0 : 1));

    if (freteStatus === 1) {
      formik.setFieldValue('produtoNaoEncontrado', '');
    }
  };

  // const handleFiltroProdutoCheckChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   if (e.target.value === 'produto') {
  //     setFiltroProdutos(e.target.value);
  //     // setShowFiltroProduto(false)
  //   } else if (e.target.value === '0') {
  //     // setShowFiltroProduto(true)
  //   }
  // };

  const handleFiltroConsultorCheckChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'idFuncionario' || e.target.value === 'funcionario') {
      setFiltroConsultor(e.target.value);
      //setShowFiltroConsultor(false)
    } else if (e.target.value === '0') {
      //setShowFiltroConsultor(true)
    }
  };

  const handleFiltroParceiroCheckChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'idParceiro' || e.target.value === 'parceiro') {
      setFiltroParceiro(e.target.value);
    } else if (e.target.value === '0') {
      //setShowFiltroParceiro(true)
    }
  };

  const handleFiltroCloserCheckChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'idCloser' || e.target.value === 'closer') {
      setFiltroCloser(e.target.value);
    } else if (e.target.value === '0') {
    }
  };

  const handleFiltroOrcamentistaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'idOrcamentista' || e.target.value === 'orcamentista') {
      setFiltroOrcamentista(e.target.value);
    } else if (e.target.value === '0') {
      //setShowFiltroOrcamentista(true);
    }
  };

  const handleTotalChange = (newPrecoUnitario, newQuantidade, newDesconto) => {
    const quantidadeAtual = newQuantidade !== undefined ? newQuantidade : quantidade;
    const descontoAtual = newDesconto !== undefined ? newDesconto : descontoProdutoSelecionado;

    if (newPrecoUnitario !== undefined && newPrecoUnitario >= 0) {
      setPrecoUnitarioProdutoSelecionado(newPrecoUnitario);
      if (selectedProductDetails) {
        setSelectedProductDetails({
          ...selectedProductDetails,
          obsproduto: observacaoProduto,
          precoUnitario: newPrecoUnitario,
          quantidade: quantidadeAtual,
          descontoProduto: descontoAtual,
          total: newPrecoUnitario * quantidadeAtual - descontoAtual,
        });
      }
    }

    if (newQuantidade !== undefined && newQuantidade >= 0) {
      setQuantidade(newQuantidade);
    }

    if (newDesconto !== undefined && newDesconto >= 0) {
      setDescontoProdutoSelecionado(newDesconto);
    }

    calculaTotal(quantidadeAtual, newPrecoUnitario, descontoAtual);
  };

  const handleTipoEntregaChangeTodos = (event) => {
    const tipoEntregaId = event.target.value;
    const tipoEntregaDesc = event.target.options[event.target.selectedIndex].text;

    if (tipoEntregaDesc === 'Selecione') {
      setSelectedTipoEntregaTodos({id: '', desc: ''});
    } else {
      setSelectedTipoEntregaTodos({id: tipoEntregaId, desc: tipoEntregaDesc});
    }
  };

  const handleTipoEntregaChange = (event) => {
    const tipoEntregaId = event.target.value;
    const tipoEntregaDesc = event.target.options[event.target.selectedIndex].text;

    if (tipoEntregaDesc === 'Selecione') {
      setSelectedTipoEntrega({id: '', desc: ''});
    } else {
      setSelectedTipoEntrega({id: tipoEntregaId, desc: tipoEntregaDesc});
    }
  };

  const handleLojaOrigemChange = (event) => {
    const lojaOrigemId = event.target.value;
    const descLojaOrigem = event.target.options[event.target.selectedIndex].text;

    if (lojaOrigemId === '') {
      setSelectedLojaOrigem({id: '', desc: ''});
    } else {
      setSelectedLojaOrigem({id: lojaOrigemId, desc: descLojaOrigem});
    }
  };

  const handleLojaOrigemChangeTodos = (event) => {
    const lojaOrigemId = event.target.value;
    const lojaOrigemDesc = event.target.options[event.target.selectedIndex].text;

    if (lojaOrigemId === '') {
      setSelectedLojaOrigemTodos({id: '', desc: ''});
    } else {
      setSelectedLojaOrigemTodos({id: lojaOrigemId, desc: lojaOrigemDesc});
    }
  };

  const handleChangeAmbiente = (selectedOption: AmbienteOption | null) => {
    setSelectedAmbienteAsync(selectedOption);
    if (selectedOption) {
      setSelectedAmbiente({
        id: selectedOption.value.toString(),
        desc: selectedOption.label,
      });
    } else {
      setSelectedAmbiente({id: '', desc: ''});
    }
  };

  const handleComplementoAmbiente = (event) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setAmbienteComplemento('');
    } else {
      setAmbienteComplemento(inputValue);
    }
  };

  const handleTabelaDePrecoProduto = (event) => {
    const selectedTabelaPrecoId = event.target.value;
    const selectedTabelaPrecoDesc = event.target.options[event.target.selectedIndex].text;

    if (selectedTabelaPrecoId === '' && selectedTabelaPrecoDesc === 'Selecione') {
      setSelectedTabelaDePrecoProduto({id: '', desc: ''});
    } else {
      setSelectedTabelaDePrecoProduto({id: selectedTabelaPrecoId, desc: selectedTabelaPrecoDesc});
    }

    const tabelaPrecoSelecionada = optionsTabelaDePrecoProduto.find(
      (option) => option.value === selectedTabelaPrecoId
    );

    if (tabelaPrecoSelecionada) {
      const precoUnitarioTable = tabelaPrecoSelecionada.precoVenda;

      setSelectedProductDetails((prevDetails) => {
        const safePrevDetails = prevDetails || {};
        return {
          ...safePrevDetails,
          precoUnitario: precoUnitarioTable,
          quantidade: 0,
        } as ProdutoInfo;
      });

      setQuantidade(0);
      setTotalProdutoSelecionado(0);
    }
  };

  const calculoMargem = (valorVenda, valorCusto, quantidade) => {
    if (infosProd) {
      const precoVenda = valorVenda;
      const precoCusto = valorCusto * quantidade;
      const diferencaPrecos = precoVenda - precoCusto;
      const calculoMargem = (diferencaPrecos / precoVenda) * 100;
      const valorTruncado = truncarDuasCasasDecimais(calculoMargem);

      setMargem(valorTruncado);
    }
  };

  const handleObsProduto = (event) => {
    setObservacaoProduto(event.target.value);
  };

  // Função que lida com a mudança do SKU (identificação do produto)
  const handleChangeSKU = (event, product) => {
    document.getElementById('section1')?.scrollIntoView({behavior: 'smooth'});

    event?.preventDefault();

    setEditing(false);

    setShowChangeSkuButton(true);

    setSelectedProductId(product.idProduto);

    setSelectedProductDetails(product);

    setSelectedProductAsync({value: product.idProduto, label: product.produto});

    setAmbienteComplemento(product.ambienteComplemento || '');

    setSelectedAmbienteAsync({value: product.ambienteId, label: product.ambienteDesc});

    setSelectedAmbiente(
      product.ambienteId ? {id: product.ambienteId, desc: product.ambiente} : {id: '', desc: ''}
    );

    setSelectedTipoEntrega(
      product.tipoEntregaId
        ? {id: product.tipoEntregaId, desc: product.tipoEntregaDesc}
        : {id: '', desc: ''}
    );

    setSelectedLojaOrigem(
      product.idloja ? {id: product.idloja, desc: product.descloja} : {id: '', desc: ''}
    );

    setEstoqueTotal(product.estoqueTotal || 0);

    setObservacaoProduto(product.obsproduto);

    setObsStatusPrd(product.imprimirObservacaoProduto ? 1 : 0);

    setProductOrder(product.ordem);

    setPrecoPadrao(Number(product.precoPadrao) || 0);

    calculaTotal(product.quantidade, product.precoUnitario, product.descontoProduto);
  };

  // Função chamada ao clicar no botão para confirmar a mudança do SKU
  const handleChangeSkuButton = () => {
    setUpdateProduto(true);
    // Informa que teve uma atualização de produtos, para ser reenviado para o endpoint

    const quantidadeFormatada = Number(quantidade);

    if (
      quantidadeFormatada === 0 ||
      isNaN(quantidadeFormatada) ||
      quantidadeFormatada === undefined
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Informe a quantidade do produto',
      });
      return;
    }

    if (Number(precoPadrao) > Number(selectedProductDetails?.precoUnitario!)) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'error',
        title: 'O preço unitário não pode ser menor que o preço padrão.',
      });
      return;
    }

    if (selectedProductDetails) {
      const total = calcularEstoqueTotal(selectedProductDetails.estoque, setEstoqueTotal);
      setEstoqueTotal(total);

      const ordemProduto = productOrder;

      // Cria um novo objeto contendo os detalhes atualizados do novo produto
      const productWithDetails = {
        ...selectedProductDetails,
        quantidade: quantidade,
        precoCusto: precoCusto,
        total: totalProdutoSelecionado,
        estoqueTotal: selectedProductDetails.estoqueTotal ?? estoqueTotal,
        descontoProduto: descontoProdutoSelecionado,
        ambiente: selectedAmbiente.desc.replace(/^\d+\s*-\s*/, '').replace(/"/g, ''),
        ambienteDesc: selectedAmbiente.desc,
        ambienteId: selectedAmbiente.id === '' ? null : Number(selectedAmbiente.id),
        ambienteComplemento: ambienteComplemento,
        tipoEntregaDesc: selectedTipoEntrega.desc,
        tipoEntregaId: selectedTipoEntrega.id === '' ? null : Number(selectedTipoEntrega.id),
        idloja: selectedLojaOrigem.id === '' ? null : Number(selectedLojaOrigem.id),
        descloja: selectedLojaOrigem.desc,
        idTabelaDePrecoProduto:
          selectedTabelaDePrecoProduto.id === '' ? null : Number(selectedTabelaDePrecoProduto.id),
        descTabelaDePrecoProduto: selectedTabelaDePrecoProduto.desc,
        obsproduto: observacaoProduto,
        imprimirObservacaoProduto: obsStatusPrd,
        margem: margem,
        precoPadrao: precoPadrao.toString(),
        estoqueRestante: estoqueTotal - quantidade,
        ordem: ordemProduto,
      };

      // Atualiza as informações do produto na lista, removendo duplicatas pela ordem
      setInfosProd((prevInfosProd) => {
        const filteredInfosProd = prevInfosProd.filter(
          (produto) => produto.ordem.toString() !== ordemProduto.toString()
        );
        return [...filteredInfosProd, productWithDetails];
      });

      handleStopEditing();
      setShowChangeSkuButton(false);
    }
  };

  const handleEditProduct = async (event, product) => {
    setEditing(true);
    setUpdateProduto(true);
    event.preventDefault();
    document.getElementById('section1')?.scrollIntoView({behavior: 'smooth'});
    setSelectedProductId(product.idProduto);

    if (product.idProduto === '123') {
      setIsPrecoUnitarioEditable(true);
      setValorEspecial(product.precoUnitario);
      // setAtivaMargem(0);
    } else {
      setIsPrecoUnitarioEditable(false);
      // setAtivaMargem(1);
    }

    const originalIndex = infosProd.findIndex((p) => p.ordem === product.ordem);

    if (originalIndex !== -1) {
      setEditingProductIndex(originalIndex);
      setSelectedProductDetails(product);
      setSelectedProductValue(product.idProduto);
      setSelectedProductAsync({value: product.idProduto, label: product.produto});
      setQuantidade(product.quantidade);
      setObservacaoProduto(product.obsproduto || '');

      setUnidade(product.unidade || '');
      setDescontoProdutoSelecionado(product.descontoProduto || 0);
      setTipoProduto(product.tipoProduto || '');
      setMarca(product.marca || '');
      setEstoqueTotal(product.estoqueTotal || 0);
      setSelectedTipoEntrega(
        product.tipoEntregaId
          ? {id: product.tipoEntregaId, desc: product.tipoEntregaDesc}
          : {id: '', desc: ''}
      );
      setAmbienteComplemento(product.ambienteComplemento || '');
      setSelectedAmbienteAsync({value: product.ambienteId, label: product.ambienteDesc});
      setSelectedAmbiente(
        product.ambienteId ? {id: product.ambienteId, desc: product.ambiente} : {id: '', desc: ''}
      );
      setSelectedLojaOrigem(
        product.idloja ? {id: product.idloja, desc: product.descloja} : {id: '', desc: ''}
      );

      if (product.descloja === null) {
        setSelectedLojaOrigem(
          product.idloja ? {id: product.idloja, desc: selectedLojaOrigem.desc} : {id: '', desc: ''}
        );
      }

      setSelectedTabelaDePrecoProduto(
        product.idTabelaDePrecoProduto
          ? {id: product.idTabelaDePrecoProduto, desc: product.descTabelaDePrecoProduto}
          : {id: '', desc: ''}
      );

      setObsStatusPrd(product.imprimirObservacaoProduto ? 1 : 0);

      setPrecoPadrao(Number(product.precoPadrao) || 0);

      calculaTotal(product.quantidade, product.precoUnitario, product.descontoProduto);
    } else {
      console.error('Produto não encontrado na lista original.');
    }

    setSelectedTabelaDePrecoProduto(
      product.idTabelaDePrecoProduto
        ? {id: product.idTabelaDePrecoProduto, desc: product.descTabelaDePrecoProduto}
        : {id: '', desc: ''}
    );

    calculaTotal(product.quantidade, product.precoUnitario, product.descontoProduto);
  };

  const handleDescontoGeralChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.,]/g, '');
    value = value.replace(',', '.');
    const parts = value.split('.');

    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 4);
      value = parts.join('.');
    }
    value = parts.join('.');

    if (parseFloat(value) >= 90) {
      value = '90';
    }
    formik.setFieldValue('descontoporcentagem', value);
  };

  function truncarDuasCasasDecimais(numero: number): number {
    const fator = Math.pow(10, 2);
    return Math.floor(numero * fator) / fator;
  }

  const calculaTotal = (
    quantidadeAtual = quantidade,
    precoUnitario = selectedProductDetails?.precoUnitario || '0.00',
    descontoProdutoCalculo = descontoProdutoSelecionado
  ) => {
    let idProduto = selectedProductDetails?.idProduto;
    if (idProduto === '123') {
      if (descontoProdutoCalculo > 0) {
        const precoComDesconto = parseFloat(precoUnitario) * (1 - descontoProdutoCalculo / 100);
        const totalComDesconto = precoComDesconto * quantidadeAtual;
        const totalComDescontoFormatado = truncarDuasCasasDecimais(Number(totalComDesconto));
        setTotalProdutoSelecionado(totalComDescontoFormatado);
      } else {
        const totalSemDesconto = parseFloat(precoUnitario) * quantidadeAtual;
        const totalSemDescontoFormatado = truncarDuasCasasDecimais(Number(totalSemDesconto));
        setTotalProdutoSelecionado(totalSemDescontoFormatado);
      }
    } else {
      if (descontoProdutoCalculo > 0) {
        const precoComDesconto = parseFloat(precoUnitario) * (1 - descontoProdutoCalculo / 100);
        const totalComDesconto = precoComDesconto * quantidadeAtual;
        const totalComDescontoFormatado = truncarDuasCasasDecimais(Number(totalComDesconto));
        setTotalProdutoSelecionado(totalComDescontoFormatado);
      } else {
        const totalSemDesconto = parseFloat(precoUnitario) * quantidadeAtual;
        const totalSemDescontoFormatado = truncarDuasCasasDecimais(Number(totalSemDesconto));
        setTotalProdutoSelecionado(totalSemDescontoFormatado);
      }
    }
  };

  const handleProdutosChange = (selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value === '123') {
        setIsPrecoUnitarioEditable(true);
        setQuantidade(0);
      } else {
        setIsPrecoUnitarioEditable(false);
      }
      setSelectedProductId(selectedOption.value);
      setPrecoPadrao(Number(selectedOption.precoUnitario));

      fetchInfoProduto(
        selectedOption.value,
        setLoading,
        setEstoque,
        setMarca,
        setUnidade,
        setTipoProduto,
        setPrecoCusto,
        setSelectedProductDetails,
        setPrecoPadrao,
        calculaTotal,
        quantidade,
        descontoProdutoSelecionado,
        setAmbienteComplemento,
        ambienteComplemento,
        setEstoqueTotal
        // setAtivaMargem
      );
      setQuantidade(0);
    }
  };

  const handleAddProduct = () => {
    setUpdateProduto(true);
    const quantidadeFormatada = Number(quantidade);
    // Validação da quantidade
    if (
      quantidadeFormatada === 0 ||
      isNaN(quantidadeFormatada) ||
      quantidadeFormatada === undefined
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Informe a quantidade do produto',
      });
      return;
    }

    // Validação do preço
    if (Number(precoPadrao) > Number(selectedProductDetails?.precoUnitario!)) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'error',
        title: 'O preço unitário não pode ser menor que o preço padrão.',
      });
      return;
    }

    if (selectedProductDetails) {
      // Calcula o estoque total
      const total = calcularEstoqueTotal(selectedProductDetails.estoque, setEstoqueTotal);
      setEstoqueTotal(total);

      const productWithDetails = {
        ...selectedProductDetails,
        ordem: selectedProductDetails.ordem,
        quantidade: quantidade,
        precoCusto: precoCusto,
        total: totalProdutoSelecionado,
        estoqueTotal: selectedProductDetails.estoqueTotal ?? estoqueTotal,
        descontoProduto: descontoProdutoSelecionado,
        ambiente: selectedAmbiente.desc.replace(/^\d+\s*-\s*/, '').replace(/"/g, ''),
        ambienteDesc: selectedAmbiente.desc,
        ambienteId: selectedAmbiente.id === '' ? null : Number(selectedAmbiente.id),
        ambienteComplemento: ambienteComplemento,
        tipoEntregaDesc: selectedTipoEntrega.desc,
        tipoEntregaId: selectedTipoEntrega.id === '' ? null : Number(selectedTipoEntrega.id),
        idloja: selectedLojaOrigem.id === '' ? null : Number(selectedLojaOrigem.id),
        descloja: selectedLojaOrigem.desc,
        idTabelaDePrecoProduto:
          selectedTabelaDePrecoProduto.id === '' ? null : Number(selectedTabelaDePrecoProduto.id),
        descTabelaDePrecoProduto: selectedTabelaDePrecoProduto.desc,
        obsproduto: observacaoProduto,
        imprimirObservacaoProduto: obsStatusPrd,
        margem: margem,
        // ativaMargem: ativaMargem!,
        precoPadrao: precoPadrao.toString(),
        estoqueRestante: estoqueTotal - quantidade,
      };
      // Verifica se já existe um produto com a mesma ordem
      const produtoExistente = infosProd.find(
        (produto) => produto.ordem === selectedProductDetails.ordem
      );

      if (produtoExistente) {
        // Atualiza campos permitidos
        setInfosProd((prevInfosProd) =>
          prevInfosProd.map((produto) =>
            produto.ordem === selectedProductDetails.ordem
              ? {
                  ...produto,
                  total: totalProdutoSelecionado,
                  precoUnitario: selectedProductDetails.precoUnitario,
                  quantidade: quantidade,
                  descontoProduto: descontoProdutoSelecionado,
                  tipoEntregaDesc: selectedTipoEntrega.desc,
                  tipoEntregaId:
                    selectedTipoEntrega.id === '' ? null : Number(selectedTipoEntrega.id),
                  idloja: selectedLojaOrigem.id === '' ? null : Number(selectedLojaOrigem.id),
                  descloja: selectedLojaOrigem.desc,
                  ambiente: selectedAmbiente.desc.replace(/^\d+\s*-\s*/, '').replace(/"/g, ''),
                  ambienteId: selectedAmbiente.id === '' ? null : Number(selectedAmbiente.id),
                  ambienteDesc: selectedAmbiente.desc,
                  ambienteComplemento: ambienteComplemento,
                  idTabelaDePrecoProduto:
                    selectedTabelaDePrecoProduto.id === ''
                      ? null
                      : Number(selectedTabelaDePrecoProduto.id),
                  descTabelaDePrecoProduto: selectedTabelaDePrecoProduto.desc,
                  obsproduto: observacaoProduto,
                  imprimirObservacaoProduto: obsStatusPrd,
                }
              : produto
          )
        );
      } else {
        // Criação de novo produto com ordem única
        let ultimaOrdem = infosProd.length > 0 ? infosProd[infosProd.length - 1].ordem : 0;
        selectedProductDetails.ordem = ultimaOrdem + 1;

        // Garante que a ordem seja única
        let ordemExistente = infosProd.some(
          (produto) => produto.ordem === selectedProductDetails.ordem
        );
        while (ordemExistente) {
          ultimaOrdem += 1;
          selectedProductDetails.ordem = ultimaOrdem;
          ordemExistente = infosProd.some(
            (produto) => produto.ordem === selectedProductDetails.ordem
          );
        }

        // Criação do novo objeto com detalhes
        const productWithDetails = {
          ...selectedProductDetails,
          quantidade: quantidade,
          precoCusto: precoCusto,
          total: totalProdutoSelecionado,
          estoqueTotal: selectedProductDetails.estoqueTotal ?? estoqueTotal,
          descontoProduto: descontoProdutoSelecionado,
          ambiente: selectedAmbiente.desc.replace(/^\d+\s*-\s*/, '').replace(/"/g, ''),
          ambienteDesc: selectedAmbiente.desc,
          ambienteId: selectedAmbiente.id === '' ? null : Number(selectedAmbiente.id),
          ambienteComplemento: ambienteComplemento,
          tipoEntregaDesc: selectedTipoEntrega.desc,
          tipoEntregaId: selectedTipoEntrega.id === '' ? null : Number(selectedTipoEntrega.id),
          idloja: selectedLojaOrigem.id === '' ? null : Number(selectedLojaOrigem.id),
          descloja: selectedLojaOrigem.desc,
          idTabelaDePrecoProduto:
            selectedTabelaDePrecoProduto.id === '' ? null : Number(selectedTabelaDePrecoProduto.id),
          descTabelaDePrecoProduto: selectedTabelaDePrecoProduto.desc,
          obsproduto: observacaoProduto,
          imprimirObservacaoProduto: obsStatusPrd,
          margem: margem,
          // ativaMargem: ativaMargem!,
          precoPadrao: precoPadrao.toString(),
          estoqueRestante: estoqueTotal - quantidade,
        };
        // Adiciona o novo produto ao array
        setInfosProd((prevInfosProd) => [...prevInfosProd, productWithDetails]);
      }
      // Reseta os valores após adicionar ou atualizar o produto
      handleStopEditing();
    }
    setIsPrecoUnitarioEditable(false);
    setValorEspecial(0.0);

    setEditing(false);
  };

  const handleStopEditing = () => {
    console.log(showChangeSkuButton);
    setShowChangeSkuButton(false);
    setEditing(false);
    setSelectedProductDetails(null);
    setQuantidade(0);
    setPrecoCusto(0);
    setTotalProdutoSelecionado(0);
    setDescontoProdutoSelecionado(0);
    setSelectedTabelaDePrecoProduto({id: '', desc: ''});
    limparOpcoesTabelaPrecoProduto();
    setSelectedProductId(null);
    setSelectedProductValue(null);
    setSelectedProductAsync(null);
    setTipoProduto('');
    setMarca('');
    setObservacaoProduto('');
    setUnidade('');
    setEstoqueTotal(0);
    setMargem(0);
    // setAtivaMargem(1);
    setPrecoPadrao(0);
    setObsStatusPrd(0);
    setIsPrecoUnitarioEditable(false);
    setValorEspecial(0.0);
  };

  const handleAddFormaPagamento = () => {
    const quantidadeProdutos = infosProd.length;

    if (quantidadeProdutos === 0 || isNaN(quantidadeProdutos) || quantidadeProdutos === undefined) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Por favor, selecione primeiro um produto',
      });

      return;
    }

    if (
      !formik.values.formapagamentoid ||
      formik.values.formapagamentoid === null ||
      formik.values.formapagamentoid === -11
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Selecione uma forma de pagamento',
      });

      return;
    }

    if (!formik.values.condicaoid || formik.values.condicaoid === null) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: 'info',
        title: 'Selecione uma condicao de pagamento',
      });

      return;
    }

    const formaPagamentoId = formik.values.formapagamentoid;
    const condicaoPagamentoId = formik.values.condicaoid;

    const valorRecebidoNumerico = parseFloat(valorRecebido.replace(/\./g, '').replace(',', '.'));

    const valorAReceberNumerico = parseFloat(valorAReceber.toFixed(2));

    if (valorRecebidoNumerico > valorAReceberNumerico) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'O valor recebido não pode ser maior que o valor a receber!',
      });

      return;
    }

    const formaPagamentoDesc =
      optionsFormaPagamento.find((option) => option.value === formaPagamentoId)?.label || '';
    const formaPagamentoIcone =
      optionsFormaPagamento.find((option) => option.value === formaPagamentoId)?.icone || '';
    const condicaoPagamentoDesc =
      optionsCondicoesPagamento.find((option) => option.value === condicaoPagamentoId)?.label || '';

    const novaFormaPagamento = {
      idloja: null,
      idFormaPagamento: formaPagamentoId,
      idCondicaoPagamento: condicaoPagamentoId,
      descFormaPagamento: formaPagamentoDesc,
      descCondicaoPagamento: condicaoPagamentoDesc,
      valorRecebido: valorRecebidoNumerico,
      desconto: 0,
      valorTotal: valorRecebidoNumerico,
      icone: formaPagamentoIcone,
      pagamentoPrincipal: pagamentoPrincipal,
    };

    setInfosPagamento((prevInfosPagamento) => [...prevInfosPagamento, novaFormaPagamento]);
    setTabelaDadosPagamento((prevTabelaDados) => [...prevTabelaDados, novaFormaPagamento]);

    setValorRecebido('0.00');
    setPagamentoPrincipal(0);
  };

  const handleValorFrete = (event) => {
    let value = event.target.value;

    // Remove todos os caracteres que não são números
    value = value.replace(/\D/g, '');

    // Formatar o valor para exibição com vírgula e pontos (string)
    let formattedValue = value;
    if (value.length > 2) {
      formattedValue = (parseInt(value, 10) / 100).toFixed(2).replace('.', ',');
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    // Define o valor formatado para exibição no campo (string)
    formik.setFieldValue('valorFreteVisual', formattedValue);

    // Define o valor numérico real para ser salvo no Formik como number
    const numericValue = parseFloat(value) / 100; // Ex.: "4587" vira 45.87
    formik.setFieldValue('valorFrete', numericValue);
  };

  //LOAD OPTIONS
  const loadOptionsClientes = async (inputValue: string) => {
    try {
      let url;
      if (filtroCliente === 'clienteFantasia') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaClienteNome/${inputValue}`;
      } else if (filtroCliente === 'idCliente') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaCliente/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idCliente,
        label: item.idCliente + ' - ' + item.clienteFantasia,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsConsultor = async (inputValue: string) => {
    try {
      let url;
      if (filtroConsultor === 'funcionario') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaNomeFuncionario/${inputValue}`;
      } else if (filtroConsultor === 'idFuncionario') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idFuncionario,
        label: item.idFuncionario + ' - ' + item.funcionario,
      }));
    } catch (error) {}
  };

  const loadOptionsParceiro = async (inputValue: string) => {
    try {
      let url;
      if (filtroParceiro === 'parceiro') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaNomeParceiroApi/${inputValue}`;
      } else if (filtroParceiro === 'idParceiro') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaParceiroApi/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idParceiro,
        label: item.idParceiro + ' - ' + item.parceiro,
      }));
    } catch (error) {}
  };

  const loadOptionsCloser = async (inputValue: string) => {
    try {
      let url;
      if (filtroCloser === 'closer') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaNomeFuncionario/${inputValue}`;
      } else if (filtroCloser === 'idCloser') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idFuncionario,
        label: item.idFuncionario + ' - ' + item.funcionario,
      }));
    } catch (error) {}
  };

  const loadOptionsOrcamentista = async (inputValue: string) => {
    try {
      let url;
      if (filtroOrcamentista === 'orcamentista') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaNomeFuncionario/${inputValue}`;
      } else if (filtroOrcamentista === 'idOrcamentista') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idFuncionario,
        label: item.idFuncionario + ' - ' + item.funcionario,
      }));
    } catch (error) {}
  };

  const loadOptionsAmbiente = async (inputValue: string) => {
    try {
      let url;
      if (filtroAmbiente === 'ambiente') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaTipoAmbientePorNome/${inputValue}`;
      } else if (filtroAmbiente === 'idAmbiente') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaTipoAmbienteCodigo/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idAmbiente,
        label: item.idAmbiente + ' - ' + item.ambiente,
      }));
    } catch (error) {}
  };

  const loadOptionsProdutos = async (inputValue: string) => {
    try {
      let url;
      if (filtroProdutos === 'idProduto') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoCodigo/${inputValue}`;
      } else if (filtroProdutos === 'produto') {
        url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${inputValue}`;
      } else {
        console.error('Filtro inválido');
        return [];
      }

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idProduto,
        label: item.idProduto + ' - ' + item.produto,
      }));
    } catch (error) {}
  };

  const existePagamentoPrincipal = infosPagamento.some((info) => info.pagamentoPrincipal === 1);

  function toggleExibir(
    exibir: boolean,
    setExibir: React.Dispatch<React.SetStateAction<boolean>>,
    setIcone: React.Dispatch<React.SetStateAction<string>>
  ) {
    setExibir(!exibir);
    setIcone(exibir ? 'bi bi-arrow-down-square-fill' : 'bi bi-arrow-up-square-fill');
  }

  const calcularTotalGeral = () => {
    const totalBruto = infosProd.reduce((total, produto) => total + (produto.total || 0), 0);

    let descontoPercentual = parseFloat(formik.values.descontoporcentagem);

    if (isNaN(descontoPercentual)) {
      descontoPercentual = 0;
    }

    const valorDesconto = (descontoPercentual / 100) * totalBruto;
    let totalLiquido = totalBruto - valorDesconto;

    let valorFrete = parseFloat(formik.values.valorFrete);

    if (isNaN(valorFrete)) {
      valorFrete = 0;
    }

    totalLiquido += valorFrete;
    const totalLiquidoArredondado = parseFloat(totalLiquido.toFixed(2));
    setTotalGeral(totalLiquidoArredondado);
  };

  const calcularMargemGeral = (totalGeral: number) => {
    const {totalCusto, temCustoNegativo} = infosProd.reduce(
      (acc, produto) => {
        const produtoTotal = produto.precoCusto! * produto.quantidade;
        acc.totalCusto += produtoTotal;
        if (produto.precoCusto! <= 0) {
          acc.temCustoNegativo = true;
        }
        return acc;
      },
      {totalCusto: 0, temCustoNegativo: false}
    );

    let calculoMargem = 0;
    if (totalGeral !== 0) {
      const diferencaGeral = totalGeral - totalCusto;
      calculoMargem = (diferencaGeral / totalGeral) * 100;
    }

    const valorTruncado = truncarDuasCasasDecimais(calculoMargem);
    setPrecoCustoTotal(totalCusto);
    setMargemTotal(valorTruncado);
    setTemCustoNegativo(temCustoNegativo);
  };

  const fetchFormasPagamento = async () => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/buscaFormasPagamento`
    );
    return response.data.map((formaPagamento: any) => ({
      value: formaPagamento.idFormaPagamento,
      label: formaPagamento.formaPagamento,
      icone: `${formaPagamento.icone}`,
    }));
  };

  const fetchCondicoesPagamento = async () => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/condicoespagamento/buscaCondicoesPagamento`
    );
    return response.data.map((condicaoPagamento: any) => ({
      value: condicaoPagamento.idCondicaoPagamento,
      label: condicaoPagamento.condicaoPagamento,
    }));
  };

  const fetchFuncionario = async (id: number) => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${id}`
    );
    return response.data[0];
  };

  const fetchPrevendaData = async (id: string | undefined) => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscar/${id}`
    );
    return response.data;
  };

  let idUnicoAtual = (infosProd.length > 0 ? infosProd.length : 0) + 1;

  const fetchProductData = async (produto: any) => {
    // const info = await fetchProductAdditionalInfo(produto.idProduto);

    const idUnicoInverso = idUnicoAtual;

    idUnicoAtual += 1;

    return {
      ...produto,
      ordem: produto.ordem,
      idUnico: produto.idUnico ?? idUnicoInverso,
      produto: produto.produto,
      unidade: produto.unidade,
      descloja: produto.descloja,
      marca: produto.marca,
      tipoProduto: produto.tipoProduto,
      descontoProduto: Number(produto.descontoProduto),
      quantidade: Number(produto.quantidade),
      total: Number(produto.totalProduto),
      precoCusto: produto.precoCusto,
      ativaMargem: produto.ativaMargem,
      precoPadrao: Number(produto.precoPadrao),
      estoque: produto.estoque,
    };
  };

  const loadParceiroById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaParceiroApi/${id}`;

      const response = await axios.get(url);

      console.log(response);

      const {data} = response;

      const firstParceiro = data[0];

      setSelectedParceiro({
        label: `${firstParceiro.parceiro} - ${firstParceiro.cnpj}`,
        value: Number(firstParceiro.idParceiro),
      });

      return [
        {
          value: data.codigog2,
          label: data.nome,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const [isState, setIsState] = useState<number>();
  useEffect(() => {
    setIsState(isState);
    console.log('state', isState);
  }, [isState]);

  const fetchPrevenda = async () => {
    setLoadingPayload(true);
    try {
      const optionsFormaPagamentoFetch = await fetchFormasPagamento();
      const optionsCondicaoPagamentoFetch = await fetchCondicoesPagamento();
      const prevenda = await fetchPrevendaData(id);

      setSelectedCliente({value: prevenda.clienteid, label: prevenda.nomeCliente});
      // buscaCliente(prevenda.clienteid);
      // buscaSaldoCliente(prevenda.clienteid);
      setOrdenacao(prevenda.ordenacao);

      if (prevenda.parceiroid) {
        loadParceiroById(prevenda.parceiroid);
      }

      const consultor = await fetchFuncionario(prevenda.consultorid);
      setSelectedConsultor({
        value: consultor.idFuncionario,
        label: `${consultor.idFuncionario} - ${consultor.funcionario}`,
      });
      if (prevenda.closerid !== 0) {
        const closer = await fetchFuncionario(prevenda.closerid);
        setSelectedCloser({
          value: closer.idFuncionario,
          label: `${closer.idFuncionario} - ${closer.funcionario}`,
        });
      }

      if (prevenda.orcamentistaid !== 0) {
        const orcamentista = await fetchFuncionario(prevenda.orcamentistaid);
        setSelectedOrcamentista({
          value: orcamentista.idFuncionario,
          label: `${orcamentista.idFuncionario} - ${orcamentista.funcionario}`,
        });
      }
      const pagamentoPrincipal = prevenda.pagamentosPrevenda.find(
        (pagamento) => pagamento.pagamentoPrincipal === 1
      );

      setIsState(prevenda.status);

      formik.setValues({
        dataemissao: prevenda.dataemissao?.split('T')[0] || '',
        vencimento: prevenda.vencimento?.split('T')[0] || '',
        clienteid: prevenda.clienteid || null,
        almoxarifadoid: prevenda.almoxarifadoid,
        empresaid: null,
        consultorid: prevenda.consultorid || '',
        closerid: prevenda.closerid || '',
        parceiroid: prevenda.parceiroid || '',
        orcamentistaid: prevenda.orcamentistaid || '',
        formapagamentoid: pagamentoPrincipal ? pagamentoPrincipal.idFormaPagamento : 0,
        condicaoid: 0,
        status: prevenda.status,
        desconto: '',
        descontoporcentagem: prevenda.descontoporcentagem || '',
        tipoorigemid: prevenda.tipoorigemid,
        tabelaprecoid: prevenda.tabelaprecoid || 0,
        responsavel1Entrega: prevenda.responsavel1Entrega || '',
        responsavel2Entrega: prevenda.responsavel2Entrega || '',
        celular1Entrega: prevenda.celular1Entrega || '',
        celular2Entrega: prevenda.celular2Entrega || '',
        profissao1Entrega: prevenda.profissao1Entrega || '',
        profissao2Entrega: prevenda.profissao2Entrega || '',
        data1Entrega: prevenda.data1Entrega?.split('T')[0] || '',
        data2Entrega: prevenda.data2Entrega?.split('T')[0] || '',
        horaEntrega: prevenda.horaEntrega || '',
        cepEntrega: prevenda.cepEntrega || '',
        enderecoEntrega: prevenda.enderecoEntrega || '',
        bairroEntrega: prevenda.bairroEntrega || '',
        cidadeEntrega: prevenda.cidadeEntrega || '',
        ufEntrega: prevenda.estadoEntrega || '',
        numeroEntrega: prevenda.numeroEntrega || '',
        pontoReferencia1Entrega: prevenda.pontoReferencia1Entrega || '',
        pontoReferencia2Entrega: prevenda.pontoReferencia2Entrega || '',
        usuariocriacao: '',
        obsprevenda: prevenda.obsPrevenda || '',
        kinboxlink: prevenda.kinboxlink || '',
        valorFrete: prevenda.valorFrete || 0.0,
        cifFob: prevenda.cifFob || 0,
        produtoNaoEncontrado: prevenda.produtoNaoEncontrado || '',
        valorFreteVisual: prevenda.valorFrete
          ? prevenda.valorFrete.toFixed(2).replace('.', ',')
          : '0,00',
        observacaoEntrega: prevenda.observacaoEntrega || '',
        isEntregaParcial: prevenda.isEntregaParcial,
        idTipoVenda: prevenda.idTipoVenda,
      });

      setProjetoStatus(prevenda.projetostatus || '');
      setObsStatus(prevenda.obsstatus || '');
      // setObsStatusPrd(prevenda.obsstatusPrd || false);

      setInnerFlow(prevenda.fluxoLogistico || 'false');
      setIsEntregaParcial(prevenda.isEntregaParcial === false ? 0 : 1);

      if (prevenda.fretestatus != null) {
        setFreteStatus(prevenda.fretestatus);
      } else {
        setFreteStatus(0);
      }

      if (prevenda.produtoNaoEncontradoStatus != null) {
        setProdutoNaoEncontradoStatus(prevenda.produtoNaoEncontradoStatus);
      } else {
        setProdutoNaoEncontradoStatus(1);
      }

      const produtosComDetalhes = await Promise.all(
        prevenda.produtosPrevenda.map(fetchProductData)
      );
      setInfosProd(produtosComDetalhes);

      setTabelaDados(
        produtosComDetalhes.map((produto) => ({
          ...produto,
          idTabelaDePrecoProduto: produto.idTabelaDePrecoProduto,
        }))
      );

      setInfosPagamento(
        prevenda.pagamentosPrevenda.map((pagamento) => {
          const formaPagamentoDesc =
            optionsFormaPagamentoFetch.find(
              (option) => option.value === String(pagamento.idFormaPagamento)
            )?.label || '';
          const formaPagamentoIcone =
            optionsFormaPagamentoFetch.find(
              (option) => option.value === String(pagamento.idFormaPagamento)
            )?.icone || '';
          const condicaoPagamentoDesc =
            optionsCondicaoPagamentoFetch.find(
              (option) => option.value === String(pagamento.idCondicaoPagamento)
            )?.label || '';
          return {
            idFormaPagamento: pagamento.idFormaPagamento,
            descFormaPagamento: formaPagamentoDesc,
            descCondicaoPagamento: condicaoPagamentoDesc,
            idCondicaoPagamento: pagamento.idCondicaoPagamento,
            valorRecebido: pagamento.valorRecebido,
            id: pagamento.id,
            icone: formaPagamentoIcone,
            pagamentoPrincipal: pagamento.pagamentoPrincipal,
          };
        })
      );

      setTabelaDadosPagamento(
        prevenda.pagamentosPrevenda.map((pagamento) => ({
          idFormaPagamento: pagamento.idFormaPagamento,
          descFormaPagamento: '',
          idCondicaoPagamento: pagamento.idCondicaoPagamento,
          descCondicaoPagamento: '',
          valorRecebido: pagamento.valorRecebido,
          id: pagamento.id,
        }))
      );
    } catch (error) {
      console.error('Error fetching:', error);
    } finally {
      setLoadingPayload(false);
    }
  };

  const limparCampos = () => {
    const campos = [
      'razaosocialDestino',
      'cnpjDestino',
      'telefoneDestino',
      'cepDestino',
      'enderecoDestino',
      'bairroDestino',
      'cidadeDestino',
      'estadoDestino',
      'numeroDestino',
      'pontoReferenciaDestino',
      'complementoDestino',
    ];
    campos.forEach((id) => {
      const campo = document.getElementById(id) as HTMLInputElement;
      if (campo) campo.value = '';
    });
  };

  //useEffect

  useEffect(() => {
    if (id) {
      const fetchProductById = async () => {
        const response = await fetchPrevendaData(id);
        const {isAmbientado, fretestatus} = response;

        if (isAmbientado) {
          setExibirProjetos(false);
          setIconeProjetos('bi bi-arrow-up-square-fill');
        } else {
          setExibirProjetos(true);
          setIconeProjetos('bi bi-arrow-down-square-fill');
        }

        if (fretestatus === 0) {
          setExibirDadosEntrega(true);
          setIconeDadosEntrega('bi bi-arrow-down-square-fill');
        } else {
          setExibirDadosEntrega(false);
          setIconeDadosEntrega('bi bi-arrow-up-square-fill');
        }
      };

      fetchProductById();
    } else {
      setInfosProd([]);
      setSelectedCliente(null);
      setSelectedCloser(null);
      setSelectedConsultor(null);
      setSelectedParceiro(null);
      setSelectedOrcamentista(null);
      formik.setValues(formPreVenda);
      limparCampos();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        await fetchPrevenda();
      };
      fetchData();
    } else {
      setInfosProd([]);
      setSelectedCliente(null);
      setSelectedCloser(null);
      setSelectedConsultor(null);
      setSelectedParceiro(null);
      setSelectedOrcamentista(null);
      setSelectedProductAsync(null);
      formik.setValues(formPreVenda);
      limparCampos();
    }
  }, [id]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Você tem informações não salvas. Deseja sair?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (tipoEntregaPadrao) {
      setSelectedTipoEntrega({
        id: tipoEntregaPadrao.value.toString(),
        desc: tipoEntregaPadrao.label,
      });

      setSelectedTipoEntregaTodos({
        id: tipoEntregaPadrao.value.toString(),
        desc: tipoEntregaPadrao.label,
      });
    }
  }, [tipoEntregaPadrao]);

  useEffect(() => {
    if (lojaOrigemPadrao) {
      setSelectedLojaOrigem({
        id: lojaOrigemPadrao.value,
        desc: lojaOrigemPadrao.label,
      });

      setSelectedLojaOrigemTodos({
        id: lojaOrigemPadrao.value.toString(),
        desc: lojaOrigemPadrao.label,
      });
    }
  }, [lojaOrigemPadrao]);

  useEffect(() => {
    let valorFrete = formik.values.valorFrete;

    if (valorFrete) {
      let formattedValue = Number(valorFrete)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      formik.setFieldValue('valorFreteVisual', formattedValue);
    }
  }, [formik.values.valorFrete]);

  useEffect(() => {
    let valorFrete = parseFloat(formik.values.valorFrete);
    if (isNaN(valorFrete)) {
      valorFrete = 0;
    }

    let novoTotalBruto = infosProd.reduce((total, produto) => total + (produto.total || 0), 0);
    novoTotalBruto += valorFrete;

    let descontoPercentual = parseFloat(formik.values.descontoporcentagem);
    if (isNaN(descontoPercentual)) {
      descontoPercentual = 0;
    }

    const valorDesconto = (descontoPercentual / 100) * totalBruto;
    const valorTotal = totalGeral || 0;
    const valorTotalRecebido = infosPagamento.reduce(
      (total, formaPagamento) => total + formaPagamento.valorRecebido,
      0
    );

    const novoValorAReceber = valorTotal - valorTotalRecebido;
    setValorAReceber(novoValorAReceber);
    setTotalBruto(novoTotalBruto);
  }, [
    infosProd,
    formik.values.descontoporcentagem,
    formik.values.valorFrete,
    totalGeral,
    infosPagamento,
  ]);

  useEffect(() => {
    if (freteStatus === 0) {
      formik.setFieldValue('valorFrete', 0.0);
    }
  }, [freteStatus, formik.values.valorFrete]);

  useEffect(() => {
    if (produtoNaoEncontradoStatus === 1) {
      formik.setFieldValue('produtoNaoEncontrado', '');
    }
  }, [produtoNaoEncontradoStatus, formik.values.produtoNaoEncontrado]);

  useEffect(() => {
    if (selectedProductDetails) {
      setQuantidade(selectedProductDetails.quantidade);
      setUnidade(selectedProductDetails.unidade || '');
      setDescontoProdutoSelecionado(selectedProductDetails.descontoProduto || 0);
      setTipoProduto(selectedProductDetails.tipoProduto || '');
      setMarca(selectedProductDetails.marca || '');
      setEstoque((selectedProductDetails.estoque as Estoque[]) || []);
      setPrecoCusto(Number(selectedProductDetails.precoCusto) || null);
      // setAtivaMargem(selectedProductDetails.ativaMargem!);
    }
  }, [selectedProductDetails]);

  useEffect(() => {
    setSelectedLojaOrigem({id: empresaPadrao || '', desc: selectedLojaOrigem.desc || ''});
  }, [empresaPadrao]);

  useEffect(() => {
    calcularTotalGeral();
    calcularMargemGeral(totalGeral);
  }, [infosProd, formik.values.descontoporcentagem, formik.values.valorFrete, totalGeral]);

  useEffect(() => {
    if (selectedProductDetails) {
      // if (ativaMargem === 1) {
      const precoCusto = Number(selectedProductDetails.precoCusto);
      calculoMargem(totalProdutoSelecionado, precoCusto, quantidade);
      // } else {
      //   setMargem(0);
      // }
    }
  }, [
    // ativaMargem,
    selectedProductDetails,
    totalProdutoSelecionado,
  ]);

  useBuscaParceiro(formik.values.parceiroid);
  useBuscaCloser(formik.values.closerid);
  useBuscaOrcament(formik.values.orcamentistaid);
  useBuscaCepPreVenda(formik.values.cepEntrega, formik);

  const renderFatura = () => {
    const descontoTotalFatura = parseFloat(formik.values.descontoporcentagem) || 0;

    return (
      <>
        <div
          className='container-listagem-produto align-center gap-4 mt-8'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-sm-2'>
            <label htmlFor='' className='form-label'>
              Valor Total
            </label>
            <input
              type='text'
              className='form-control form-control-sm'
              value={`R$` + totalBruto.toLocaleString('pt-BR')}
              disabled
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <label htmlFor='' className='form-label'>
              Desconto
            </label>
            <input
              type='text'
              className='form-control form-control-sm'
              value={descontoTotalFatura + `% `}
              disabled
              readOnly
            />
          </div>
          <div className='col-sm-2'>
            <label htmlFor='' className='form-label'>
              Valor a Receber
            </label>
            <input
              type='text'
              className='form-control form-control-sm'
              value={`R$ ${valorAReceber.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
              disabled
              readOnly
            />
          </div>
          <div
            className='col-sm-2'
            style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
          >
            <label htmlFor='' className='form-label'>
              Saldo Crédito Cliente
            </label>
            <input
              id='saldoCreditoCliente'
              type='text'
              className='form-control form-control-sm'
              disabled
              readOnly
            />
          </div>
        </div>
      </>
    );
  };

  //const precocusto = infosProd.reduce((total, produto) => total + (produto.precoCusto || 0), 0);

  const renderValoresTotais = () => {
    //const descontoProdutos = calcularDescontoProdutos() || 0;
    const descontoTotal = parseFloat(formik.values.descontoporcentagem) || 0;
    const totalPreVenda = totalGeral || 0;
    const quantidadeProdutos = infosProd.length;

    return (
      <>
        <div className='row mb-3'>
          <div className='col-sm3'>
            <h2>Resumo Orçamento</h2>
            <div className='mb-2' style={{flexGrow: 1, borderBottom: '2px solid #d3d3d3'}}></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className='row mb-10 w-50'>
            <div className='row mb-3'>
              <div className='col-sm-6'>
                <h4>
                  <strong>Quantidade de itens:</strong> {quantidadeProdutos}
                </h4>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-sm-6'>
                <h4>
                  <strong>Desconto no Orçamento:</strong>{' '}
                  {descontoTotal.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  %
                </h4>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-sm-6'>
                <h4>
                  <strong>Total:</strong>
                  <span style={{color: '#3e97ff'}}>
                    {' '}
                    R${' '}
                    {totalPreVenda.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div
            className='row mb-3 w-50'
            style={{display: 'flex', justifyContent: 'flex-end', alignContent: 'center'}}
          >
            <div className='col-sm-6'>
              <h3
                data-toggle='tooltip'
                data-placement='top'
                title={temCustoNegativo ? 'Existem produtos com preço de custo zerado' : ''}
              >
                <strong>Total Margem:</strong>
                <span style={{color: temCustoNegativo ? 'red' : '#50cd89'}}>
                  {''}{' '}
                  {` ${margemTotal.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} % `}
                </span>
              </h3>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleGerarPedido = async (id, navigate) => {
    let estoqueInsuficiente = false;

    const prevenda = await fetchPrevendaData(id);

    prevenda.produtosPrevenda.forEach((produto) => {
      const estoqueTotal = parseInt(produto.estoqueTotal);
      const quantidadeProduto = parseInt(produto.quantidade);

      const estoqueRestante = estoqueTotal - quantidadeProduto;
      if (estoqueRestante < 0) {
        estoqueInsuficiente = true;
      }
    });

    if (estoqueInsuficiente) {
      Swal.fire({
        title: 'Estoque Insuficiente',
        text: 'Alguns produtos possuem estoque insuficiente e serão reemitidos para aprovação. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.put(
              `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${id}/gerar-pedido`
            );

            if (response.status === 200) {
              Swal.fire({
                title: 'Pedido Gerado!',
                text: 'Seu pedido foi gerado com sucesso!',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then(() => {
                navigate('/listagem-pedidos');
              });
            }
          } catch (error: any) {
            Swal.fire({
              title: 'Erro!',
              text: error.response.data[0].mensagem,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        }
      });
    } else {
      try {
        const response = await axios.put(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${id}/gerar-pedido`
        );

        if (response.status === 200) {
          Swal.fire({
            title: 'Pedido Gerado!',
            text: 'Seu pedido foi gerado com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            navigate('/listagem-pedidos');
          });
        }
      } catch (error: any) {
        Swal.fire({
          title: 'Erro!',
          text: error.response.data[0].mensagem,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleOptionsAsyncProducts = (selectedOption) => {
    if (selectedOption) {
      setSelectedProductAsync(selectedOption);
      handleProdutosChange(selectedOption);
    }
  };

  const handleOptionsAsyncCliente = (selectedOption) => {
    if (selectedOption) {
      setSelectedCliente(selectedOption);
    }
  };

  const handleOptionsAsyncConsultor = (selectedOption) => {
    if (selectedOption) {
      setSelectedConsultor(selectedOption);
    }
  };

  const handleOptionsAsyncCloser = (selectedOption) => {
    if (selectedOption) {
      setSelectedCloser(selectedOption);
    }
  };

  const handleOptionsAsyncOrcamentista = (selectedOption) => {
    if (selectedOption) {
      setSelectedOrcamentista(selectedOption);
    }
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    if (optionsTipoVenda.length > 0 && !formik.values.idTipoVenda) {
      formik.setFieldValue('idTipoVenda', optionsTipoVenda[0].value);
    }
  }, [optionsTipoVenda, formik]);
  // const [initialOffset, setInitialOffset] = useState(0);
  // const [scrollY, setScrollY] = useState(0);

  // useEffect(() => {
  //   console.log('tipo screen: ', tipo);

  //   const handleScroll = () => {
  //     setScrollY(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const section1 = document.getElementById('section1');
  //   const sectionPayment = document.getElementById('section-payment');
  //   const sectionMainForm = document.getElementById('mainFormSection'); // Nova seção

  //   console.log('tipo: ', tipo);

  //   if (tipo === 'propostas') {
  //     if (section1) {
  //       setInitialOffset(section1.offsetTop); // Definir o offset inicial da section1
  //     }

  //     const handleScroll = () => {
  //       const currentScroll = window.scrollY;

  //       // Realizar a lógica apenas se todos os elementos existirem
  //       if (section1 && sectionPayment && sectionMainForm) {
  //         const section1Top = section1.getBoundingClientRect().top;
  //         const sectionPaymentRect = sectionPayment.getBoundingClientRect();
  //         const sectionMainFormRect = sectionMainForm.getBoundingClientRect(); // Retângulo da sectionMainForm

  //         // Adicionar 50px de tolerância após a section-payment ficar visível
  //         const tolerance = 50;

  //         const extraTolerance = 200;

  //         // Verificar se a sectionMainForm está visível na tela
  //         const isInMainFormSection =
  //           sectionMainFormRect.top < window.innerHeight - tolerance - extraTolerance &&
  //           sectionMainFormRect.bottom > 0;

  //         // Variável para controlar se a section-payment está visível na tela (considerando a tolerância)
  //         const isInPaymentSection =
  //           sectionPaymentRect.top < window.innerHeight - tolerance &&
  //           sectionPaymentRect.bottom > 0;

  //         const isInSection1 = section1Top <= 50 && section1Top > 0;

  //         // Fixar a section1 se estamos nela e fora das outras seções
  //         if (isInSection1 && !isFixed && !isInPaymentSection && !isInMainFormSection) {
  //           setIsFixed(true);
  //         }

  //         // Desfixar ao sair da section1 (scroll para cima) ou ao chegar na section-payment ou na sectionMainForm
  //         if (currentScroll <= initialOffset && isFixed) {
  //           setIsFixed(false);
  //         }

  //         // Se o usuário estiver vendo qualquer parte da section-payment ou sectionMainForm, desativar fixação
  //         if ((isInPaymentSection || isInMainFormSection) && isFixed) {
  //           setIsFixed(false);
  //         }
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll, { passive: true });
  //     window.addEventListener('keydown', handleScroll, { passive: true }); // Detectar teclas como setas para baixo/cima
  //     window.addEventListener('touchmove', handleScroll, { passive: true }); // Detectar eventos de toque (touch)
  //     window.addEventListener('mousewheel', handleScroll, { passive: true }); // Detectar rolagem do mouse

  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //       window.removeEventListener('keydown', handleScroll);
  //       window.removeEventListener('touchmove', handleScroll);
  //       window.removeEventListener('mousewheel', handleScroll);
  //     };
  //   }

  //   setIsFixed(false);
  // }, [isFixed, tipo, scrollY]);

  return (
    <>
      {loadingPayload ? (
        <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <form
          className='form-control-solid proposta'
          onSubmit={formik.handleSubmit}
          noValidate
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        >
          <div className='content'>
            <div className='formularioPrincipal' id='mainFormSection'>
              <div className='row'>
                <div className='col-sm-5'>
                  <h1 className='text-dark fw-bolder mb-5'>
                    {tipo === 'propostas' ? 'Proposta Comercial' : 'Pedidos'}
                  </h1>
                </div>
              </div>
              <div
                className='mb-3 form-label-container'
                style={{display: 'flex', alignItems: 'center'}}
              >
                <h5>Informações Gerais</h5>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>
              <div className='mb-3 row' style={{display: 'flex', justifyContent: 'space-between'}}>
                {/* <div className='col-sm-2' style={{display: 'none'}}>
                {/* <label htmlFor='' className='form-label'>
                  Filtro
                </label>
                <select
                  className='form-select form-select-sm'
                  name=''
                  id=''
                  onChange={handleFiltroClienteCheckChange}
                >
                  <option value="0">Selecione</option>
                  <option value='clienteFantasia'>Nome Cliente</option>
                </select> *
              </div> */}
                {id && (
                  <div
                    className='col-sm-1'
                    style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                  >
                    <label htmlFor='' className='form-label form-label-sm'>
                      Orçamento
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      value={id}
                      readOnly
                      disabled
                    />
                  </div>
                )}

                <div className='col-sm-4'>
                  <label htmlFor='' className='form-label'>
                    Cliente *
                  </label>
                  <AsyncSelectCliente
                    handleOptions={(optionSelected) => handleOptionsAsyncCliente(optionSelected)}
                    inputOption={selectedCliente}
                    isDisabled={showFiltroCliente}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        setSelectedCliente(null);
                      }
                    }}
                  />
                </div>
                <div className='col-sm-1 d-flex justify-content-center align-items-center flex-wrap'>
                  <button
                    type='button'
                    onClick={() => navigate('/cadastro-clientes')}
                    className='btn btn-success btn-sm mt-9'
                  >
                    <i className='bi bi-person-fill-add ' style={{fontSize: '18px'}}></i>
                  </button>
                </div>
                <div
                  className='col-sm-2'
                  style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  <label htmlFor='' className='form-label'>
                    Data Emissão
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    {...formik.getFieldProps('dataemissao')}
                    readOnly
                    disabled
                  />
                </div>
                {/* tipo === 'propostas' ? navigate('/listagem-propostas-comerciais') : navigate('/listagem-pedidos') */}
                <div className='col-sm-2'>
                  <label htmlFor='' className='form-label'>
                    Vencimento
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    {...formik.getFieldProps('vencimento')}
                    readOnly
                    disabled
                  />
                </div>
                {formik.values.status === 9 ? (
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Status
                    </label>
                    <select className='form-select form-select-sm' disabled>
                      <option value='9'> Sincronizado </option>
                    </select>
                  </div>
                ) : (
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Status
                    </label>
                    <select
                      className='form-select form-select-sm'
                      name=''
                      id=''
                      onChange={handleStatusSelectChange}
                      value={formik.values.status}
                    >
                      <option value='1'> Em aberto </option>
                      {/* <option value='2'> Negociação Encerrada </option> */}
                      <option value='0'> Cancelado </option>
                    </select>
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col-sm-2' style={{display: 'none'}}>
                  <label htmlFor='' className='form-label'>
                    Filtro
                  </label>
                  <select
                    className='form-select form-select-sm'
                    name=''
                    id=''
                    onChange={handleFiltroConsultorCheckChange}
                  >
                    <option value='funcionario'>Nome Consultor</option>
                  </select>
                </div>
                <div className='col-sm-4'>
                  <label htmlFor='' className='form-label'>
                    Consultor *
                  </label>
                  <AsyncSelectConsultor
                    handleOptions={(optionSelected) => handleOptionsAsyncConsultor(optionSelected)}
                    inputOption={selectedConsultor}
                    isDisabled={showFiltroConsultor}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        setSelectedConsultor(null);
                      }
                    }}
                  />
                </div>
                <div className='col-sm-3'>
                  <label className='form-label'>Almoxarifado *</label>
                  <select
                    className='form-select form-select-sm'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'almoxarifadoid')}
                    value={formik.values.almoxarifadoid}
                  >
                    <option value=''>Selecione</option>
                    {optionsAlmoxarifado.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className='col-sm-2'
                  style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  <label htmlFor='' className='form-label' style={{color: 'red'}}>
                    Tipo do Pedido *
                  </label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(event) => {
                      handleSelectChange(event, 'idTipoVenda');
                      setIdTipoVenda(Number(event.target.value));
                    }}
                    name=''
                    id=''
                    value={formik.values.idTipoVenda}
                    style={{color: 'red'}}
                  >
                    {optionsTipoVenda.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className='col-sm-2'
                  style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  <label htmlFor='' className='form-label'>
                    Tipo de Origem
                  </label>
                  <select
                    className='form-select form-select-sm'
                    onChange={(event) => handleSelectChange(event, 'tipoorigemid')}
                    name=''
                    id=''
                    value={formik.values.tipoorigemid}
                  >
                    <option value=''> Selecione </option>
                    {optionsOrigemPrevenda.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className='col-sm-1'
                  style={{minWidth: '160px', marginTop: '10px', display: 'flex'}}
                >
                  <label htmlFor='' className='form-label'>
                    Fluxo Logístico?
                  </label>
                  <div className='form-check form-switch mt-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault2'
                      checked={innerFlow === true}
                      onChange={handleInnerFlow}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                      <b>{innerFlow === true ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='col-sm-1'>
                  <h5> Projetos</h5>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-light btn-sm'
                    onClick={() =>
                      toggleExibir(exibirProjetos, setExibirProjetos, setIconeProjetos)
                    }
                  >
                    <i className={iconeProjetos}></i>
                  </button>
                </div>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>

              <div
                id='filtroDeProjeto'
                style={{display: exibirProjetos ? 'none' : 'block'}}
                className='mb-5'
              >
                <div className='row'>
                  <div className='col-sm-2 mb-3' style={{display: 'none'}}>
                    <label htmlFor='' className='form-label'>
                      Filtro
                    </label>
                    <select
                      className='form-select form-select-sm'
                      name=''
                      id=''
                      onChange={handleFiltroParceiroCheckChange}
                    >
                      <option value='parceiro'>Nome Parceiro</option>
                    </select>
                  </div>
                  <div className='col-sm-4 mb-3'>
                    <label htmlFor='' className='form-label'>
                      Profissional
                    </label>
                    <AsyncSelect
                      className='react-select-styled react-select-solid'
                      classNamePrefix='react-select'
                      placeholder='Pesquise o profissional'
                      loadOptions={loadOptionsParceiro}
                      isDisabled={showFiltroParceiro}
                      value={selectedParceiro}
                      onChange={(selectedOption: ClienteOption | null) => {
                        setSelectedParceiro(selectedOption);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setSelectedParceiro(null);
                        }
                      }}
                    />
                  </div>
                  <div className='col-sm-2' style={{display: 'none'}}>
                    <label htmlFor='' className='form-label'>
                      Filtro
                    </label>
                    <select
                      className='form-select form-select-sm'
                      name=''
                      id=''
                      onChange={handleFiltroCloserCheckChange}
                    >
                      <option value='closer'>Nome Closer</option>
                    </select>
                  </div>
                  <div className='col-sm-4'>
                    <label htmlFor='' className='form-label'>
                      Closer
                    </label>
                    <AsyncSelectConsultor
                      handleOptions={(closerOption) => handleOptionsAsyncCloser(closerOption)}
                      inputOption={selectedCloser}
                      isDisabled={showFiltroCloser}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setSelectedCloser(null);
                        }
                      }}
                    />
                  </div>
                  <div className='col-sm-2' style={{display: 'none'}}>
                    <label htmlFor='' className='form-label'>
                      Filtro
                    </label>
                    <select
                      className='form-select form-select-sm'
                      name=''
                      id=''
                      onChange={handleFiltroOrcamentistaChange}
                    >
                      <option value='orcamentista'>Nome Orçamentista</option>
                    </select>
                  </div>
                  <div className='col-sm-4'>
                    <label htmlFor='' className='form-label'>
                      Orçamentista
                    </label>
                    <AsyncSelectConsultor
                      handleOptions={(optionSelected) =>
                        handleOptionsAsyncOrcamentista(optionSelected)
                      }
                      inputOption={selectedOrcamentista}
                      isDisabled={showFiltroOrcamentista}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setSelectedOrcamentista(null);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <label htmlFor='flexSwitchCheckDefault1' className='form-label'>
                      Projeto?
                    </label>
                  </div>
                  <div className='col-sm-12 mt-2'>
                    <div className='form-check form-switch d-flex align-items-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault1'
                        checked={projetoStatus === 1}
                        onChange={handleProjetoStatus}
                      />
                      <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault1'>
                        <b>{projetoStatus === 1 ? 'Sim' : 'Não'}</b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='col-sm-1'>
                  <h5> Destinatário</h5>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-light btn-sm'
                    onClick={() =>
                      toggleExibir(exibirDestinatario, setExibirDestinatario, setIconeDestinarario)
                    }
                  >
                    <i className={iconeDestinarario}></i>
                  </button>
                </div>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>

              <div style={{display: exibirDestinatario ? 'none' : 'block'}} className='mb-5'>
                <div className='row mb-5'>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Razão Social
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='razaosocialDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      CNPJ
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='cnpjDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Telefone
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='telefoneDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-1'></div>
                </div>
                <div className='row mb-5'>
                  <div className='col-sm-2'>
                    <label className='form-label'> CEP </label>
                    <InputMask
                      // mask='99.999-999'
                      type='text'
                      className='form-control form-control-sm'
                      id='cepDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'> Endereço </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='enderecoDestino'
                      readOnly
                      disabled
                      maxLength={199}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Bairro</label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='bairroDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label className='form-label'> Cidade </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='cidadeDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-1'>
                    <label className='form-label'> UF </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='estadoDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-1'>
                    <label className='form-label'>Número</label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='numeroDestino'
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-sm-2'>
                    <label className='form-label'>Inscr. Estad. / ID</label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='inscEstadualDestino'
                      readOnly
                      disabled
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Ponto de Referência</label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='pontoReferenciaDestino'
                      readOnly
                      disabled
                      maxLength={39}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Complemento</label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='complementoDestino'
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='col-sm-1'>
                  <h5> Dados da Entrega</h5>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-light btn-sm'
                    onClick={() =>
                      toggleExibir(exibirDadosEntrega, setExibirDadosEntrega, setIconeDadosEntrega)
                    }
                  >
                    <i className={iconeDadosEntrega}></i>
                  </button>
                </div>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>
              <div
                id='filtroDeProjeto'
                style={{display: exibirDadosEntrega ? 'none' : 'block'}}
                className='mb-5'
              >
                <div className='row mb-5'>
                  <div className='col-sm-3'>
                    <label htmlFor='' className='form-label'>
                      Responsável 1
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('responsavel1Entrega')}
                      maxLength={39}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Celular
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('celular1Entrega')}
                      maxLength={20}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Profissão
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('profissao1Entrega')}
                      maxLength={29}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Data
                    </label>
                    <input
                      type='date'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('data1Entrega')}
                    />
                  </div>
                  <div className='col-sm-1'></div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Hora
                    </label>
                    <input
                      className='form-control form-control-sm'
                      type='time'
                      {...formik.getFieldProps('horaEntrega')}
                    />
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-sm-3'>
                    <label htmlFor='' className='form-label'>
                      Responsável 2
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('responsavel2Entrega')}
                      maxLength={39}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Celular 2
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('celular2Entrega')}
                      maxLength={20}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Profissão
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('profissao2Entrega')}
                      maxLength={29}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      Data
                    </label>
                    <input
                      type='date'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('data2Entrega')}
                    />
                  </div>
                  <div className='col-sm-1'></div>
                  <div className='col-sm-2'>
                    <label htmlFor='' className='form-label'>
                      CIF / FOB
                    </label>
                    <select
                      className='form-select form-select-sm'
                      {...formik.getFieldProps('cifFob')}
                      onChange={handleCifFob}
                      value={formik.values.cifFob}
                    >
                      <option value='0'> Selecionar </option>
                      <option value='1'> CIF </option>
                      <option value='2'> FOB </option>
                    </select>
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-sm-2'>
                    <label className='form-label'> CEP </label>
                    <InputMask
                      mask='99.999-999'
                      className='form-control form-control-sm'
                      {...formik.getFieldProps('cepEntrega')}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'> Endereço </label>
                    <input
                      type='text'
                      {...formik.getFieldProps('enderecoEntrega')}
                      className='form-control form-control-sm'
                      maxLength={199}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Bairro</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('bairroEntrega')}
                      className='form-control form-control-sm'
                      maxLength={29}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label className='form-label'> Cidade </label>
                    <input
                      type='text'
                      {...formik.getFieldProps('cidadeEntrega')}
                      className='form-control form-control-sm'
                      maxLength={39}
                    />
                  </div>
                  <div className='col-sm-1'>
                    <label className='form-label'> UF </label>
                    <input
                      type='text'
                      {...formik.getFieldProps('ufEntrega')}
                      className='form-control form-control-sm'
                      maxLength={10}
                    />
                  </div>
                  <div className='col-sm-1'>
                    <label className='form-label'>Número</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('numeroEntrega')}
                      onChange={(event) => {
                        formik.setFieldValue(
                          'numeroEntrega',
                          event.target.value.replace(/\D/g, '')
                        );
                      }}
                      className='form-control form-control-sm'
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-sm-5'>
                    <label className='form-label'>Ponto de Referência</label>
                    <input
                      className='form-control form-control-sm'
                      type='text'
                      {...formik.getFieldProps('pontoReferencia1Entrega')}
                      maxLength={39}
                    />
                  </div>
                  <div className='col-sm-5'>
                    <label className='form-label'>Ponto de Referência ll </label>
                    <input
                      className='form-control form-control-sm'
                      type='text'
                      {...formik.getFieldProps('pontoReferencia2Entrega')}
                      maxLength={39}
                    />
                  </div>
                </div>
              </div>
              <div
                className='mb-3 form-label-container'
                style={{display: 'flex', alignItems: 'center'}}
              >
                <h5>Link Kinbox</h5>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>
              <div className='row mb-5'>
                <div className='col-sm-12'>
                  <input
                    className='form-control form-control-sm'
                    placeholder='Insira o link kinbox'
                    {...formik.getFieldProps('kinboxlink')}
                    type='text'
                  />
                </div>
              </div>
              <div
                className='mb-3 form-label-container'
                style={{display: 'flex', alignItems: 'center'}}
              >
                <h5>Observações</h5>
                <div
                  style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}
                ></div>
              </div>
              <div className='col-sm-12'>
                <label htmlFor='flexSwitchCheckDefault2' className='form-label'>
                  Exibir observação na impressão?
                </label>
              </div>
              <div className='col-sm-12 mt-2 mb-2'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault2'
                    checked={obsStatus === 1}
                    onChange={handleObsStatus}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                    <b>{obsStatus === 1 ? 'Sim' : 'Não'}</b>
                  </label>
                </div>
              </div>
              <div className='mb-8 col-sm-12'>
                <label className='form-label'>Obs:</label>
                <textarea
                  className='form-control'
                  {...formik.getFieldProps('obsprevenda')}
                  maxLength={75}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    formik.setFieldValue('obsprevenda', value);
                  }}
                />
                <div className='char-counter' style={{color: 'black'}}>
                  {75 - formik.values.obsprevenda.length} caracteres restantes
                </div>
              </div>
            </div>
            <div
              className={
                isFixed ? 'container-products-details fixed' : 'container-products-details'
              }
            >
              <div className={isFixed ? 'painel-produto' : ''}>
                <div
                  className='mb-3 form-label-container'
                  style={{display: 'flex', alignItems: 'center'}}
                  id='section1'
                >
                  <h5>Listagem de Produtos</h5>
                  <div style={{flexGrow: 1, borderBottom: '2px solid #000', margin: '10px'}}></div>
                  {tipo === 'propostas' && (
                    <>
                      <h5 style={{marginRight: '10px'}}>Filtro?</h5>
                      <div className='form-check form-switch d-flex align-items-center'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id='flexSwitchCheckDefault2'
                          checked={filterProduct === 1}
                          onChange={handleFilterProduct}
                        />

                        <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                          <b>{filterProduct === 1 ? 'Sim' : 'Não'}</b>
                        </label>
                      </div>
                    </>
                  )}
                </div>

                {filterProduct === 1 && (
                  <>
                    <div className='row'>
                      <div className='container-listagem-produto align-center gap-4'>
                        <div className='col-sm-4'>
                          <DropdownMultipleChoice
                            label='Marca'
                            options={optionsMarca}
                            onSelect={handleSelectMarca}
                            selectedOptions={selectedOptionsMarca}
                            toggleDropdown={() => toggleDropdown(setIsOpenMarca)}
                            isOpen={isOpenMarca}
                            isMultiSelect={true}
                            disabled={false}
                            canWrite={true}
                          />
                        </div>

                        <div className='col-sm-2'>
                          <DropdownMultipleChoice
                            label='Tipo de Produto'
                            options={optionsTipoProduto}
                            onSelect={handleSelectTipoProduto}
                            selectedOptions={selectedOptionsTipoProduto}
                            toggleDropdown={() => toggleDropdown(setIsOpenTipoProduto)}
                            isOpen={isOpenTipoProduto}
                            isMultiSelect={true}
                            disabled={false}
                            canWrite={false}
                          />
                        </div>

                        <div className='col-sm-2'>
                          <DropdownMultipleChoice
                            label='Faixa de Preço'
                            options={statusMappingFaixaPreco}
                            onSelect={handleSelectFaixaPreco}
                            selectedOptions={selectedOptionFaixaPreco}
                            toggleDropdown={() => toggleDropdown(setIsOpenFaixaPreco)}
                            isOpen={isOpenFaixaPreco}
                            isMultiSelect={false}
                            disabled={false}
                            canWrite={false}
                          />
                        </div>

                        <div className='col-sm-2'>
                          <DropdownMultipleChoice
                            label='Linha de Produto'
                            options={statusMappingLinhaProduto}
                            onSelect={handleSelectLinhaProduto}
                            selectedOptions={selectedOptionLinhaProduto}
                            toggleDropdown={() => toggleDropdown(setIsOpenLinhaProduto)}
                            isOpen={isOpenLinhaProduto}
                            disabled={true}
                            isMultiSelect={false}
                            canWrite={false}
                          />
                        </div>

                        <div className='col-sm-2' style={{maxWidth: '180px'}}>
                          <label htmlFor='' className='form-label' style={{marginRight: '10px'}}>
                            Em encarte?
                          </label>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckProcura'
                              checked={filtrarEncarte}
                              onChange={handleToggleFiltrarEncarte}
                            />
                            <label
                              className='form-check-label ms-2'
                              htmlFor='flexSwitchCheckProcura'
                            >
                              <b>{filtrarEncarte ? 'Sim' : 'Não'}</b>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className='row' style={{marginTop: '20px'}}>
                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label' style={{marginRight: '10px'}}>
                            Com estoque?
                          </label>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckProcura'
                              checked={filtrarEstoque}
                              onChange={handleToggleFiltrarEstoque}
                            />
                            <label
                              className='form-check-label ms-2'
                              htmlFor='flexSwitchCheckProcura'
                            >
                              <b>{filtrarEstoque ? 'Sim' : 'Não'}</b>
                            </label>
                          </div>
                        </div>

                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label' style={{marginRight: '10px'}}>
                            Em promoção?
                          </label>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckProcura'
                              checked={filtrarPromocao}
                              onChange={handleToggleFiltrarPromocao}
                              disabled={true}
                            />
                            <label
                              className='form-check-label ms-2'
                              htmlFor='flexSwitchCheckProcura'
                            >
                              <b>{filtrarPromocao ? 'Sim' : 'Não'}</b>
                            </label>
                          </div>
                        </div>

                        <div className='col-sm-8'>
                          <div
                            className='d-flex justify-content-end flex-wrap'
                            style={{gap: '10px', marginTop: '20px'}}
                          >
                            <button
                              style={{minWidth: '160px'}}
                              type='button'
                              className='btn btn-success btn-sm'
                              onClick={() => {
                                setProducts([]);
                                setPage(0);
                                asyncFilterProducts();
                              }}
                            >
                              Filtrar
                            </button>

                            <button
                              style={{minWidth: '160px'}}
                              type='button'
                              className='btn btn-primary btn-sm'
                              onClick={() => {
                                setProducts([]);
                                setSelectedOptionsMarca([]);
                                setSelectedOptionsTipoProduto([]);
                                setSelectedOptionFaixaPreco('');
                                setSelectedOptionsLinhaProduto('');
                                setHasMore(false);
                                setPage(0);
                                setFiltrarEncarte(false);
                                setFiltrarEstoque(false);
                                setFiltrarPromocao(false);
                                setFilterProduct(0);
                                setIsFixed(true);
                              }}
                            >
                              Limpar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <Container className='mt-4' style={{maxWidth: '900px'}}>
                        <div style={{maxHeight: '400px', overflowY: 'scroll'}}>
                          {products.map((product: IProduto, index) => (
                            <Card
                              key={index}
                              className='mb-3 p-3'
                              style={{display: 'flex', alignItems: 'center'}}
                              onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = '#dfebff';
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = '#ffffff';
                              }}
                            >
                              <Row className='align-items-center'>
                                <Col xs={2}>
                                  <img
                                    src={
                                      product.urlImagem && product.urlImagem !== 'null'
                                        ? product.urlImagem
                                        : icon_img_not_found
                                    }
                                    alt='Product Image'
                                    style={{width: '100%', height: 'auto'}}
                                  />
                                </Col>
                                <Col xs={6}>
                                  <h6
                                    className='fw-bold'
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {product.produto || ' - '}
                                  </h6>
                                  <p className='mb-0'>
                                    <strong>Código:</strong>{' '}
                                    {product.idProduto !== 'null' ? product.idProduto : ' - '}{' '}
                                    <br />
                                    <strong>Preço:</strong> R${' '}
                                    {product.precoUnitario !== 'null'
                                      ? product.precoUnitario
                                      : ' - '}{' '}
                                    <br />
                                    <strong>Marca:</strong> {product.marca || ' - '} <br />
                                    <strong>Unidade:</strong> {product.unidade || ' - '}
                                  </p>
                                </Col>
                                <Col
                                  xs={4}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <p className='mb-0'>
                                    <strong>Em Encarte:</strong> {product.isEncarte ? 'SIM' : 'NÃO'}{' '}
                                    <br />
                                    <strong>Estoque Total:</strong>{' '}
                                    {product.estoqueTotal && product.estoqueTotal !== 'null'
                                      ? product.estoqueTotal
                                      : '0'}{' '}
                                    <br />
                                    <strong>Quantidade Prevista:</strong>{' '}
                                    {product.qtdPrevisto || ' - '} <br />
                                    <strong>Data Prevista:</strong>{' '}
                                    {product.dataPrevisao
                                      ? formatarData(product.dataPrevisao)
                                      : ' - '}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                          <div ref={tableEndRef}></div>
                          {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                              <button
                                className='btn btn-primary m-5'
                                onClick={loadMore}
                                type='button'
                              >
                                Carregar Mais
                              </button>
                            </div>
                          )}
                        </div>
                      </Container>
                    </div>
                    <div style={{flexGrow: 1, borderBottom: '2px solid #000', margin: '20px'}} />
                  </>
                )}

                <div className='row mb-5'>
                  <div className='col-sm-12' style={{marginTop: '10px'}}>
                    <label htmlFor='' className='form-label'>
                      Selecione o produto
                    </label>

                    <AsyncSelectProduct
                      handleOptions={(products) => handleOptionsAsyncProducts(products)}
                      inputOption={selectedProductAsync}
                      isDisabled={(totalGeral > 0 && valorAReceber <= 0 ? true : false) || editing}
                      isImg={true}
                    />
                  </div>
                </div>
                {loading ? (
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{height: '100%'}}
                  >
                    <div
                      className='spinner-border text-success m-5'
                      style={{width: '3rem', height: '3rem'}}
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      <div className='container-listagem-produto align-center gap-4 mt-8'>
                        <div className='col-sm-1'>
                          <label htmlFor='' className='form-label'>
                            Preço Padrão
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='number'
                            name=''
                            // value={formatarPrecoSemCifra(precoPadrao.toString()) || ''}
                            value={precoPadrao.toString() || ''}
                            // onChange={(e) => {
                            //   handleTotalChange(e.target.value, undefined, undefined);
                            // }}
                            id='precoPadrao'
                            disabled={true}
                          />
                        </div>

                        <div className='col-sm-1'>
                          <label htmlFor='' className='form-label'>
                            Preço Unitário
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id='precoUniProduto'
                            value={(selectedProductDetails?.precoUnitario ?? 0).toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            onChange={(e) => {
                              let value = e.target.value;

                              // Remove caracteres que não são dígitos
                              value = value.replace(/\D/g, '');

                              // Atualiza o valor no objeto selectedProductDetails
                              // if (selectedProductDetails) {
                              //   selectedProductDetails.precoUnitario = value;
                              // }

                              // Converte para float e formata para o padrão do backend
                              const floatValue = parseFloat(value) / 100;

                              // Atualiza o total chamando a função handleTotalChange
                              handleTotalChange(
                                floatValue || 0,
                                quantidade ?? 0,
                                descontoProdutoSelecionado ?? 0
                              );
                            }}
                            disabled={selectedProductDetails?.idProduto === '123'}
                          />
                        </div>

                        <div
                          className='col-sm-1'
                          style={{display: isPrecoUnitarioEditable ? '' : 'none'}}
                        >
                          <label
                            htmlFor=''
                            className='form-label'
                            style={{
                              color: 'green',
                              display: isPrecoUnitarioEditable ? '' : 'none',
                            }}
                          >
                            Valor Especial
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            name=''
                            id=''
                            value={
                              valorEspecial !== undefined && valorEspecial !== null
                                ? Number(valorEspecial).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ''
                            }
                            style={{
                              color: 'green',
                              border: '2px dashed green',
                              display: isPrecoUnitarioEditable ? '' : 'none',
                            }}
                            onChange={(event) => {
                              let inputValue = event.target.value;
                              inputValue = inputValue.replace(/\D/g, '');

                              const newValue = parseFloat(inputValue) / 100;

                              if (isNaN(newValue)) {
                                setValorEspecial(0.0);
                              } else {
                                setValorEspecial(newValue);
                              }

                              if (selectedProductDetails) {
                                setSelectedProductDetails({
                                  ...selectedProductDetails,
                                  obsproduto: observacaoProduto,
                                  precoUnitario: String(newValue),
                                });
                              }
                              setQuantidade(0);
                              // setTotalProdutoSelecionado(0);
                            }}
                          />
                        </div>

                        <div className='col-sm'>
                          <label htmlFor='' className='form-label'>
                            Quantidade
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            id=''
                            value={quantidade}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/\D/g, '');

                              handleTotalChange(undefined, inputValue, undefined);
                            }}
                          />
                        </div>

                        <div
                          className='col-sm-1'
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          <label htmlFor='' className='form-label'>
                            Desconto %
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/\D/g, '');

                              if (inputValue === '') {
                                handleTotalChange(undefined, undefined, 0);
                                e.target.value = '';
                                return;
                              }

                              let newValue = parseFloat(inputValue) || 0;

                              if (newValue > 10000) {
                                newValue = 10000;
                              }

                              handleTotalChange(undefined, undefined, newValue / 100);

                              const formattedValue = `${(newValue / 100).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`;
                              e.target.value = formattedValue;
                            }}
                            value={
                              descontoProdutoSelecionado !== undefined
                                ? `${Number(descontoProdutoSelecionado).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : ''
                            }
                            name=''
                            id=''
                          />
                        </div>

                        <div className='col-sm-0 mt-8'>
                          <a
                            href='https://www.mobills.com.br/calculadoras/calculadora-de-porcentagem/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <button type='button' className='btn btn-secondary btn-sm'>
                              <i className='bi bi-calculator-fill'></i>
                            </button>
                          </a>
                        </div>

                        <div className='col-sm-1'>
                          <label htmlFor='' className='form-label'>
                            Total
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            name=''
                            value={(isNaN(totalProdutoSelecionado)
                              ? 0
                              : totalProdutoSelecionado
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            id='totalProdutos'
                            disabled
                          />
                        </div>

                        {/* {ativaMargem ? ( */}
                        <div className='col-sm-1'>
                          <label htmlFor='' className='form-label'>
                            Margem %
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='number'
                            id=''
                            value={margem!}
                            onChange={(e) =>
                              handleTotalChange(undefined, e.target.value, undefined)
                            }
                            disabled
                            readOnly
                          />
                        </div>
                        {/* ) : (
                    <div
                      className='col-sm-1'
                      style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}
                    >
                      <label className='form-label'>Sem Margem</label>
                    </div>
                  )} */}

                        <div className='col-sm-2'>
                          <label className='form-label'>Tipo de Entrega</label>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Select example'
                            value={selectedTipoEntrega.id}
                            onChange={handleTipoEntregaChange}
                          >
                            <option value=''>Selecione</option>
                            {optionsTipoEntrega.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label'>
                            Loja de Origem
                          </label>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Select example'
                            value={selectedLojaOrigem.id}
                            onChange={handleLojaOrigemChange}
                          >
                            <option value=''>Selecione</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='container-listagem-produto gap-4 mt-8'>
                        <div className='col-sm'>
                          <label htmlFor='' className='form-label'>
                            Unidade
                          </label>
                          <input
                            className='form-control form-control-sm'
                            value={unidade}
                            disabled={true}
                          />
                        </div>
                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label'>
                            Tipo de Produto
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            name=''
                            id=''
                            value={tipoProduto}
                            disabled
                            readOnly
                          />
                        </div>
                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label'>
                            Fornecedor/Marca
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            name=''
                            id=''
                            value={marca}
                            disabled
                            readOnly
                          />
                        </div>
                        <div className='col-sm-2'>
                          <label htmlFor='' className='form-label'>
                            Estoque Total
                          </label>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            name=''
                            id=''
                            value={estoqueTotal}
                            disabled
                            readOnly
                          />
                        </div>
                        <div className='col-sm-0 mt-8' style={{}}>
                          <button
                            type='button'
                            className='btn btn-secondary btn-sm'
                            onClick={() => setShowModalEstoque(true)}
                          >
                            <i className='bi bi-search'></i>
                          </button>
                        </div>
                        <div className='col'></div>
                        <div
                          className='col-sm-2'
                          style={{display: exibirProjetos ? 'none' : 'block'}}
                        >
                          <label htmlFor='' className='form-label'>
                            Ambiente
                          </label>
                          <div className='row'>
                            <AsyncSelect
                              className='react-select-styled react-select-solid'
                              classNamePrefix='react-select'
                              placeholder={'Pesquise o ambiente'}
                              loadOptions={loadOptionsAmbiente}
                              isDisabled={showFiltroAmbiente}
                              value={selectedAmbienteAsync}
                              isClearable
                              onChange={handleChangeAmbiente}
                            />
                          </div>
                        </div>
                        <div
                          className='col-sm-2'
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: exibirProjetos ? 'none' : 'block',
                          }}
                        >
                          <label htmlFor='' className='form-label'>
                            Complemento Ambiente
                          </label>
                          <input
                            className='form-control form-control-sm'
                            value={ambienteComplemento}
                            onChange={handleComplementoAmbiente}
                            type='text'
                            name=''
                            id=''
                            disabled={!selectedAmbienteAsync}
                          />
                        </div>
                      </div>
                      <div className='container-listagem-produto align-center gap-4 mt-8'>
                        <div className='col-sm-3'>
                          <label htmlFor='' className='form-label'>
                            Tabela de Preço(Produto)
                          </label>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Select example'
                            onChange={handleTabelaDePrecoProduto}
                            value={selectedTabelaDePrecoProduto.id}
                          >
                            <option value=''>Selecione</option>
                            {optionsTabelaDePrecoProduto.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-sm-7'>
                          <label htmlFor='' className='form-label'>
                            Observação
                          </label>
                          <input
                            className='form-control form-control-sm'
                            value={observacaoProduto}
                            onChange={handleObsProduto}
                            type='text'
                            name=''
                            id=''
                            style={{backgroundColor: observacaoProduto ? '#FFF9C4' : 'white'}}
                          />
                        </div>
                        <div className='col-sm-2'>
                          <label htmlFor='flexSwitchCheckDefault2' className='form-label-sm'>
                            Exibir observação na impressão?
                          </label>
                          <div className='mt-2 mb-2'>
                            <div className='form-check form-switch d-flex align-items-center'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='flexSwitchCheckDefault2'
                                checked={obsStatusPrd === 1}
                                onChange={handleObsStatusPrd}
                              />
                              <label
                                className='form-check-label ms-2'
                                htmlFor='flexSwitchCheckDefault2'
                              >
                                <b>{obsStatusPrd === 1 ? 'Sim' : 'Não'}</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex gap-8 mt-8 mb-8'>
                        <button
                          type='button'
                          className='btn-cadastro btn-success'
                          style={{
                            minWidth: '160px',
                            display: showChangeSkuButton ? 'none' : 'block',
                          }}
                          onClick={handleAddProduct}
                          disabled={
                            !selectedProductDetails || (totalGeral > 0 && valorAReceber <= 0)
                              ? true
                              : false
                          }
                        >
                          {editing ? 'Atualizar Produto' : 'Adicionar Produto'}
                        </button>

                        {showChangeSkuButton && (
                          <button
                            type='button'
                            className='btn-cadastro btn btn-info'
                            style={{minWidth: '160px'}}
                            onClick={(e) => {
                              handleChangeSkuButton();
                              setUpdateProduto(true);
                            }}
                          >
                            Trocar SKU
                          </button>
                        )}

                        <button
                          type='button'
                          className='btn-cadastro btn-danger'
                          style={{minWidth: '160px'}}
                          onClick={(e) => {
                            handleStopEditing();
                            setUpdateProduto(false);
                          }}
                          disabled={totalGeral > 0 && valorAReceber <= 0 ? true : false}
                        >
                          Cancelar Edição
                        </button>

                        <div>
                          <label htmlFor='' className='form-label'>
                            Fixar
                          </label>
                          <div className='form-check form-switch mt-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckDefault2'
                              checked={isFixed}
                              onChange={() => setIsFixed((prev) => !prev)}
                            />
                            <label
                              className='form-check-label ms-2'
                              htmlFor='flexSwitchCheckDefault2'
                            >
                              <b>{isFixed === true ? 'Sim' : 'Não'}</b>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              className={isFixed ? 'tableProdSelect mb-10 table-in-fixed' : 'tableProdSelect mb-10'}
            >
              <Table>
                <TabContainer>
                  <TableProdutosPrevenda
                    className='card-flush h-md-100'
                    onRemoveProduct={handleRemoveProduct}
                    onEditProduct={handleEditProduct}
                    infosProd={infosProd}
                    setInfosProd={setInfosProd}
                    selectedProductDetails={selectedProductDetails}
                    totalGeral={totalGeral}
                    valorAReceber={valorAReceber}
                    setOrdenacao={setOrdenacao}
                    ordenacao={ordenacao ?? 0}
                    setUpdateProduto={setUpdateProduto}
                    handleChangeSKU={handleChangeSKU}
                    idPedido={Number(id)}
                    isFluxoLogistico={innerFlow}
                  />
                </TabContainer>
              </Table>

              <div className='container-listagem-produto' style={{justifyContent: 'space-between'}}>
                <div
                  className='col-sm-6'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    gap: 10,
                    marginLeft: 2,
                  }}
                >
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <label
                      htmlFor=''
                      className='form-label'
                      style={{marginTop: 10, fontSize: '16px'}}
                    >
                      Cliente encontrou tudo que procurava?
                    </label>
                    <div className='form-check form-switch '>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckProcura'
                        checked={produtoNaoEncontradoStatus === 1}
                        onChange={handleProdutoNaoEncontradoStatus}
                      />
                      <label className='form-check-label ms-2' htmlFor='flexSwitchCheckProcura'>
                        <b>{produtoNaoEncontradoStatus === 1 ? 'Sim' : 'Não'}</b>
                      </label>
                    </div>
                  </div>

                  <div style={{visibility: produtoNaoEncontradoStatus ? 'hidden' : 'visible'}}>
                    <label className='form-label'>Descreva os produtos: </label>
                    <textarea
                      className='form-control '
                      {...formik.getFieldProps('produtoNaoEncontrado')}
                      maxLength={150}
                      onChange={(e) => {
                        formik.setFieldValue('produtoNaoEncontrado', e.target.value);
                      }}
                    />
                    <div className='char-counter' style={{color: 'black'}}>
                      {150 - formik.values.produtoNaoEncontrado.length} caracteres restantes
                    </div>
                  </div>
                </div>
                <div
                  className='todos-produtos'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='col-sm-5'>
                      <label htmlFor='' className='form-label'>
                        Tipo Entrega
                      </label>
                      <select
                        className='form-select form-select-sm'
                        aria-label='Select example'
                        value={selectedTipoEntregaTodos.id}
                        onChange={handleTipoEntregaChangeTodos}
                      >
                        {optionsTipoEntrega.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-sm-5'>
                      <label htmlFor='' className='form-label'>
                        Loja de Origem
                      </label>
                      <select
                        className='form-select form-select-sm'
                        aria-label='Select example'
                        value={selectedLojaOrigemTodos.id}
                        onChange={handleLojaOrigemChangeTodos}
                      >
                        <option value=''>Selecione</option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <button
                    type='button'
                    className='btn btn-warning todos-produtos'
                    onClick={() => {
                      setShowModalConfirmEntrega(true);
                      setUpdateProduto(true);
                    }}
                  >
                    Aplicar entrega a todos os produtos
                  </button>
                </div>
              </div>
            </div>
            <div
              className='mb-3 form-label-container'
              style={{display: 'flex', alignItems: 'center'}}
              id='section-payment'
            >
              <h5>Desconto</h5>
              <div style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}></div>
            </div>
            <div className='container-listagem-produto mb-5 align-center gap-6 mt-8'>
              <div className='col-sm-2'>
                <label htmlFor='' className='form-label'>
                  Desconto %
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  {...formik.getFieldProps('descontoporcentagem')}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos

                    if (inputValue === '') {
                      handleTotalChange(undefined, undefined, 0);
                      e.target.value = '';
                      return;
                    }

                    let newValue = parseFloat(inputValue) || 0; // Converte para float ou 0

                    if (newValue > 10000) {
                      newValue = 10000; // Limita o valor máximo
                    }

                    const formattedValue = (newValue / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });

                    e.target.value = formattedValue; // Atualiza o valor no input

                    handleDescontoGeralChange(e); // Chama a função de alteração do desconto
                  }}
                  disabled={valorAReceber === 0} // Desabilita o input se valor a receber for 0
                />
              </div>

              <div className='row'>
                <label htmlFor='flexSwitchCheckFrete' className='form-label'>
                  Frete
                </label>
                <div className='col-sm-8 mt-2'>
                  <div className='form-check form-switch d-flex align-items-center'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckFrete'
                      {...formik.getFieldProps('freteStatus')}
                      checked={freteStatus === 1}
                      onChange={handleFreteStatus}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckFrete'>
                      <b>{freteStatus === 1 ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className='row'>
                <label htmlFor='flexSwitchCheckEntregaParcial' className='form-label'>
                  Entrega Parcial
                </label>
                <div className='col-sm-8 mt-2'>
                  <div className='form-check form-switch d-flex align-items-center'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckEntregaParcial'
                      {...formik.getFieldProps('entregaParcial')}
                      checked={isEntregaParcial === 1}
                      onChange={handleEntregaParcial}
                    />
                    <label
                      className='form-check-label ms-2'
                      htmlFor='flexSwitchCheckEntregaParcial'
                    >
                      <b>{isEntregaParcial === 1 ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className='col-sm-3'>
                <label htmlFor='' className='form-label'>
                  Valor Frete R$
                </label>
                <input
                  disabled={!freteStatus ? true : false}
                  type='text'
                  className='form-control form-control-sm'
                  value={formik.values.valorFreteVisual}
                  onChange={handleValorFrete}
                />
              </div>
            </div>

            <div className='mb-8'>
              <label className='form-label'>Observação da entrega: </label>
              <textarea
                className='form-control '
                {...formik.getFieldProps('observacaoEntrega')}
                maxLength={200}
                onChange={(e) => {
                  formik.setFieldValue('observacaoEntrega', e.target.value);
                }}
              />
              <div className='char-counter' style={{color: 'black'}}>
                {200 - formik.values.observacaoEntrega.length} caracteres restantes
              </div>
            </div>

            <div
              className='mb-3 form-label-container'
              style={{display: 'flex', alignItems: 'center'}}
            >
              <h5>Informações de Pagamento </h5>
              <div style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}></div>
            </div>
            <div className='mb-5 '>
              {renderFatura()}

              <div
                className='container-listagem-produto align-center gap-4 mt-8'
                style={{justifyContent: 'space-between'}}
              >
                <div
                  className='col-sm-2'
                  style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  <label htmlFor='formapagamentoid' className='form-label'>
                    Forma de Pagamento
                  </label>
                  <select
                    className='form-select form-select-sm'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'formapagamentoid')}
                    value={formik.values.formapagamentoid || ''}
                  >
                    <option value=''>Selecione</option>
                    {optionsFormaPagamento.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className='col-sm-2'
                  style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  <label htmlFor='' className='form-label'>
                    Condição Pagamento
                  </label>
                  <select
                    className='form-select form-select-sm'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'condicaoid')}
                    value={formik.values.condicaoid}
                  >
                    <option value=''>Selecione</option>
                    {optionsCondicoesPagamento.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-sm-2'>
                  <label htmlFor='' className='form-label'>
                    Valor Recebido
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    value={valorRecebido}
                    onChange={(e) => {
                      let value = e.target.value;

                      value = value.replace(/\D/g, '');

                      if (value) {
                        value = (parseFloat(value) / 100).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });
                      }

                      setValorRecebido(value);
                    }}
                  />
                </div>
                <div className='col-sm-2'>
                  <div className='row'>
                    <div
                      className='col-sm-12'
                      style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                    >
                      <label htmlFor='flexSwitchCheckDefault3' className='form-label'>
                        Pagamento Principal
                      </label>
                    </div>
                    <div className='col-sm-12 mt-2'>
                      <div className='form-check form-switch d-flex align-items-center'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id='flexSwitchCheckDefault3'
                          checked={pagamentoPrincipal === 1}
                          onChange={handlePagamentoPrincipal}
                          disabled={existePagamentoPrincipal}
                        />
                        <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault3'>
                          <b>{pagamentoPrincipal === 1 ? 'Sim' : 'Não'}</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-8 mt-10'>
                <div className='col-sm-3'>
                  <button
                    type='button'
                    className='btn btn-success btn-sm'
                    onClick={handleAddFormaPagamento}
                    disabled={valorAReceber === 0}
                  >
                    Adicionar Forma de Pagamento
                  </button>
                </div>
              </div>

              <Table>
                <TabContainer>
                  <TableFormasDePagamento
                    className='card-flush h-md-100'
                    infosPagamento={infosPagamento.map((pagamento) => ({
                      ...pagamento,
                      valorFormatado: pagamento.valorRecebido.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                    }))}
                    onRemovePagamento={handleRemovePagamento}
                  />
                </TabContainer>
              </Table>
            </div>

            <div
              className='row mb-5'
              style={{backgroundColor: '#ffffff', width: '100%', height: 'auto', padding: '10px'}}
            >
              {renderValoresTotais()}
            </div>

            <div className='d-flex gap-8'>
              {tipo === 'propostas' &&
                isState !== 0 &&
                isState !== 2 &&
                isState !== 3 &&
                isState !== 4 &&
                isState !== 9 &&
                (
                  <button
                    type='submit'
                    className='btn-cadastro btn-bg-success'
                    id='post_user_submit'
                  >
                    {id ? 'Atualizar' : 'Cadastrar'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Aguarde...
                        <span className='spinner-border spinner-border-sm align-middle ms-3'></span>
                      </span>
                    )}
                  </button>
                )}

              <button
                type='button'
                className='btn-cadastro btn-danger'
                id='back'
                onClick={() =>
                  tipo === 'propostas'
                    ? navigate('/listagem-propostas-comerciais')
                    : navigate('/listagem-pedidos')
                }
              >
                Voltar
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-3'></span>
                  </span>
                )}
              </button>

              {/* {tipo === 'propostas' && formik.values.status === 2 && (
              <button
                type='button'
                className='btn-cadastro btn-primary'
                id='pedido'
                onClick={() => handleGerarPedido(id, navigate)}
                style={{marginLeft: 'auto'}}
              >
                Gerar Pedido
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-3'></span>
                  </span>
                )}
              </button>
            )} */}
            </div>

            <Modal
              size={'sm'}
              show={showModalEstoque}
              centered={true}
              onHide={() => setShowModalEstoque(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Detalhes estoque</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>{selectedProductDetails?.produto}</h5>
                <div>
                  {selectedProductDetails && selectedProductDetails.estoque.length > 0 ? (
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>WMS</th>
                          <th>Estoque</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductDetails?.estoque.map((estoque, index) => (
                          <tr key={index}>
                            <td>{estoque.wms}</td>
                            <td>{Math.floor(estoque.EstoqueProduto)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Nenhum produto selecionado.</p>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowModalEstoque(false)}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </form>
      )}
      <Modal
        size={'sm'}
        show={showModalConfirmEntrega}
        centered={true}
        onHide={() => setShowModalConfirmEntrega(false)}
      >
        <Modal.Header closeButton className='text-center'>
          <Modal.Title>Detalhes Entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Deseja alterar o tipo de entrega para todos os produtos? </h5>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-info' onClick={() => tipoEntregaParaTodos()}>
            Confirmar
          </button>
          <Button variant='secondary' onClick={() => setShowModalConfirmEntrega(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size={'sm'}
        show={showModalInnerFlow}
        centered={true}
        onHide={() => setShowModalInnerFlow(false)}
        animation={true}
      >
        <Modal.Header closeButton className='text-center'>
          <Modal.Title>Ativação de Fluxo Logístico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            O fluxo logístico permite que o SYSLED controle a separação/expedição do pedido.
            <br />
            <br /> Deseja realmente ativar a função?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-info'
            onClick={() => {
              // Confirma ativação e fecha o modal
              setInnerFlow(true);
              setShowModalInnerFlow(false);
            }}
          >
            Confirmar
          </button>
          <Button
            variant='secondary'
            onClick={() => {
              // Apenas fecha o modal sem alterar o estado do toggle
              setShowModalInnerFlow(false);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
