import axios from 'axios';

interface ProductOption {
    id: number;
    descricao: string;
}

interface OptionType {
  value: number;
  label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const formatOptions = (data: ProductOption[]): OptionType[] => {
  return data.map((item) => ({
    value: item.id,
    label: item.descricao,
  }));
};

const fetchMarcaProductOptions = async () => {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/marcasProdutos/optionsMarcaProdutos`
    );
    return formatOptions(response.data);
  } catch (error) {
    console.error('Erro ao buscar opções:', error);
    return [];
  }
};

export default fetchMarcaProductOptions;
