/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendParceiro,
  updateParceiro,
} from "../cadastro_requests/cadastro_requests";
import InputMask from "react-input-mask";
import clsx from "clsx";
import { submitForm } from "../../useUtils/useSubmitFormParceiro";
import useOptionsTipoParceiro from "../../selectOptions/useOptionTipoParceiro";
import Select from "react-select";
import useOptionsFuncionariosClientes from "../../selectOptions/useOptionsFuncionariosClientes";
import {
  Infobanco,
  InfosPessoaContato,

  VendedoresOptions,
  cadastroValidationSchema,
  formParceiro,
} from "./Interface";
import useBuscaCep from "../../useUtils/useBuscaCep";
import useBuscaCnpjParceiro from "../../useUtils/useBuscaCnpjParceiro";
import { getParceiroById } from "./service/parceiros";
import dayjs from "dayjs";
import "./style/style.css";
import { TabContainer, Table } from "react-bootstrap";
import { TablePessoasContato } from "../clientes/components/ListagemPessoasContato";
import moment from "moment";
import PageTitulo from "../../components/Pagetitulo";
import icon_img_not_found from "../../../pages/../assets/icon_img_not_found (1).jpg";
import "./style/style.css";
import useOptionsPerfilParceiro from "../../selectOptions/useOptionsPerfilParceiro";
import { OptionType } from "../produto/Interface";
import useOptionsBanco from "../../selectOptions/useOptionsBanco";
import useOptionsVendedores from "../../selectOptions/useOptionsVendedores";
import axios from "axios";
import useOptionsClassificacao from "../../selectOptions/useOptionsClassificacao";
import e from "express";
import { format } from 'date-fns';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "43.56px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007bff"
      : state.isFocused
        ? "#cce5ff"
        : "#fff",
    color: state.isSelected ? "#fff" : "#000",
  }),
};

const CadastroParceiros = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { optionsTipoParceiro } = useOptionsTipoParceiro();
  const { optionsBanco } = useOptionsBanco();
  const { optionsClassificacao } = useOptionsClassificacao();
  useOptionsPerfilParceiro();
  const { optionsVendedores } = useOptionsVendedores();
  const { optionsFuncionariosClientes } = useOptionsFuncionariosClientes();

  const [selectedVendedor, setSelectedVendedor] = useState<VendedoresOptions[]>(
    []
  );
  const [isPF, setIsPF] = useState(true);
  const [activeSection, setActiveSection] = useState("general");
  const [historicoCompras, setHistoricoCompras] = useState([]);
  const [showInputRow, setShowInputRow] = useState(false);
  const [infosPessoaContato, setInfosPessoaContato] = useState<InfosPessoaContato[]>([]);

  const [newPessoa, setNewPessoa] = useState<InfosPessoaContato>({
    nome: '',
    setor: '',
    email: '',
    telefone: '',
    ramal: '',
  });

  const [base64Image, setBase64Image] = useState<string>("");
  const [selectedBanco, setOptionsBanco] = useState<OptionType>();
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [agencia, setAgencia] = useState<number>(0);
  const [conta, setConta] = useState<number>(0);
  const [observacao, setObservacao] = useState<string>("");
  const [selectedtipoChavePix, setSelectedtipoChavePix] = useState<string>("");
  const [chavePix, setChavePix] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [isParceiro, setIsParceiro] = useState(0);
  const [selectedClassificacao, setSelectedClassificacao] = useState<OptionType>();
  const [IdParceiroClassificacao, setIdParceiroClassificacao] = useState(0);
  const [createdat, setCreatedat] = useState(new Date());
  const [parceiroClassificacaoTitulo, setParceiroClassificacaoTitulo] = useState('');
  const [descricaoTipoProfissional, setDescricaoTipoProfissional] = useState('');


  const formattedDate = format(createdat, 'dd/MM/yyyy');

  const formik = useFormik({
    initialValues: formParceiro,
    validationSchema: cadastroValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      submitForm(
        id,
        values,
        infosPessoaContato,
        updateParceiro,
        sendParceiro,
        selectedVendedor,
        setLoading,
        setSubmitting,
        setStatus,
        navigate,
        base64Image,
        selectedBanco,
        isParceiro,
        IdParceiroClassificacao,
        createdat,
        parceiroClassificacaoTitulo,
        descricaoTipoProfissional


      );
    },
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        alert(
          "Formato de arquivo inválido. Por favor, selecione um arquivo PNG, JPG ou JPEG."
        );
        event.target.value = "";
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert(
          "O arquivo é muito grande. Por favor, selecione um arquivo menor que 2MB."
        );
        event.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setBase64Image(base64data);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);

    if (name === "nome_fantasia") {
      formik.setFieldValue("nome_razaosocial", value);
    }
    if (name === "IdParceiroClassificacao") {
      formik.setFieldValue("IdParceiroClassificacao", parseInt(value, 10));
    }
  };


  const handleVendedoresChange = (selectedVendedor) => {
    setSelectedVendedor(selectedVendedor);
  };

  const handleBancoChange = (selectedBanco) => {
    setOptionsBanco(selectedBanco);
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue("status", event.target.value);
  };

  const handleTipoChaveChange = (e) => {
    setSelectedtipoChavePix(e.target.value);
  };
  const getMaskBanco = () => {
    switch (formik.values.tipoDeChave) {
      case 1: // CPF
        return "999.999.999-99";
      case 2: // CNPJ
        return "99.999.999/9999-99";
      case 3: // Número de Celular
        return "(99) 99999-9999";
      case 4: // Email
        return ""; // Sem máscara para e-mail
      default:
        return "";
    }
  };

  const getPlaceholder = () => {
    switch (formik.values.tipoDeChave) {
      case 1: // CPF
        return "Digite o CPF";
      case 2: // CNPJ
        return "Digite o CNPJ";
      case 3: // Número de Celular
        return "Digite o número de celular";
      case 4: // Email
        return "Digite o e-mail";
      default:
        return "Selecione o tipo de chave Pix";
    }
  };

  const determineMask = () => {
    switch (selectedtipoChavePix) {
      case "1": // CPF
        return "999.999.999-99";
      case "2": // CNPJ
        return "99.999.999/9999-99";
      case "3": // Número de Celular
        return "(99) 99999-9999";
      default:
        return "";
    }
  };

  const handleTipoCadastroChange = (event) => {
    formik.setFieldValue("tipo_cadastro", event.target.value);
    const valorselecionado = event.target.value;
    if (valorselecionado === "0") {
      setIsPF(false);
    } else if (valorselecionado === "1") {
      setIsPF(true);
    } else {
      setIsPF(true);
    }
  };

  const handleObsParceiro = (e) => {
    const { checked } = e.target;
    formik.setFieldValue("is_parceiro", checked ? 1 : 0);
  };



  useEffect(() => {
    if (selectedBanco) {
      setOptionsBanco(selectedBanco);
    }
  }, [selectedBanco]);

  useEffect(() => {
    if (id) {
      getParceiroById(id).then((response) => {
        if (response) {
          if (response.tipopessoa === 0) {
            setIsPF(false);
          } else if (response.tipopessoa === 1) {
            setIsPF(true);
          }

          formik.setValues({
            ...formik.values,
            nome_razaosocial: response.parceiro || "",
            nome_fantasia: response.fantasia || "",
            cnpj_cpf: response.cnpj || "",
            inscricao_estudal_id: response.inscricaoestadual || "",
            comissao: response.comissao || "",
            vendedor: response.vendedorid || "",
            tipo_parceiro: response.tipoparceiro || "",
            tipo_cadastro: response.tipopessoa || "",
            cep: response.cep || "",
            endereco: response.endereco || "",
            numero: response.numero || "",
            bairro: response.bairro || "",
            complemento: response.complemento || "",
            cidade: response.cidade || "",
            estado: response.uf || "",
            telefone: response.telefone || "",
            celular: response.celular || "",
            email: response.email || "",
            data_nasc:
              dayjs(response.nascimento).format("YYYY-MM-DD") || undefined,
            website: response.website || "",
            status: response.status || "",
            observacao: response.observacao || "",
            img_parceiro_base64: response.img_parceiro_base64 || "",
            banco: response.idBanco,
            agencia: response.agencia,
            conta: response.conta,
            observacoes: response.observacoes,
            tipoDeChave: response.tipoChavePix,
            chavePix: response.chavePix,
            obsStatus: response.obsStatus || 0,
            is_parceiro: response.is_parceiro,
            createdat: response.createdat,
            idParceiroClassificacao: response.idParceiroClassificacao,
            parceiroClassificacaoTitulo: response.parceiroClassificacaoTitulo,
            descricaoTipoProfissional: response.descricaoTipoProfissional,
          });
          if (response.img_parceiro_base64) {
            setBase64Image(response.img_parceiro_base64);
          }

          if (response && response.parceirosVendedores) {
            const mappedVendedores = response.parceirosVendedores.map(
              (vendedor) => ({
                value: vendedor.idFuncionario,
                label: vendedor.funcionario,
              })
            );
            setSelectedVendedor(mappedVendedores);
          }

          setInfosPessoaContato(
            response.pessoasContato.map((pessoaContato) => ({
              nome: pessoaContato.nome || "",
              setor: pessoaContato.setor || "",
              email: pessoaContato.email || "",
              telefone: pessoaContato.telefone || "",
              ramal: pessoaContato.ramal || "",
            }))
          );
        }
      });
    } else {
      setInfosPessoaContato([]);
      setSelectedVendedor([]);
      formik.setValues(formParceiro);
    }
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPessoa({ ...newPessoa, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaNomeParceiroApi/${inputValue};
      const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/bancos/contas`);

    } catch (error) {
      console.error('Erro', error);
    }
  };

  const getLabel = () => {
    const tipoCadastro = formik.values.tipo_cadastro;
    if (tipoCadastro === "2" || tipoCadastro === "4") {
      return "Nº Identificação";
    }
    return isPF ? "CPF" : "CNPJ";
  };

  const getMask = () => {
    const tipoCadastro = formik.values.tipo_cadastro;
    if (tipoCadastro === "2" || tipoCadastro === "4") {
      return "";
    }
    return isPF ? "999.999.999-99" : "99.999.999/9999-99";
  };

  const handleAddPessoa = () => {
    setInfosPessoaContato((prevState) => [...prevState, { ...newPessoa, id: prevState.length + 1 }]);
    setNewPessoa({
      nome: '',
      setor: '',
      email: '',
      telefone: '',
      ramal: '',
    });
    setShowInputRow(false);
  };

  const handleCancel = () => {
    setShowInputRow(false);
    setNewPessoa({
      nome: "",
      setor: "",
      email: "",
      telefone: "",
      ramal: "",
    });
  };

  const handleShowInputRow = () => {
    setShowInputRow(true);
  };

  const handleRemovePessoa = (index: number) => {
    setInfosPessoaContato((prevState) =>
      prevState.filter((_, i) => i !== index)
    );
  };

  const handleEditPessoa = (index: number, pessoa: InfosPessoaContato) => {
    setInfosPessoaContato((prevState) =>
      prevState.map((item, i) => (i === index ? pessoa : item))
    );
  };

  useBuscaCnpjParceiro(formik.values.cnpj_cpf, formik);
  useBuscaCep(formik.values.cep, formik);


  const renderizarHistoricoCompras = (historicoCompras) => {
    return (
      <div className="text-center table-container table-responsive">
        <table className=" table-hover tablehist table-rounded table-row-bordered">
          <thead className="thead-dark">
            <tr className="fw-bold fs-6 text-gray-800">
              <th className="text-center" style={{ width: "5%" }}>
                Id Produto
              </th>
              <th className="text-center" style={{ width: "40%" }}>
                Produto
              </th>
              <th className="text-center" style={{ width: "5%" }}>
                Quant.
              </th>
              <th className="text-center" style={{ width: "5%" }}>
                Preço Venda
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                Tipo Produto
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                Prod. Filho
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                Grupo
              </th>
              <th
                className="text-center"
                style={{ width: "5%", marginRight: "10px" }}
              >
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {historicoCompras.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{item.idProduto}</td>
                <td
                  className="text-center"
                  style={{ width: "50px", height: "50px" }}
                >
                  {item.produto}
                </td>
                <td className="text-center">{item.produtoQuantidade}</td>
                <td className="text-center">{`R$${parseFloat(
                  item.precoVenda
                ).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}</td>
                <td className="text-center">{item.tipoProduto}</td>
                <td className="text-center">{item.produtoFilho}</td>
                <td className="text-center">{item.grupo}</td>
                <td className="text-center">
                  {moment(item.dataFinalizacaoPrevenda).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  function handleRemoveBanco(option: OptionType): void {
    throw new Error("Function not implemented.");
  }

  return (
    <form
      className="form-control-solid"
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    >
      <div className="text-light-dark mb-10">
        <PageTitulo id={id} tipoFormulario="Profissional" />

        <div className=" mt-6">
          <div className="col-sm-9">
            <div className="btn-group custom-nav-tabs" role="group">
              <div>
                <button
                  type="button"
                  className={`btn btn-outline-secondary ${activeSection === "perfil" ? "active" : ""
                    }`}
                  onClick={() => setActiveSection("perfil")}
                >
                  Perfil
                </button>
              </div>
              <button
                type="button"
                className={`btn btn-outline-secondary ${activeSection === "general" ? "active" : ""
                  }`}
                onClick={() => setActiveSection("general")}
              >
                Dados gerais
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary ${activeSection === "profissionais" ? "active" : ""
                  }`}
                onClick={() => setActiveSection("profissionais")}
              >
                Profissionais
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary ${activeSection === "dadosComplementares" ? "active" : ""
                  }`}
                onClick={() => setActiveSection("dadosComplementares")}
              >
                Dados Complementares
              </button>

              <button
                type="button"
                className={`btn btn-outline-secondary ${activeSection === "Financeiro" ? "active" : ""
                  }`}
                onClick={() => setActiveSection("Financeiro")}
              >
                Financeiro
              </button>

              <button
                type="button"
                className={`btn btn-outline-secondary ${activeSection === "historico" ? "active" : ""
                  }`}
                onClick={() => setActiveSection("historico")}
              >
                Histórico
              </button>
            </div>
          </div>
        </div>
        {activeSection === "general" && (
          <>
            <div className="mb-5 mt-8 ">
              <div className='col-sm-12'>
                <label htmlFor='flexSwitchCheckDefault2' className='form-label'>
                  É Parceiro?
                </label>
              </div>
              <div className='col-sm-12'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault2'
                    checked={formik.values.is_parceiro === 1}
                    {...formik.getFieldProps("is_parceiro")}
                    onChange={handleObsParceiro}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                    <b>{formik.values.is_parceiro === 1 ? 'Sim' : 'Não'}</b>
                  </label>
                </div>

              </div>
              <div className="mb-5 form-inputs">
                <div className="col-md-3 mt-4">
                  <label className="form-label">Tipo de Cadastro</label>
                  <select
                    defaultValue={""}
                    required
                    className="form-select bg-transparent"
                    {...formik.getFieldProps("tipo_cadastro")}
                    value={formik.values.tipo_cadastro}
                    onChange={handleTipoCadastroChange}
                    style={{ marginTop: "0px" }}
                  >
                    <option value="" disabled selected>
                      Selecione...
                    </option>
                    <option value="1"> Autônomo </option>
                    <option value="0"> Escritório </option>
                    <option value="2"> Estrangeiro </option>
                    <option value="4"> Estrangeiro no Brasil</option>
                  </select>
                </div>
                <div className="col-md-3 mt-4">
                  <label className="form-label">Tipo de Profissional *</label>
                  <select
                    defaultValue={""}
                    className={clsx(
                      "form-select bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.tipo_parceiro &&
                          formik.errors.tipo_parceiro,
                      },
                      {
                        "is-valid":
                          formik.touched.tipo_parceiro &&
                          !formik.errors.tipo_parceiro,
                      }
                    )}
                    aria-label="Selecione o tipo de parceiro"
                    {...formik.getFieldProps("tipo_parceiro")}
                  >
                    <option value="" disabled selected>
                      Selecione...
                    </option>
                    {optionsTipoParceiro.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 mt-4">
                  <label className="form-label">Código</label>
                  <input
                    type="text"
                    placeholder="Opcional"
                    {...formik.getFieldProps("codigo")}
                    className="form-control bg-transparent"
                    onChange={handleChange}
                  />
                  {formik.touched.codigo && formik.errors.codigo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.codigo}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-5 form-inputs">
                <div className="col-md-3 mt-4">
                  <label className="form-label">
                    {isPF ? "Nome Completo *" : "Nome do Escritório *"}
                  </label>
                  <input
                    type="text"
                    placeholder={`Insira ${isPF ? " o nome completo" : "a nome do escritório"
                      }`}
                    {...formik.getFieldProps("nome_razaosocial")}
                    onChange={handleChange}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.nome_razaosocial &&
                          formik.errors.nome_razaosocial,
                      },
                      {
                        "is-valid":
                          formik.touched.nome_razaosocial &&
                          !formik.errors.nome_razaosocial,
                      }
                    )}
                  />
                  {formik.touched.nome_razaosocial &&
                    formik.errors.nome_razaosocial && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.nome_razaosocial}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className="col-md-3 mt-4 ">
                  <label className="form-label">{getLabel()} *</label>
                  <InputMask
                    mask={getMask()}
                    placeholder={`Insira o ${getLabel()}`}
                    {...formik.getFieldProps("cnpj_cpf")}
                    onChange={handleChange}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.cnpj_cpf && formik.errors.cnpj_cpf,
                      },
                      {
                        "is-valid":
                          formik.touched.cnpj_cpf && !formik.errors.cnpj_cpf,
                      }
                    )}
                  />

                  {formik.touched.cnpj_cpf && formik.errors.cnpj_cpf && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.cnpj_cpf}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-3 mt-4">
                  <label htmlFor="" className="form-label">
                    Status *
                  </label>
                  <select
                    defaultValue={""}
                    required
                    className={clsx(
                      "form-select bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.status && formik.errors.status,
                      },
                      {
                        "is-valid":
                          formik.touched.status && !formik.errors.status,
                      }
                    )}
                    {...formik.getFieldProps("status")}
                    onChange={handleStatusChange}
                    placeholder="Selecione uma opção"
                  >
                    <option value="" disabled selected>
                      Selecione...
                    </option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.status}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!isPF && (
                <div className="mb-5 form-inputs">
                  <div className="col-md-3 mt-4 " style={{ display: "none" }}>
                    <label className="form-label">Nome Fantasia</label>
                    <input
                      type="text"
                      placeholder="Insira razão social ou nome completo"
                      {...formik.getFieldProps("nome_fantasia")}
                      onChange={handleChange}
                      className={"form-control bg-transparent"}
                    />
                  </div>

                  <div className="col-md-3 mt-4">
                    <label className="form-label">
                      Inscrição Estadual / ID
                    </label>
                    <input
                      type="text"
                      placeholder="Inscriação Estadual"
                      {...formik.getFieldProps("inscricao_estudal_id")}
                      className="form-control bg-transparent"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3 mt-4">
                    <label className="form-label">Site</label>
                    <input
                      type="text"
                      placeholder="Opcional"
                      {...formik.getFieldProps("website")}
                      className="form-control bg-transparent"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3 mt-4"></div>
                </div>
              )}

              {/* Endereço */}
              <div>
                <div
                  className="mt-8 mb-3 form-label-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h5>Endereço</h5>
                  <div
                    style={{
                      flexGrow: 1,
                      borderBottom: "2px solid #000",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
                <div className="mb-3 mt-2 form-inputs">
                  <div className="col-md-3 mt-4">
                    <label className="form-label">CEP *</label>
                    <InputMask
                      mask="99.999-999"
                      placeholder="Informe o CEP"
                      {...formik.getFieldProps("cep")}
                      onChange={handleChange}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid": formik.touched.cep && formik.errors.cep,
                        },
                        {
                          "is-valid": formik.touched.cep && !formik.errors.cep,
                        }
                      )}
                    />
                    {formik.touched.cep && formik.errors.cep && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.cep}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mt-4">
                    <label className="form-label"> Município *</label>
                    <input
                      type="text"
                      placeholder="Cidade"
                      {...formik.getFieldProps("cidade")}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.cidade && formik.errors.cidade,
                        },
                        {
                          "is-valid":
                            formik.touched.cidade && !formik.errors.cidade,
                        }
                      )}
                    />
                  </div>
                  <div className="col-md-4 mt-4">
                    <label className="form-label"> UF *</label>
                    <input
                      type="text"
                      placeholder="Estado"
                      {...formik.getFieldProps("estado")}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.estado && formik.errors.estado,
                        },
                        {
                          "is-valid":
                            formik.touched.estado && !formik.errors.estado,
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="mb-3 form-inputs">
                  <div className="col-md-12 mt-2">
                    <label className="form-label"> Endereço *</label>
                    <input
                      type="text"
                      placeholder="Endereço"
                      {...formik.getFieldProps("endereco")}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.endereco && formik.errors.endereco,
                        },
                        {
                          "is-valid":
                            formik.touched.endereco && !formik.errors.endereco,
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="mb-5 form-inputs">
                  <div className="col-md-3 mt-2">
                    <label className="form-label">Bairro *</label>
                    <input
                      type="text"
                      placeholder="Bairro"
                      {...formik.getFieldProps("bairro")}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.bairro && formik.errors.bairro,
                        },
                        {
                          "is-valid":
                            formik.touched.bairro && !formik.errors.bairro,
                        }
                      )}
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="form-label">Número</label>
                    <input
                      type="text"
                      placeholder="Número"
                      {...formik.getFieldProps("numero")}
                      onChange={handleChange}
                      className="form-control bg-transparent"
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <label className="form-label">Complemento</label>
                    <input
                      type="text"
                      {...formik.getFieldProps("complemento")}
                      onChange={handleChange}
                      className="form-control bg-transparent"
                    />
                  </div>
                </div>
              </div>

              {/* Contato */}
              <div>
                <div
                  className="mt-8 mb-3 form-label-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h5>Contato</h5>
                  <div
                    style={{
                      flexGrow: 1,
                      borderBottom: "2px solid #000",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>

                <div className="mb-3 mt-2 form-inputs">
                  <div className="col-md-3 mt-4">
                    <label className="form-label">Telefone * </label>
                    <InputMask
                      type="text"
                      mask="(99)9999-9999"
                      placeholder="Insira um contato"
                      {...formik.getFieldProps("telefone")}
                      onChange={handleChange}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.telefone && formik.errors.telefone,
                        },
                        {
                          "is-valid":
                            formik.touched.telefone && !formik.errors.telefone,
                        }
                      )}
                    />
                    {formik.touched.telefone && formik.errors.telefone && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.telefone}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mt-4">
                    <label className="form-label">Celular</label>
                    <InputMask
                      type="text"
                      mask="(99)9999-9999"
                      placeholder="Insira um celular"
                      {...formik.getFieldProps("celular")}
                      onChange={handleChange}
                      className={clsx("form-control bg-transparent")}
                    />
                  </div>

                  <div className="col-md-4 mb-4 mt-4">
                    <label className="form-label">Email *</label>
                    <InputMask
                      type="text"
                      placeholder="Insira um email"
                      {...formik.getFieldProps("email")}
                      onChange={handleChange}
                      className={clsx(
                        "form-control bg-transparent",
                        {
                          "is-invalid":
                            formik.touched.email && formik.errors.email,
                        },
                        {
                          "is-valid":
                            formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === "perfil" && (
          <>
            <div className="mb-5 mt-8">
              <div>
                <div>
                  <div className="d-flex flex-column">
                    <div className="list-unstyled d-flex gap-8 text-body mt-4">
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card">
                    <div className="card-body pt-4 pb-3">
                      <table className="table-auto w-full">
                        <tbody>
                          <tr>
                            <td className="w-1/4 pr-4">
                              <img
                                src={
                                  base64Image
                                    ? base64Image
                                    : icon_img_not_found
                                }
                                alt={base64Image}
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  border: "2px solid #50cd89",
                                  marginRight: "10px",
                                }}
                              />
                              <div
                                className="card-header"
                                style={{ textAlign: "center" }}
                              >
                                <h3 className="card-title">
                                  {formik.values.nome_razaosocial || "N/A"}
                                </h3>
                              </div>
                            </td>
                            <td>
                              <table
                                className="w-full"
                                style={{ marginLeft: "30px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Profissão
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      {formik.values.descricaoTipoProfissional || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Classificação
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      {formik.values.parceiroClassificacaoTitulo || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Redes Sociais
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      {formik.values.website || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Celular
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      {formik.values.telefone || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Estado
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      {formik.values.estado || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Cidade
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.cidade || "N/A"}
                                      </a>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Profissional
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.tipo_cadastro || "N/A"}
                                      </a>
                                    </td>
                                  </tr> */}
                                  {/* <tr>
                                    <td className="text-sm font-medium  pb-3.5 pe-3 text-success">
                                      Parceiro 37D
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.cnpj_cpf || "N/A"}
                                      </a>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    {/* <td className="text-sm font-medium pb-3.5 pe-3 text-success">
                                      Parceiro 57D
                                    </td> */}
                                    {/* <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.cnpj_cpf || "N/A"}
                                      </a>
                                    </td> */}
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Parceiro
                                    </td>
                                    <td className="text-sm font-medium text-gray-800 pb-3.5">
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.is_parceiro ? "Sim" : "Não"}
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Parceiro desde
                                    </td>
                                    <td>{formattedDate}</td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">
                                      Primeira venda
                                    </td>
                                    <td>
                                      {formik.values.website || "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">Consultor</td>
                                    <td>
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.vendedor || "N/A"}
                                      </a>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">Cadastro</td>
                                    <td> teste</td>
                                  </tr> */}
                                  <tr>
                                    <td className="text-sm font-medium text-gray-500 pb-3.5 pe-3">Email</td>
                                    <td>
                                      <a
                                        className="text-gray-800 hover:text-primary-active"
                                        href="#"
                                      >
                                        {formik.values.email || "N/A"}
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === "profissionais" && (
          <>
            <div className="mb-5 mt-8 ">
              <>
                <div
                  className="mt-8 mb-3 form-label-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h5>Pessoa de Contato</h5>
                  <div
                    style={{
                      flexGrow: 1,
                      borderBottom: "2px solid #000",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
                <Table>
                  <TabContainer>
                    <TablePessoasContato
                      className=""
                      infosPessoaContato={infosPessoaContato}
                      onRemovePessoa={handleRemovePessoa}
                      onEditPessoa={handleEditPessoa}
                      showInputRow={showInputRow}
                      onInputChange={handleInputChange}
                      onAddPessoa={handleAddPessoa}
                      onCancel={handleCancel}
                      newPessoa={newPessoa}
                    />
                  </TabContainer>
                </Table>
                {!showInputRow && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success mt-3"
                    onClick={handleShowInputRow}
                  >
                    Adicionar Pessoa de Contato
                  </button>
                )}
              </>
            </div>
          </>
        )}
        {activeSection === "dadosComplementares" && (
          <>
            <div className="mb-5 mt-8 ">
              <div className="mb-5 form-inputs row">
                <div className="col-md-3 mt-4 ">
                  <label className="form-label">
                    {isPF ? "Data de nascimento" : "Data de abertura do CNPJ"}
                  </label>
                  <input
                    type="date"
                    className="form-control bg-transparent"
                    placeholder="Informe uma data"
                    {...formik.getFieldProps("data_nasc")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-4">
                  <label htmlFor="" className="form-label">
                    Vendedor
                  </label>
                  <Select
                    className="react-select-styled react-select-solid"
                    classNamePrefix="react-select"
                    options={optionsFuncionariosClientes}
                    value={selectedVendedor}
                    placeholder="Selecione uma opção"
                    styles={customStyles}
                    isMulti={true}
                    onChange={handleVendedoresChange}
                  />
                  {optionsVendedores.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </div>
                <div className="col-md-3 mt-4 ">
                  <label htmlFor="" className="form-label">
                    Comissão %
                  </label>
                  <input
                    className="form-control bg-transparent"
                    type="number"
                    {...formik.getFieldProps("comissao")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-4">
                  <label className="form-label">Classificação PA</label>
                  <select
                    defaultValue={""}
                    className="form-select bg-transparent"
                    aria-label="Selecione uma opção"
                    {...formik.getFieldProps('idParceiroClassificacao')}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // const parsedValue = selectedValue ? parseInt(selectedValue, 10) : null;
                      formik.setFieldValue('idParceiroClassificacao', selectedValue)
                    }}
                  >
                    <option value="">Selecione..</option>
                    {optionsClassificacao.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
              <div>
                <div>
                  <div className="col-md-8 mt-4 mb-6">
                    <h4
                      style={{
                        color: "#3f3f3f",
                        marginTop: "10px",
                        marginBottom: 16,
                      }}
                    >
                      Imagem
                    </h4>
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#dbdfe9",
                        borderRadius: "6px",
                        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.5)",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {base64Image ? (
                        <img
                          src={base64Image ? base64Image : ""}
                          alt={formik.values.img_parceiro_base64}
                          style={{
                            width: "196px",
                            height: "196px",
                            borderRadius: "6px",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="selecao-arquivo"
                          style={{
                            color: "#99a1b7",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 14,
                              cursor: "pointer",
                              width: "200px",
                              height: "200px",
                            }}
                          >
                            <i
                              className="bi bi-lightbulb"
                              style={{ fontSize: "60px" }}
                            ></i>
                            Selecionar Imagem
                          </div>
                        </label>
                      )}
                      <input
                        id="selecao-arquivo"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        className="form-personalizado choose"
                        maxLength={2000000}
                        onChange={handleFileChange}
                      />
                    </div>
                    <small id="" className="form-text text-muted">
                      O tamanho do arquivo não deve ultrapassar 2 MB
                    </small>
                  </div>
                  {base64Image && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setBase64Image("")}
                    >
                      Remover Imagem
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === "Financeiro" && (
          <>
            <form className="mb-10 mt-8" onSubmit={handleSubmit}>
              <>
                <div className="mt-5 mb-10">
                  <div className="row">
                    <h1 className="mt-2">Dados Bancários</h1>
                    <div
                      style={{
                        borderBottom: "2px solid #878787",
                        marginBottom: "10px",
                      }}
                    ></div>

                    <div className="col-md-3 mt-8">
                      <h6 className="text-body">Banco</h6>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        placeholder="Selecione uma opção"
                        {...formik.getFieldProps("banco")}
                      >
                        <option value='' disabled selected>Selecione</option>
                        {optionsBanco.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3 mt-8">
                      <h6 className="text-body">Agência</h6>
                      <InputMask
                        mask="9999-9"
                        className="form-control bg-transparent"
                        {...formik.getFieldProps("agencia")}
                      />
                    </div>

                    <div className="col-md-3 mt-8">
                      <h6 className="text-body">Conta</h6>
                      <InputMask
                        mask="99999-9"
                        className="form-control bg-transparent"
                        {...formik.getFieldProps("conta")}
                      />
                    </div>

                    <div className="col-md-3 mt-8">
                      <h6 className="text-body">Observação</h6>
                      <input
                        type="text"
                        className="form-control bg-transparent"
                        {...formik.getFieldProps("observacoes")}
                      />
                    </div>
                  </div>

                  <h1 className="mt-8">Pix</h1>

                  <div className="row">
                    <div className="col-md-3 mt-8">
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column mr-3">
                          <h6 className="text-body mt-4">Tipo de Chave</h6>
                          <select
                            className="react-select-styled react-select-solid form-control bg-transparent"
                            {...formik.getFieldProps("tipoDeChave")}
                            style={{
                              width: "380px",
                              marginRight: "20px",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("tipoDeChave", parseInt(e.target.value, 10));
                            }}
                          >
                            <option value="0">Selecione...</option>
                            <option value="1">CPF</option>
                            <option value="2">CNPJ</option>
                            <option value="3">Número de Celular</option>
                            <option value="4">Email</option>
                          </select>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                          <div className="d-flex flex-column mr-3">
                            <h6 className="text-body mt-3">Chave Pix</h6>
                            {formik.values.tipoDeChave === 4 ? (
                              <input
                                type="email"
                                placeholder={getPlaceholder()}
                                className="form-control bg-transparent"
                                style={{ width: "400px", padding: "10px" }}
                                {...formik.getFieldProps("chavePix")}
                              />
                            ) : (
                              <InputMask
                                mask={getMaskBanco()}
                                placeholder={getPlaceholder()}
                                className="form-control bg-transparent"
                                style={{ width: "400px", padding: "10px" }}
                                {...formik.getFieldProps("chavePix")}
                              />
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </form>
          </>
        )}


        {activeSection === "historico" && (
          <>
            <div className="mb-10 mt-8 ">
              {historicoCompras.length > 0 ? (
                renderizarHistoricoCompras(historicoCompras)
              ) : (
                <>
                  <div className="mt-5 mb-10">
                    <h4 style={{ marginLeft: "10px" }}>
                      Ainda não há histórico de compras disponível para este
                      cliente.
                    </h4>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="d-flex gap-8">
          <button
            type="submit"
            className=" btn-cadastro btn-bg-success"
            id="post_user_submit"
          >
            {id ? "Atualizar" : "Cadastrar"}

            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Aguarde...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          <button
            type="button"
            className="btn-cadastro btn-danger"
            id="back"
            onClick={() => navigate("/listagem-parceiros")}
          >
            Voltar
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Aguarde...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CadastroParceiros;
