// CategorizacaoProdutosContext.tsx
import React, { createContext, ReactNode, useState } from 'react';

// Definindo o tipo do contexto
interface CategorizacaoProdutosContextType {
  isActiveSection: string;
  changeTab: (tab: string) => void;
}

// Valor inicial do contexto
export const CategorizacaoProdutosContext = createContext<CategorizacaoProdutosContextType | undefined>(undefined);

// Provedor do contexto
interface TabProviderProps {
  children: ReactNode;
}

export const TabProvider: React.FC<TabProviderProps> = ({ children }) => {
  const [isActiveSection, setIsActiveSection] = useState<string>('segmento');

  const changeTab = (tab: string) => {
    setIsActiveSection(tab);
  };

  return (
    <CategorizacaoProdutosContext.Provider value={{ isActiveSection, changeTab }}>
      {children}
    </CategorizacaoProdutosContext.Provider>
  );
};

export  default CategorizacaoProdutosContext;
