import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { sendUpdateUser } from '../cadastro_requests/cadastro_requests';
import './EditarUsuario.css';
import useOptionsEmpresas from '../../selectOptions/useOptionsEmpresas';
import useOptionsIps from '../../selectOptions/useOptionsIps';
import useOptionsPermissoes from '../../selectOptions/useOptionsPermissoes';
import useOptionsFuncionariosClientes from '../../selectOptions/useOptionsFuncionariosClientes';
import PageTitulo from '../../components/Pagetitulo';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface ipOption {
  value: number;
  label: string;
}

interface Vendedores {
  idFuncionario: number;
  funcionario: string;
}

interface VendedoresOptions {
  value: number;
  label: string;
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
  }),
};

const cadastroValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Seu nome deve conter pelo menos 2 caracteres')
    .required('Por favor, informe seu nome'),
  sobrenome: Yup.string()
    .min(2, 'Seu sobrenome deve conter pelo menos 2 caracteres')
    .required('Por favor, infome seu sobreome'),
  email: Yup.string().email('Endereço de email inválido').required('Por favor, informe um email'),
  login: Yup.string()
    .min(4, 'O campo login não pode ser vazio')
    .required('Por favor, informe um login'),
  password: Yup.string()
    .min(8, 'Sua senha deve conter mais de 8 caracteres')
    .max(50, 'Maximum 50 symbols'),
});

export function EditarUsuario() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIps, setSelectedIps] = useState([]);
  const [selectedPermissoes, setSelectedPermissoes] = useState<string[]>([]);

  const { optionsPermissoes } = useOptionsPermissoes();
  const [empresasArrayModified, setEmpresasArrayModified] = useState<
    { id: number; fantasia: string }[]
  >([]);
  const [ipArrayModified, setIpArrayModified] = useState<
    { id: number; ipAddress: string; descricao: string }[]
  >([]);
  const { options } = useOptionsEmpresas();
  const { optionsIp } = useOptionsIps();
  const [empresaPadraoId, setEmpresaPadraoId] = useState(0);

  const { optionsFuncionariosClientes } = useOptionsFuncionariosClientes();
  const [selectedVendedor, setSelectedVendedor] = useState<VendedoresOptions[]>([]);

  const formik = useFormik({
    initialValues: {
      id: 0,
      nome: '',
      sobrenome: '',
      email: '',
      login: '',
      tipousuario: 1,
      idPerfil: 0,
      password: '',
      idempresas: [],
      ips: [],
      status: 1,
      isFinanceiro: 0,
      ipAddressForce: -1,
      empresaPadraoId: empresaPadraoId,
    },
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (
          values.tipousuario === 1 &&
          values.idempresas.length === 0 &&
          empresasArrayModified.length === 0
        ) {
          Swal.fire({
            icon: 'error',
            title:
              "Caso o tipo de usuário selecionado seja 'Usuário', é necessário vincular pelo menos uma empresa",
            text: 'Por favor, vincule uma empresa e tente novamente',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        if (Number(values.tipousuario) === 1 && Number(values.idPerfil) === 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Por favor, selecione a permissão para o usuário',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        if (Number(values.tipousuario) === 1 && Number(values.idPerfil) === 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Por favor, selecione a permissão para o usuário',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        const updatedIdEmpresas = [
          ...values.idempresas,
          ...empresasArrayModified.map((empresa) => empresa.id),
        ];
        const uniqueIdEmpresas = Array.from(new Set(updatedIdEmpresas));
        const updatedIps = [...values.ips, ...ipArrayModified.map((ip) => ip.id)];
        const uniqueIps = Array.from(new Set(updatedIps));
        const vendedorpadraoid = selectedVendedor.map((tipo) => tipo.value);

        const response = await sendUpdateUser(
          values.id,
          values.nome,
          values.sobrenome,
          values.email,
          values.tipousuario,
          values.idPerfil,
          values.status,
          values.isFinanceiro,
          uniqueIdEmpresas,
          uniqueIps,
          values.password,
          values.ipAddressForce,
          values.empresaPadraoId,
          vendedorpadraoid.toString()
        );

        if (response.status === 200) {
          setSubmitting(false);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Usuário atualizado com sucesso!',
            confirmButtonText: 'Voltar a listagem',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/listagem-usuarios');
            },
          });
        } else {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao atualizar o usuário, verifique as informações preenchidas e tente novamente',
            text: 'Something went wrong!',
            confirmButtonText: 'Ok',
          });
          setStatus('Ocorreu um erro ao salvar o usuário. Por favor, tente novamente.');
        }
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar o usuário',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });

          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações de usuário corretamente');
      }
    },
  });

  function mapVendedoresToOptions(vendedores: Vendedores[]): VendedoresOptions[] {
    return vendedores.map((vendedor) => ({
      value: vendedor.idFuncionario,
      label: vendedor.funcionario,
    }));
  }

  useEffect(() => {
    async function fetchUsuario() {
      try {
        // Fetch user data and update form fields
        const usuarioResponse = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/${id}`
        );
        if (usuarioResponse.status === 200) {
          const usuario = usuarioResponse.data;

          formik.setValues({
            ...formik.values,
            id: usuario.id,
            nome: usuario.nome,
            sobrenome: usuario.sobrenome,
            email: usuario.email,
            login: usuario.login,
          });

          switch (usuario.tipousuario) {
            case 'ADMIN':
              formik.setFieldValue('tipousuario', 0);
              break;
            case 'USER':
              formik.setFieldValue('tipousuario', 1);
              break;
          }

          formik.setFieldValue('status', usuario.status);
          formik.setFieldValue('isFinanceiro', usuario.isfinanceiro);
          formik.setFieldValue('idPerfil', usuario.idPerfil);
          formik.setFieldValue('ipAddressForce', usuario.ipAddressForce);

          const empresasArrayModified = usuario.empresasVinculadas;
          setEmpresasArrayModified(empresasArrayModified);

          const ipArrayModified = usuario.ipsVinculados;
          setIpArrayModified(ipArrayModified);

          const empresaPadrao = usuario.empresasVinculadas.find(
            (empresa) => empresa.isPadrao === true
          );
          if (empresaPadrao) {
            setEmpresaPadraoId(empresaPadrao.id);
            formik.setFieldValue('empresaPadraoId', empresaPadrao.id);
          }

          setSelectedVendedor(mapVendedoresToOptions(usuario.funcionariosUsuarios));
        } else {
          throw new Error('Erro ao buscar usuário');
        }
      } catch (error) {
        console.error('Error fetching options or user data:', error);
      }
    }
    fetchUsuario();
  }, []);

  const handleEmpresasChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const newIdEmpresas = selectedOptions.map((option) => option.value);
    formik.setFieldValue('idempresas', newIdEmpresas);

  };

  const handleIpsChange = (selectedOptions) => {
    setSelectedIps(selectedOptions);
    const newIdIps = selectedOptions.map((optionsIp) => optionsIp.value);
    formik.setFieldValue('ips', newIdIps);
  };

  const handleVendedores = (selectedVendedor) => {
    setSelectedVendedor(selectedVendedor);
  };

  const handlePermissaoUsuarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('idPerfil', event.target.value);
    setSelectedPermissoes([event.target.value]);
  };

  const handleTipoUsuarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoUsuario = Number(event.target.value);
    formik.setFieldValue('tipousuario', tipoUsuario);

    if (tipoUsuario === 0) {
      formik.setFieldValue('idPerfil', '1');
    } else {
      formik.setFieldValue('idPerfil', '0');
    }
  };

  const handleStatusUsuarioChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleIsFinanceiroChange = (event) => {
    formik.setFieldValue('isFinanceiro', event.target.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  const handleRestricaoIp = (event) => {
    formik.setFieldValue('ipAddressForce', event.target.value);
  };

  const handleRemoveEmpresa = (empresaId) => {
    const updatedEmpresasArray = empresasArrayModified.filter(
      (empresa) => empresa.id !== empresaId
    );
    setEmpresasArrayModified(updatedEmpresasArray);

  };

  const handleRemoveIp = (ipId) => {
    const updatedIpArray = ipArrayModified.filter((ip) => ip.id !== ipId);
    setIpArrayModified(updatedIpArray);
  };

  const handleEmpresaPadraoChange = (empresaId) => {
    setEmpresaPadraoId(empresaId);
    formik.setFieldValue('empresaPadraoId', empresaId);
  };

  return (
    <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-light-dark'>
        <PageTitulo id={id} tipoFormulario='Usuário' />
        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Informações Gerais</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>

        <div id='idUsario' style={{ display: 'none' }}>
          Id
          <input type='number' id='idUser' {...formik.getFieldProps('id')} />
        </div>

        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Nome</label>
            <input
              type='text'
              placeholder='Insira seu nome'
              {...formik.getFieldProps('nome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                { 'is-valid': formik.touched.nome && !formik.errors.nome }
              )}
              onChange={(event) => {
                const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('nome', value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Sobrenome</label>
            <input
              type='text'
              placeholder='Insira seu sobrenome'
              {...formik.getFieldProps('sobrenome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.sobrenome && formik.errors.sobrenome },
                { 'is-valid': formik.touched.sobrenome && !formik.errors.sobrenome }
              )}
              onChange={(event) => {
                const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('sobrenome', value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.sobrenome && formik.errors.sobrenome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sobrenome}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Email</label>
            <input
              type='email'
              placeholder='Digite seu e-mail'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Tipo de Usuario</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleTipoUsuarioChange}
              value={formik.values.tipousuario}
            >
              <option value='1'>Usuário</option>
              <option value='0'>Administrador</option>
            </select>
          </div>
          <div className="col-sm-3">
            <label className="form-label">Permissão</label>
            <select
              className="form-select"
              aria-label="Select example"
              onChange={handlePermissaoUsuarioChange}
              disabled={Number(formik.values.tipousuario) === 0}
              value={formik.values.idPerfil}
            >
              <option value="0">Selecione</option>
              {optionsPermissoes.map(permissao => (
                <option key={permissao.value} value={permissao.value}>
                  {permissao.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3 mb-5'>
            <label className='form-label'>Login</label>
            <input
              type='text'
              className='form-control'
              placeholder='Digite seu login'
              {...formik.getFieldProps('login')}
              readOnly
            />
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Senha</label>
            <input
              type='password'
              autoComplete='off'
              placeholder='Digite sua senha'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-2'>
            <label className='form-label'>Status</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleStatusUsuarioChange}
              value={formik.values.status}
            >
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>

          <div className='col-sm-2'>
            <label className='form-label'>Restrição de IP</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleRestricaoIp}
              value={formik.values.ipAddressForce}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
          <div className='col-sm-2'>
            <label className='form-label'>Financeiro</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleIsFinanceiroChange}
              value={formik.values.isFinanceiro}
            >
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>

          <div className='col-sm-6'>
            <label className='form-label'>Vincular Empresas</label>
            <Select
              name='idempresas'
              //defaultValue={userOptions}
              options={options}
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Selecione empresas vinculadas a esse usuario'
              isMulti
              styles={customStyles}
              onChange={handleEmpresasChange}
            />
          </div>

          <div className='col-sm-6'>
            <label className='form-label'>Vincular Ips</label>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              options={optionsIp}
              placeholder="Selecione os ip's permitidos a esse usuario"
              isMulti
              styles={customStyles}
              onChange={handleIpsChange}
            />
          </div>

          <div className='col-sm-6 mt-5'>
            <label className='form-label divEmpresasVinculadas'>Empresas Vinculadas</label>
            <table className='empresas-vinculadas-table table table-striped'>
              <thead>
                <th className='text-center'>Id</th>
                <th className='text-center'>Nome/Fantasia</th>
                <th className='text-center'>Empresa Padrão</th>
                <th className='text-center'>Remover</th>
              </thead>
              <tbody>
                {empresasArrayModified.map((empresa) => (
                  <tr key={empresa.id}>
                    <td className='text-center'>{empresa.id}</td>
                    <td className='text-center'>{empresa.fantasia}</td>
                    <td className='text-center'>
                      <input
                        type='checkbox'
                        checked={empresaPadraoId === empresa.id}
                        style={{
                          marginTop: '10px',
                          transform: 'scale(1.5)', // Aumenta o tamanho do checkbox
                        }}
                        onChange={() => handleEmpresaPadraoChange(empresa.id)}
                      />
                    </td>
                    <td className='text-center'>
                      <button
                        type='button'
                        className='btn btn-bg-danger btn-sm'
                        id='removeEmpVinculada'
                        onClick={() => handleRemoveEmpresa(empresa.id)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


          <div className='col-sm-6 mt-5'>
            <label className='form-label divEmpresasVinculadas'>Ips Vinculados </label>
            <table className='empresas-vinculadas-table table table-striped'>
              <thead>
                <th className='text-center'>Id</th>
                <th className='text-center'>Descricao</th>
                <th className='text-center'>Remover</th>
              </thead>
              <tbody>
                {ipArrayModified.map((ips) => (
                  <tr key={ips.id}>
                    <td className='text-center'>{ips.id}</td>
                    <td className='text-center'>
                      {ips.descricao} - {ips.ipAddress}
                    </td>
                    <td className='text-center'>
                      <button
                        type='button'
                        className='btn btn-bg-danger btn-sm'
                        id='removeEmpVinculada'
                        onClick={() => handleRemoveIp(ips.id)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Selecione o Vendedor padrão para o Usuário
            </label>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              options={optionsFuncionariosClientes}
              value={selectedVendedor}
              placeholder='Selecione uma opção'
              styles={customStyles}
              isMulti
              onChange={handleVendedores}
            />
          </div>
          <div>
            <div className='d-flex gap-8 mt-5'>
              <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
                Atualizar
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              <button
                type='button'
                className='btn-cadastro btn-danger'
                id='back'
                onClick={() => navigate('/listagem-usuarios')}
              >
                Voltar
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
