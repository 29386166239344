import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Modal } from 'react-bootstrap';
import { sendWorkFlowAprov, sendWorkFlowRejei } from '../../cadastros/cadastro_requests/cadastro_requests';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { each } from 'chart.js/dist/helpers/helpers.core';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;

type ModalWorkflowProps = {
  showModal: boolean;
  onHide: () => void;
  modalTitle: string;
  mode: 'Aprovar' | 'Recusar';
  status: number;
  onConfirm: () => void;
};

interface WorkflowPendenciaHistoricoTypes {
  id: number | null;
  idUsuarioAprovador: number;
  idWorkflowPendencia: number | null;
  motivo: string;
}

const ModalWorkflow = ({ showModal, onHide, modalTitle, mode, id, status, userId, entidade, workflowComponente, onConfirm }) => {
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [statusWorkFlow, setStatusWorkFlow] = useState("");
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [idWorkflowPendencia, setIdWorkflowPendencia] = useState<number | null>(id || 0);
  const [idUsuarioAprovador, setIdUsuarioAprovador] = useState<number>(userId || 0);
  const [idEntidade, setidEntidade] = useState<number | null>(null);
  const [idWorkflowComponente, setidWorkflowComponente] = useState<number | null>(null);

  useEffect(() => {
    setIdWorkflowPendencia(id ?? 0);
    setIdUsuarioAprovador(userId ?? 0);
    setidEntidade(entidade ?? null);
    setidWorkflowComponente(workflowComponente ?? null);
  }, [id, userId, entidade, workflowComponente]);

  interface HistoricoItem {
    login: string;
    id: number;
    idWorkflowPendencia: number;
    motivo: string;
    idUsuarioAprovador: number;
    statusAprovacaoEnum: string | null;
  }

  const [historico, setHistorico] = useState<HistoricoItem[]>([]);

  const initialValues: WorkflowPendenciaHistoricoTypes = {
    id: null,
    idWorkflowPendencia: idWorkflowPendencia || 0,
    motivo: '',
    idUsuarioAprovador: idUsuarioAprovador || 1,
  };

  const fetchHistorico = async (idWorkflowPendencia) => {
    if (idWorkflowPendencia !== null) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/historicoAprovacaoPendencias/listar?idEntidade=${idEntidade}&idWorkflowComponente=${idWorkflowComponente}`
        );

        const historicoData = response.data.content || [];
        setHistorico(historicoData);
        setTotalElements(response.data.totalElements || 0);

        const status = historicoData.map(item => item.statusAprovacaoEnum);
        if (status.includes("REJEITADO")) {
          setStatusWorkFlow("Rejeitou um novo workflow");
        } else if (status.includes("APROVADO")) {
          setStatusWorkFlow("Aprovou um novo workflow");
        } else {
          setStatusWorkFlow("Status desconhecido");
        }

      } catch (error) {
        console.error('Erro ao buscar histórico:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      motivo: Yup.string().required('O motivo é obrigatório'),
    }),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);

      const formData = {
        idWorkflowPendencia: idWorkflowPendencia!,
        motivo: values.motivo,
        idUsuarioAprovador: idUsuarioAprovador,
        idEntidade: entidade,
        idWorkflowComponente: workflowComponente,
        statusAprovacaoEnum: mode === 'Aprovar' ? 'APROVADO' : 'REJEITADO',
      };

      try {
        const apiCall =
          mode === "Aprovar" ? sendWorkFlowAprov : sendWorkFlowRejei;

        const response = await apiCall(id, formData);

        setSubmitting(false);
        setLoading(false);
        onHide();
        resetForm();

      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false);
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao aprovar solicitação',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });

          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações corretamente');
      }
    },
  });

  useEffect(() => {
    if (showModal) {
      fetchHistorico(idWorkflowPendencia);
    }
  }, [idWorkflowPendencia]);

  const toggleHistory = () => {
    setIsHistoryVisible(!isHistoryVisible);
  };

  let modalContent: React.ReactNode = null;

  switch (mode) {
    case 'Aprovar':
      modalContent = <h3>Deseja realmente aprovar esta solicitação?</h3>;
      break;
    case 'Recusar':
      modalContent = <h3>Deseja realmente recusar esta solicitação?</h3>;
      break;
    default:
      modalContent = null;
      break;
  }

  return (
    <Modal show={showModal} centered={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '24px' }}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex' }}>{modalContent}</Modal.Body>
      <Modal.Body>
        <div className="notification-item">
          <div className="d-flex gap-3 px-3 py-2 border-bottom" onClick={toggleHistory} style={{ cursor: 'pointer' }}>
            <div className="d-flex flex-column flex-grow-1">
              <div className="mb-1">
                <Modal.Title>
                  Histórico <i className="bi bi-arrow-bar-down"></i>
                </Modal.Title>
              </div>
            </div>
          </div>

          {isHistoryVisible && (
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
              {historico.length > 0 ? (
                historico.map((item) => (
                  <div key={item.id} className="px-3 py-2">
                    <div className="notification-item">
                      <div className="d-flex gap-3 px-3 py-2 border-bottom">
                        <div className="position-relative">
                          <img
                            alt=""
                            className="rounded-circle"
                            src={`${REACT_APP_IMG_URL}/avatars/-1.jpg`}
                            style={{ width: '40px', height: '40px' }}
                          />
                          <span className="badge bg-secondary position-absolute top-0 end-0 translate-middle" style={{ width: '20px', height: '20px', fontSize: '12px' }}>
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <div className="mb-1">
                            <a href="#" className="text-dark fw-bold">
                              {item.login}
                            </a>
                            <span className="text-muted">
                              {item.statusAprovacaoEnum === 'REJEITADO' ? ' Rejeitou um novo WorkFlow' : item.statusAprovacaoEnum === 'APROVADO' ? ' Aprovou um novo WorkFlow' : item.statusAprovacaoEnum}
                            </span>
                            <div className="mb-1">
                              <a href="#" className="text-dark fw-bold">
                                Motivo:
                              </a>
                              <span className="text-muted">
                                {' '} {item.motivo}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="px-3 py-2">Nenhum histórico disponível.</div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Body>
        <form
          className='form-control-solid'
          onSubmit={formik.handleSubmit}
          noValidate
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        >
          <h3>Motivo <i className="bi bi-pencil-square"></i></h3>
          <textarea
            id="motivo"
            className="form-control"
            placeholder="Digite..."
            rows={3}
            maxLength={255}
            style={{ width: '100%' }}
            {...formik.getFieldProps('motivo')}
            value={formik.values.motivo}
          />
          {formik.touched.motivo && formik.errors.motivo ? (
            <div className="text-danger">{formik.errors.motivo}</div>
          ) : null}

          <Modal.Footer>
            <Button
              style={{ cursor: 'pointer' }}
              type='submit'
              variant='primary'
              disabled={loading}
              onClick={() => {
                if (!formik.values.motivo) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Campo obrigatório',
                    text: 'Por favor, preencha o campo motivo.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              }}
            >
              {mode}
            </Button>
            <Button variant='secondary' onClick={onHide}>
              Cancelar
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalWorkflow;