import React, { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_sysled/layout/core';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import useOptionsEmpresas from '../../selectOptions/useOptionsEmpresas';
import { Table, TabContainer } from 'react-bootstrap';
import { TableProdutosSelecionados } from './components/TableProdutosSelecionados';
//import useOptionsProdutos from '../../selectOptions/useOptionsProdutos';
import { getOptionsProdutosFromStorage } from '../../selectOptions/useGlobalOptionsProdutos';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../../../modules/auth';
//dateRanger imports
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

import AsyncSelect from 'react-select/async';

//date-fns imports
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { RangeType } from 'rsuite/esm/DatePicker/types';
import { postCampanhaPromocional } from '../campanha-promocional-requests/campanha-promocional-requests';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import PageTitulo from '../../components/Pagetitulo';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface ProductOption {
  value: number;
  label: string;
}

interface EmpresaOption {
  value: number;
  label: string;
}

interface ProdutoInfo {
  idProduto: string;
  produto: string;
  precoUnitario: string;
  estoque: Estoque[];
  precoPromocional?: string;
}

interface Estoque {
  wms: string;
  CodigoProduto: number;
  EstoqueProduto: number;
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
  }),
};

const CadastroPromocao: FC = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedProducts, setSelectedProducts] = useState<ProductOption[]>([]);
  const [infosProd, setInfosProd] = useState<ProdutoInfo[]>([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState<EmpresaOption[]>([]);
  const [tituloCampanha, setTituloCampanha] = useState('');
  const [statusCampanha, setStatusCampanha] = useState('3');
  const [endDate, setEndDate] = useState(new Date());
  const { options } = useOptionsEmpresas();
  //const {optionsProdutos} = useOptionsProdutos();
  const optionsProdutos = getOptionsProdutosFromStorage();
  const [valueDateRange, setValueDateRange] = useState<DateRange>([startDate, endDate]);
  const navigate = useNavigate();
  const [promoPrices, setPromoPrices] = useState<{ [key: string]: string }>({});
  const { currentUser } = useAuth();
  const [showStock, setShowStock] = useState(false);
  const [listProdutos, setListProdutos] = useState([]);

  const handleDateRangeChange = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value) {
      setValueDateRange([value[0], value[1]]);
    }
  };

  const handleSubmit = async () => {
    const promoPricesArray = infosProd.map((infoProd) => {
      let estoquecd = infoProd.estoque[0] ? infoProd.estoque[0].EstoqueProduto : 0;
      let estoquefloriano = infoProd.estoque[1] ? infoProd.estoque[1].EstoqueProduto : 0;

      if (!showStock) {
        estoquecd = 0;
        estoquefloriano = 0;
      }

      return {
        idproduto: infoProd.idProduto,
        valorpromocionalproduto: promoPrices[infoProd.idProduto] || '',
        descricao: infoProd.produto,
        estoquecd: estoquecd,
        estoquefloriano: estoquefloriano,
      };
    });

    if (statusCampanha === '3') {
      Swal.fire({
        icon: 'info',
        title: 'Nenhum status selecionado',
        text: 'Por favor, selecione o status da campanha',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (tituloCampanha === null || tituloCampanha === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campanha sem título informado',
        text: 'Por favor, defina um título para a campanha',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (!valueDateRange[0] || !valueDateRange[1]) {
      Swal.fire({
        icon: 'info',
        title: 'Nenhum intervalo de data selecionado',
        text: 'Por favor, selecione um período para a campanha promocional',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (selectedEmpresas.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Nenhuma empresa selecionada',
        text: 'Por favor, selecione pelo menos uma empresa para a campanha',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (promoPricesArray.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Nenhum produto selecionado',
        text: 'Por favor, selecione pelo menos um produto para a campanha promocional',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const hasPromoPrice = promoPricesArray.some((item) => item.valorpromocionalproduto !== '');
    if (!hasPromoPrice) {
      Swal.fire({
        icon: 'info',
        title: 'Nenhum produto selecionado com preço promocional',
        text: 'Por favor, selecione pelo menos um produto com um preço promocional preenchido',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const allPromoPricesFilled = promoPricesArray.every(
      (item) => item.valorpromocionalproduto !== ''
    );
    if (!allPromoPricesFilled) {
      Swal.fire({
        icon: 'info',
        title: 'Produtos selecionados sem preço promocional preenchido',
        text: 'Por favor, preencha o preço promocional para os produtos selecionados',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const campanhaDados = {
      titulo: tituloCampanha,
      status: statusCampanha,
      datainicial: valueDateRange[0],
      datafinal: valueDateRange[1],
      usuariocriacao: currentUser?.login,
      produtoscampanha: promoPricesArray,
      idsEmpresas: selectedEmpresas.map((empresa) => empresa.value),
    };


    try {
      const response = await postCampanhaPromocional(campanhaDados);
      if (response.status === 201) {
        await Swal.fire({
          icon: 'success',
          title: 'Campanha cadastrada com sucesso!',
          confirmButtonText: 'Voltar a listagem',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/promocao-listagem');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar campaha, verifique as informações preenchidas e tente novamente',
          text: 'Something went wrong!',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error: any) {
    }
  };

  const handleStockCheckChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '0') {
      setShowStock(false);
    } else if (e.target.value === '1') {
      setShowStock(true);
    }
  };

  const handleProdutosChange = (selectedProducts: ProductOption[] | null) => {
    if (selectedProducts) {
      const productValues = selectedProducts.map((product) => product.value);
      fetchInfoProduto(productValues);
      setSelectedProducts(selectedProducts);
    }
  };

  const handleEmpresasChange = (selectedEmpresas) => {
    setSelectedEmpresas(selectedEmpresas);
  };

  const handleRemoveProduct = (idProduto: string) => {
    setInfosProd((prevInfosProd) =>
      prevInfosProd.filter((infoProd) => infoProd.idProduto !== idProduto)
    );
  };

  const handlePromoPriceChange = (idProduto: string, value: string) => {
    setPromoPrices((prev) => ({ ...prev, [idProduto]: value }));
  };

  const handleTituloChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 120) {
      setTituloCampanha(e.target.value.substring(0, 120));
    } else {
      setTituloCampanha(e.target.value);
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusCampanha(e.target.value);
  };

  const fetchInfoProduto = async (productValues: number[]) => {
    try {
      const produtosInfo: ProdutoInfo[] = [];

      for (const productValue of productValues) {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoIdInfoPrevenda/${productValue}`
        );

        if (response.data && response.data.length > 0) {
          const produtoInfo = response.data[0];
          produtosInfo.push(produtoInfo);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: `Nenhum produto encontrado para o valor: ${productValue}`,
          });
          console.warn(`Nenhum produto encontrado para o valor: ${productValue}`);
        }
      }

      setInfosProd((prevInfosProd) => {
        const novosInfosProd = [...prevInfosProd];
        produtosInfo.forEach((produtoInfo) => {
          if (!novosInfosProd.some((infoProd) => infoProd.idProduto === produtoInfo.idProduto)) {
            novosInfosProd.unshift(produtoInfo);
          }
        });

        return novosInfosProd;
      });
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
    }
  };

  const loadOptionsProdutos = async (inputValue: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idProduto,
        label: item.idProduto + ' - ' + item.produto,
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de produtos:', error);
      return [];
    }
  };

  return (
    <>
      <div className='form-label-container'>
        <PageTitulo id={null} tipoFormulario='Promoção / Encarte' />
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>

      <div className='mt-10 mb-10'>
        <div className='row mb-6 gap-4'>
          <div className='col-sm-5'>
            <label className='form-label'>Informe um título</label>
            <input
              className='form-control'
              type='text'
              placeholder='Insira o título da campanha promocional'
              onChange={handleTituloChange}
              name=''
              id=''
            />
          </div>
          <div className='col-sm-4'>
            <label htmlFor='' className='form-label'>
              Selecione o intervalo de datas
            </label>

            <DateRangePicker
              size='lg'
              appearance='default'
              // value={valueDateRange}
              style={{ width: '350px' }}
              onChange={handleDateRangeChange}
              format='dd-MM-yyyy'
              ranges={[
                {
                  label: 'Ontem',
                  value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                },
                {
                  label: 'Hoje',
                  value: [new Date(), new Date()],
                },
                {
                  label: 'Último 7 dias',
                  value: [subDays(new Date(), 6), new Date()],
                },
                {
                  label: 'Este mês',
                  value: [startOfMonth(new Date()), endOfMonth(new Date())],
                },
              ]}
              placeholder='Selecione o período'
            />
          </div>
          <div className='col-sm-2'>
            <label htmlFor='' className='form-label'>
              Selecione o status
            </label>
            <select className='form-select' name='' id='' onChange={handleStatusChange}>
              <option value='3'>Selecione</option>
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
        </div>
        <div className='row gap-4'>
          <div className='col-sm-5'>
            <label htmlFor='' className='form-label'>
              Selecione os produtos
            </label>
            <AsyncSelect
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              loadOptions={loadOptionsProdutos}
              placeholder='Selecione os produtos deste encarte'
              styles={customStyles}
              isMulti
              onChange={(selectedProducts, _actionMeta) =>
                handleProdutosChange(Array.from(selectedProducts) as ProductOption[])
              }
            />
          </div>
          <div className='col-sm-4'>
            <label htmlFor='' className='form-label '>
              Selecione as empresas
            </label>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              options={options}
              placeholder='Selecione as empresas deste encarte'
              isMulti
              styles={customStyles}
              onChange={handleEmpresasChange}
            />
          </div>

          <div className='col-sm-2'>
            <label className='form-label' htmlFor=''>
              Consultar Estoque
            </label>
            <select className='form-select' name='' id='' onChange={handleStockCheckChange}>
              <option value='0'>Não</option>
              <option value='1'>Sim</option>
            </select>
          </div>
        </div>
      </div>
      <div className='mb-10'>
        <div className='mb-3 form-label-container mb-10'>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #d3d3d3' }}></div>
        </div>

        <TabContainer>
          <Table className='my-0'>
            <TableProdutosSelecionados
              className='card-flush h-md-100'
              infosProd={infosProd}
              onRemoveProduct={handleRemoveProduct}
              onPromoPriceChange={handlePromoPriceChange}
              promoPrices={promoPrices}
              showStock={showStock}
            />
          </Table>
        </TabContainer>
      </div>
      <div className=''>
        <button
          type='button'
          className='btn btn-danger'
          onClick={() => navigate('/promocao-listagem')}
        >
          Voltar
        </button>
        <button type='submit' className='btn btn-success mx-5' onClick={handleSubmit}>
          Criar Campanha
        </button>
      </div>
    </>
  );
};

export { CadastroPromocao };
