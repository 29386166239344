import React, {useEffect, useState} from "react";
import {useAuth} from "../../modules/auth";
import axios from "axios";

interface OptionType {
  value: number;
  label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsAlmoxarifado = () => {
    const [optionsAlmoxarifado, setOptionsAlmoxarifado] = useState<OptionType[]>([]);
    const {currentUser} = useAuth();
  
    useEffect(() => {
      async function fetchOptions() {
        try {
          const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/almoxarifado/getAlmoxarifadoOptions`);
          const data = await response.data;

          const filteredData = data.filter(item => currentUser?.empresasVinculadas?.some(empresa => +empresa.codigo === +item.idEmpresa));

          const formattedOptions = filteredData.map(item => {
            
            const empresasFiltradas = currentUser?.empresasVinculadas?.find(empresa => +empresa.codigo === +item.idEmpresa);
            const empresaVinculadaid = empresasFiltradas ? empresasFiltradas.codigo : '';
            const fantasia = empresasFiltradas ? empresasFiltradas.fantasia : '';

            const label = `[${empresaVinculadaid}] ${fantasia} - ${item.almoxarifado}`;

            return {
              value: item.idAlmoxarifado,
              label: label
            }
            
          });

          setOptionsAlmoxarifado(formattedOptions);
        } catch (error) {
          console.error("Erro ao buscar opções:", error);
        }
      }
      fetchOptions();
    }, []);
  
    return { optionsAlmoxarifado };
  };
  
  export default useOptionsAlmoxarifado;

