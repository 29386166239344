import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {formatStatus} from '../components/FormStatus';
import {fetchData} from '../services/FetchWorkflow';

interface Formulario {
  id: number;
  titulo: string;
  descricao: string;
  status: number;
}

const ListagemWorkflowAprovacao = () => {
  const [loading, setLoading] = useState(false);
  const [formularios, setFormularios] = useState<Formulario[]>([]);

  const fetchAprovacao = async () => {
    try {
      setLoading(true);
      const data = await fetchData<{content: any[]}>('workflowaprovacao');

      if (data) {
        setFormularios(data.content);
      } else {
        setFormularios([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAprovacao();
  }, []);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Workflow Aprovação</h1>
        <Link
          to='/workflow-aprovacao'
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Nova Solicitação
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>

      <div className='table-responsive mt-10'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Titulo</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>Editar</th>
              </tr>
            </thead>
            <tbody>
              {formularios.map((formulario) => (
                <tr key={formulario.id}>
                  <td className='text-center' style={{alignContent: 'center'}}>
                    {formulario.id}
                  </td>
                  <td className='text-center' style={{alignContent: 'center'}}>
                    {formulario.titulo}
                  </td>
                  <td className='text-center' style={{alignContent: 'center'}}>
                    {formatStatus(formulario.status)}
                  </td>
                  <td className='text-center'>
                    <Link
                      to={`/workflow-aprovacao/${formulario.id}`}
                      className='btn btn-success btn-sm'
                    >
                      Editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ListagemWorkflowAprovacao;
