import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { PageTitle } from '../../../../_sysled/layout/core';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { sendEmpresa } from '../cadastro_requests/cadastro_requests';
import useBuscaCnpj from '../../useUtils/useBuscaCnpj';
import useBuscaCep from '../../useUtils/useBuscaCep';
import PageTitulo from '../../components/Pagetitulo';

const cadastroValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(4, 'O nome da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o nome da empresa'),
  razaosocial: Yup.string()
    .min(4, 'A razão social da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe a razão social da empresa'),
  fantasia: Yup.string()
    .min(4, 'O nome fantasia da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o nome fantasia da empresa'),
  cnpj: Yup.string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido')
    .required('Por favor, informe o CNPJ da empresa'),
  codigo: Yup.number()
    .min(1, 'O código da empresa deve conter pelo menos 1 caracteres')
    .required('Por favor, informe o código da empresa'),
  endereco: Yup.string()
    .min(4, 'O endereço da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o endereço da empresa'),
  cidade: Yup.string()
    .min(2, 'A cidade da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe a cidade da empresa'),
  estado: Yup.string()
    .min(2, 'O estado da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe o estado da empresa'),
  pais: Yup.string()
    .min(2, 'O país da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe o país da empresa'),
  cep: Yup.string()
    .matches(/^\d{2}\.\d{3}-\d{3}$/, 'CEP inválido')
    .required('Por favor, informe o CEP da empresa'),
  telefone: Yup.string().required('Por favor, informe o telefone da empresa'),
  email: Yup.string()
    .email('Endereço de email inválido')
    .min(1, 'O campo email não pode ser vazio')
    .required('Por favor, informe um email'),
});

const initialValues = {
  nome: '',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  codigo: '',
  endereco: '',
  cidade: '',
  estado: '',
  pais: '',
  cep: '',
  telefone: '',
  email: '',
  status: 1,
  website: '',
};

export function CadastroEmpresas() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await sendEmpresa(
          values.nome,
          values.razaosocial,
          values.fantasia,
          values.cnpj,
          values.codigo,
          values.endereco,
          values.cidade,
          values.estado,
          values.pais,
          values.cep,
          values.telefone,
          values.email,
          values.status,
          values.website
        );

        if (response.status === 201) {
          setSubmitting(false);
          setLoading(false);
          await Swal.fire({
            icon: 'success',
            title: 'Empresa cadastrada com sucesso!',
            text: 'O que você gostaria de fazer?',
            showCancelButton: true,
            confirmButtonText: 'Cadastrar nova empresa',
            cancelButtonText: 'Voltar a listagem de empresas',
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/cadastro-empresas');
              formik.resetForm();
            } else {
              navigate('/listagem-empresas');
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar a empresa, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
          setStatus('Ocorreu um erro ao salvar o usuário. Por favor, tente novamente.');
        }
      } catch (error: any) {
        if (error.response) {
          const { data } = error.response;
          Swal.fire({
            icon: 'error',
            title: data.message,
            text: 'Por favor, verifique as informações',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar a empresa',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações de usuário corretamente');
      }
    },
  });

  useBuscaCnpj(formik.values.cnpj, formik);
  useBuscaCep(formik.values.cep, formik);

  const handleStatusEmpresaChange = (event) => {

    formik.setFieldValue('status', event.target.value);
  };

  return (
    <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-light-dark'>
        <PageTitulo id={null} tipoFormulario='Empresa' />

        <div
          className='mb-3 form-label-container'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <h5>Informações Gerais</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Nome *</label>
            <input
              type='text'
              placeholder='Insira o nome da empresa'
              {...formik.getFieldProps('nome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-3'>
            <label className='form-label'>CNPJ *</label>
            <InputMask
              mask='99.999.999/9999-99' // Aplicando a máscara de CNPJ
              placeholder='Insira o CNPJ da empresa'
              {...formik.getFieldProps('cnpj')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
              )}
            />
            {formik.touched.cnpj && formik.errors.cnpj && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cnpj}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Status</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleStatusEmpresaChange}
              value={formik.values.status}
            >
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Razão Social</label>
            <input
              type='text'
              placeholder='Insira a razão social da empresa'
              {...formik.getFieldProps('razaosocial')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.razaosocial && formik.errors.razaosocial },
                {
                  'is-valid': formik.touched.razaosocial && !formik.errors.razaosocial,
                }
              )}
            />
            {formik.touched.razaosocial && formik.errors.razaosocial && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.razaosocial}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Fantasia</label>
            <input
              type='text'
              placeholder='Insira o nome fantasia da empresa'
              {...formik.getFieldProps('fantasia')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.fantasia && formik.errors.fantasia },
                {
                  'is-valid': formik.touched.fantasia && !formik.errors.fantasia,
                }
              )}
            />
            {formik.touched.fantasia && formik.errors.fantasia && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fantasia}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'>Codigo</label>
            <input
              type='text'
              placeholder='Insira o codigo da empresa'
              {...formik.getFieldProps('codigo')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.codigo && formik.errors.codigo },
                {
                  'is-valid': formik.touched.codigo && !formik.errors.codigo,
                }
              )}
              onChange={(event) => {
                formik.setFieldValue('codigo', event.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
              }}
            />
            {formik.touched.codigo && formik.errors.codigo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.codigo}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Telefone *</label>
            <InputMask
              mask='(99)9999-9999'
              placeholder='Insira o telefone da empresa'
              {...formik.getFieldProps('telefone')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.telefone && formik.errors.telefone },
                {
                  'is-valid': formik.touched.telefone && !formik.errors.telefone,
                }
              )}
            />
            {formik.touched.telefone && formik.errors.telefone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.telefone}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Email *</label>
            <input
              type='text'
              placeholder='Insira o email da empresa'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Website</label>
            <input
              type='text'
              placeholder='Insira o website da empresa'
              {...formik.getFieldProps('website')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.website && formik.errors.website },
                {
                  'is-valid': formik.touched.website && !formik.errors.website,
                }
              )}
            />
            {formik.touched.website && formik.errors.website && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.website}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Localização</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'> CEP *</label>
            <InputMask
              mask='99.999-999'
              placeholder='Insira o CEP da empresa'
              {...formik.getFieldProps('cep')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cep && formik.errors.cep },
                { 'is-valid': formik.touched.cep && !formik.errors.cep }
              )}
            />
            {formik.touched.cep && formik.errors.cep && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cep}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'>
            <label className='form-label'> Endereço </label>
            <input
              type='text'
              placeholder='Endereço da empresa'
              {...formik.getFieldProps('endereco')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.endereco && formik.errors.endereco },
                {
                  'is-valid': formik.touched.endereco && !formik.errors.endereco,
                }
              )}
            />
            {formik.touched.endereco && formik.errors.endereco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.endereco}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'> Cidade </label>
            <input
              type='text'
              placeholder='Cidade da empresa'
              {...formik.getFieldProps('cidade')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                {
                  'is-valid': formik.touched.cidade && !formik.errors.cidade,
                }
              )}
            />
            {formik.touched.cidade && formik.errors.cidade && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cidade}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'>
            <label className='form-label'> Estado </label>
            <input
              type='text'
              placeholder='Estado da empresa'
              {...formik.getFieldProps('estado')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.estado && formik.errors.estado },
                {
                  'is-valid': formik.touched.estado && !formik.errors.estado,
                }
              )}
            />
            {formik.touched.estado && formik.errors.estado && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.estado}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-3'>
            <label className='form-label'> País </label>
            <input
              type='text'
              placeholder='País da empresa'
              {...formik.getFieldProps('pais')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.pais && formik.errors.pais },
                {
                  'is-valid': formik.touched.pais && !formik.errors.pais,
                }
              )}
            />
            {formik.touched.pais && formik.errors.pais && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.pais}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='d-flex gap-8'>
            <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
              Cadastrar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              className='btn-cadastro btn-danger'
              id='back'
              onClick={() => navigate('/listagem-empresas')}
            >
              Voltar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
