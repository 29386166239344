import { tr } from 'date-fns/locale';
import React, {useState, useEffect} from 'react';

type Props = {
    className: string
    infosPerfis : PerfilFormularioInfo[],
    onRemovePerfil: (index: number) => void;
}

interface PerfilFormularioInfo{

    status: number,
    idFormulario: number,
    descFormulario: string,
    ler: number,
    escrever: number,
    excluir: number,
    sincronizar: number

}

const formatValue = (value: number) => {
    return value === 1 ? 'Sim' : 'Não';
};

const formatStatus = (value: number) => {
    return value === 1 ? 'Ativo' : 'Inativo';
};

const TablePermissoes: React.FC<Props> = ({className, infosPerfis, onRemovePerfil}) => {

    return (
        <>
        <div className={`card ${className}`}>
            <div></div>
            <div className='card-header'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3'>Listagem Formulários de Acesso</span>
                </h3>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                        <thead>
                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                <th className="p-0 pb-3 text-center">Id Formulario</th>
                                <th className="p-0 pb-3 text-center">Desc. Formulario</th>
                                <th className="p-0 pb-3 text-center">Status</th>
                                <th className="p-0 pb-3 text-center">Ler</th>
                                <th className="p-0 pb-3 text-center">Escrever</th>
                                <th className="p-0 pb-3 text-center">Excluir</th>
                                <th className="p-0 pb-3 text-center">Sincronizar</th>
                                <th className="p-0 pb-3 text-center">Remover</th>
                            </tr>
                        </thead>
                        <tbody>
                            {infosPerfis.map((infosPerfil, index) => {
                                return (
                                    <tr key={infosPerfil.idFormulario || index}>
                                        <td className='text-center'>{infosPerfil.idFormulario}</td>
                                        <td className='text-center'>{infosPerfil.descFormulario}</td>
                                        <td className='text-center'>{formatStatus(infosPerfil.status)}</td>
                                        <td className='text-center'>{formatValue(infosPerfil.ler)}</td>
                                        <td className='text-center'>{formatValue(infosPerfil.escrever)}</td>
                                        <td className='text-center'>{formatValue(infosPerfil.excluir)}</td>
                                        <td className='text-center'>{formatValue(infosPerfil.sincronizar)}</td>
                                        <td className='text-center'>
                                            <button type='button'
                                                className='btn btn-sm btn-danger'
                                                onClick={() => onRemovePerfil(index)}
                                                >
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
};

export {TablePermissoes}

