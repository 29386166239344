import * as Yup from 'yup'


interface InfosDadosProduto {
  descricao: String;
  codigo_sku: String;
  origem_id: String | null;
  origem_desc: String;
  tipo_id: String | null;
  tipo_desc: String;
  ncm: String;
  gtin_ean: String;
  cest: String;
  preco_venda: Number | null;
  preco_promocional: Number | null;
  unidade: string | number;
  idUnidadeMedida: number | string | null
  peso_liq: Number | null;
  peso_bruto: Number | null;
  num_volumes: Number | null;
  tipo_embalagem_id: String | null;
  tipo_embalagem_desc: String;
  embalagem_id: String | null;
  embalagem_desc: String;
  largura: Number | null;
  altura: Number | null;
  comprimento: Number | null;
  controlar_estoque: Number | null;
  estoque_inicial: Number | null;
  estoque_min: Number | null;
  estoque_max: Number | null;
  sob_encomenda: Number | null;
  controlar_lotes: Number | null;
  localizacao: String;
  dias_preparacao: Number | null;
  categoria_id: String | null;
  categoria_desc: String;
  marca_id: Number | undefined | null;
  marca_desc: String | undefined;
  desc_complementar: String;
  img_produto_base64: String;
  img_url: String;
  link_video: String;
  slug: String;
  keywords: String;
  titulo_seo: String;
  custo_a_partir_de: String | null;
  custo_saldo_atual: Number | null;
  custo_saldo_anterior: Number | null;
  custo_impostos_rec: Number | null;
  custo_preco: Number | null;
  custo_medio: Number | null;
  custo_preco_venda: Number | null;
  fabricante: String;
  codigo_fabricante: String;
  uni_por_caixas: Number | null;
  linha_de_produto_id:String | null;
  linha_de_produto_desc: String;
  garantia: String;
  gtin_ean_tributavel: String;
  unidade_tributavel: String;
  fator_de_conversao: Number | null;
  codigo_enquadramento_ipi: String;
  valor_ipi_fixo: Number | null;
  obs_geral_produto: String;
  status: Number | null;
}

interface ListPrecoInfo {
  id?: string | number  
  idtabeladepreco: number  | null;
  descricao: string ;
  precovenda: number | null;
  precopromocional: number | null;
  status: number
}

interface ProdutoOption {
  id: string;
  desc: string;
}

interface OptionType {
 
  value: number | undefined;
  label: string | undefined;
}

const formProduto: InfosDadosProduto = {
  descricao: '',
  codigo_sku: '',
  origem_id: null,
  origem_desc: '',
  tipo_id: null,
  tipo_desc: '',
  ncm: '',
  gtin_ean: '',
  cest: '',
  preco_venda: null,
  preco_promocional: null,
  unidade: '',
  idUnidadeMedida: null,
  peso_liq: null,
  peso_bruto: null,
  num_volumes: null,
  tipo_embalagem_id: null,
  tipo_embalagem_desc: '',
  embalagem_id: null,
  embalagem_desc: '',
  largura: null,
  altura: null,
  comprimento: null,
  controlar_estoque: null,
  estoque_inicial: null,
  estoque_min: null,
  estoque_max: null,
  sob_encomenda: null,
  controlar_lotes: null,
  localizacao: '',
  dias_preparacao: null,
  categoria_id: null,
  categoria_desc: '',
  marca_id: undefined,
  marca_desc: '',
  desc_complementar: '',
  img_produto_base64: '',
  img_url: '',
  link_video: '',
  slug: '',
  keywords: '',
  titulo_seo: '',
  custo_a_partir_de: null,
  custo_saldo_atual: null,
  custo_saldo_anterior: null,
  custo_impostos_rec: null,
  custo_preco: null,
  custo_medio: null,
  custo_preco_venda: null,
  fabricante: '',
  codigo_fabricante: '',
  uni_por_caixas: null,
  linha_de_produto_id: null,
  linha_de_produto_desc: '',
  garantia: '',
  gtin_ean_tributavel: '',
  unidade_tributavel: '',
  fator_de_conversao: null,
  codigo_enquadramento_ipi: '',
  valor_ipi_fixo: null,
  obs_geral_produto: '',
  status: null,
};


 const cadastroValidationSchema = Yup.object().shape({

    // nome_razaosocial: Yup.string().required('Por favor, informe um nome para o parceiro'),
    // cnpj_cpf: Yup.string().required('Por favor, informe CPF ou CNPJ'),
    // tipo_parceiro: Yup.string().required('Por favor, o tipo de parceiro'),
    // status: Yup.string().required('Por favor, selecione o status'),
    // cep: Yup.string().required('Por favor, informe CEP'),
    // endereco: Yup.string().required('Por favor, informe um endereço'),
    // bairro:Yup.string().required('Por favor, informe o bairro'),
    // cidade: Yup.string().required('Por favor, informe a cidade'),
    // estado: Yup.string().required('Por favor, informe o estado'),
    // email: Yup.string().required('Por favor, informe o email do parceiro'),
    // telefone: Yup.string().required('Por favor, informe telefone de contato do parceiro'),
  })

export { cadastroValidationSchema, formProduto };
export type { InfosDadosProduto, ListPrecoInfo, ProdutoOption, OptionType}
