import React, { ChangeEvent } from 'react';
import { FC, useState, useEffect } from 'react';
import { PageTitle } from '../../../../_sysled/layout/core';
import { CardProduto } from './components/CardEstoque';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DotLoader } from 'react-spinners';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface Produto {
  idProduto: number;
  produto: string;
  idloja: number;
  idprevenda: number;
  qtdProduto: number;
  precoUnitario: number;
  imagem: string;
  dataprev: string;
  qtdComprado: null;
  estoqueTotal: null;
  date: string;
  hashDate: string;
  encarte: boolean;
  estoque: Estoque[];
  pedidoCompra: PedidoCompra[];
}

interface PedidoCompra {
  idPedidoStatus: number;
  pedidoStatus: number;
  qtdPedidos: number;
  qtdProdutos: number;
  dataPrevisaoEntrega: string;
  transportadoraFantasia: string;
  idTransportadora: string;
  numeroNotaFiscal: string;
  idCliente: string;
  clienteFantasia: string;
}

interface Estoque {
  wms: string;
  CodigoProduto: number;
  EstoqueProduto: number;
}

interface EstoqueFilto {
  wms: string;
  EstoqueProduto: number;
}

const ConsultaEstoqueWMS: FC = () => {
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('');
  const [mostrarEstoqueZerado, setMostrarEstoqueZerado] = useState(false);
  const [mostrarEstoque, setMostrarEstoque] = useState(false);
  const [mostrarProdutosPromocionais, setMostrarProdutosPromocionais] = useState(false);

  const [idPrevenda, setIdPrevenda] = useState('');
  const [nomeProduto, setNomeProduto] = useState('');
  const [codProduto, setCodProduto] = useState('');

  const buscaProdutos = async () => {
    let searchValue = '';

    if (searchFiltro === 'idprevenda') {
      searchValue = idPrevenda;
    } else if (searchFiltro === 'nomeproduto') {
      searchValue = nomeProduto;
    } else if (searchFiltro === 'codproduto') {
      searchValue = codProduto;
    }

    if (searchFiltro === '' || searchFiltro === null) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'blue',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Por favor, selecione pelo menos um filtro',
      });
      return;
    } else if (searchValue === '' || searchValue === null) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'blue',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Por favor, preencha o campo da pesquisa',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dashboards/estoque-wms`,
        {
          params: {
            search: searchValue,
            filtro: searchFiltro,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.length === 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            iconColor: 'blue',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: 'Não existe registros de estoque para essa pesquisa',
          });
          setProdutos([]);
        } else {
          setProdutos(response.data);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.location.href = '/auth';
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao carregar produtos',
          text: 'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente mais tarde.',
          confirmButtonText: 'Ok',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchFiltro === 'idprevenda') {
      setCodProduto('');
      setNomeProduto('');
    } else if (searchFiltro === 'nomeproduto') {
      setIdPrevenda('');
      setCodProduto('');
    } else if (searchFiltro === 'codproduto') {
      setIdPrevenda('');
      setNomeProduto('');
    } else if (searchFiltro === '') {
      setIdPrevenda('');
      setNomeProduto('');
      setCodProduto('');
    }
  }, [searchFiltro]);

  const handleSearch = (event) => {
    event.preventDefault();
    buscaProdutos();
  };

  function formatarNumero(numero) {
    return Math.floor(numero).toString();
  }

  function formatarPreco(preco) {
    const precoArredondado = Number(preco).toFixed(2);
    return `R$ ${precoArredondado}`;
  }

  function filtrarEstoqueDuplicados(estoque) {
    const estoqueFiltrado: EstoqueFilto[] = [];
    const wmsExistentes = new Set<String>();

    estoque.forEach((item) => {
      if (!wmsExistentes.has(item.wms)) {
        estoqueFiltrado.push(item);
        wmsExistentes.add(item.wms);
      }
    });

    return estoqueFiltrado;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {


  };

  function filtraEstoqueZerado(estoque, quantidadeProduto) {
    if (quantidadeProduto === null || quantidadeProduto === '') {
      quantidadeProduto = 0;
    }
    const somaEstoque = estoque.reduce((total, item) => total + parseFloat(item.EstoqueProduto), 0);
    return somaEstoque <= quantidadeProduto;
  }

  function filtraEstoque(estoque, quantidadeProduto) {
    if (quantidadeProduto === null || quantidadeProduto === '') {
      quantidadeProduto = 0;
    }
    const somaEstoque = estoque.reduce((total, item) => total + parseFloat(item.EstoqueProduto), 0);
    return somaEstoque > quantidadeProduto;
  }

  function formatarData(dataString) {
    const data = new Date(dataString);
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const dia = String(data.getDate()).padStart(2, '0');
    return `${dia}-${mes}-${ano}`;
  }

  return (
    <>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='text-dark fw-bolder'>Consulta Estoque</h1>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>

      <div className='mt-10'>
        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row  gap-4'>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label form-label-sm'>
                Selecione o tipo de filtro
              </label>
              <select
                className='form-select form-select-sm'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value=''>Selecione o filtro</option>
                {/* <option value='idprevenda'>Id Prevenda</option> */}
                <option value='nomeproduto'>Descrição Produto</option>
                <option value='codproduto'>Cód. Produto</option>
              </select>
            </div>
            <div className='col-sm-2' style={{ display: 'none' }}>
              <label htmlFor='' className='form-label form-label-sm'>
                Id Pré-Venda
              </label>
              <input
                type='number'
                placeholder='Pesquisar por id pré-venda'
                className='form-control form-control-sm'
                value={idPrevenda}
                onChange={(e) => setIdPrevenda(e.target.value)}
                disabled={searchFiltro !== 'idprevenda'}
              />
            </div>
            <div className='col-sm-2'>
              <label htmlFor='' className='form-label form-label-sm'>
                Código Produto
              </label>
              <input
                type='number'
                placeholder='Pesquisar por cód. do produto'
                className='form-control form-control-sm'
                value={codProduto}
                onChange={(e) => setCodProduto(e.target.value)}
                disabled={searchFiltro !== 'codproduto'}
              />
            </div>
            <div className='col-sm-2'>
              <label htmlFor='' className='form-label form-label-sm'>
                Descrição Produto
              </label>
              <input
                type='text'
                placeholder='Pesquisar por nome do produto'
                className='form-control form-control-sm'
                value={nomeProduto}
                onChange={(e) => setNomeProduto(e.target.value)}
                disabled={searchFiltro !== 'nomeproduto'}
              />
            </div>

            <div className='col-sm-2' style={{ margin: ' 25px 0' }}>
              <button type='submit' className='btn btn-sm btn-success'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='row mb-10 gap-4'>
        <div className='col-sm-3'>
          <label htmlFor='' className='form-label'>
            Exibir somente produtos com estoque zerado:
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckChecked'
              checked={mostrarEstoqueZerado} // Vinculando o estado ao switch
              onChange={(e) => setMostrarEstoqueZerado(e.target.checked)}
            />
          </div>
        </div>
        <div className='col-sm-3'>
          <label htmlFor='' className='form-label'>
            Exibir somente produtos com estoque:
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckChecked'
              checked={mostrarEstoque} // Vinculando o estado ao switch
              onChange={(e) => setMostrarEstoque(e.target.checked)}
            />
          </div>
        </div>
        <div className='col-sm-3'>
          <label htmlFor='' className='form-label'>
            Exibir somente produtos com preço promocional:
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckChecked'
              checked={mostrarProdutosPromocionais}
              onChange={(e) => setMostrarProdutosPromocionais(e.target.checked)}
            />
          </div>
        </div>
      </div>
      {/* <div className='row mb-8'>
        <div className='col-sm-1'></div>
        <div className='col-sm-2 mx-9'>
          <label htmlFor='' className='form-label'>
            Exibir somente produtos com estoque zerado:
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckChecked'
              checked={mostrarEstoqueZerado} // Vinculando o estado ao switch
              onChange={(e) => setMostrarEstoqueZerado(e.target.checked)}
            />
          </div>
        </div>
      </div> */}
      <div className='row'>
        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #d3d3d3', marginLeft: '10px' }}></div>
        </div>
        <div className='table-responsive'>
          {loading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: '100%' }}
            >
              <DotLoader color='#07ef25' loading={loading} size={66} />
            </div>
          ) : (
            <table className='table table-rounded table-row-bordered border gy-7 gs-7'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th scope='col' className='text-center'>
                    Imagem
                  </th>
                  <th scope='col' className='text-center'>
                    Produto
                  </th>
                  <th scope='col' className='text-center'>
                    Preço Unitário
                  </th>
                  <th scope='col' className='text-center'>
                    Quantidade do Produto
                  </th>
                  <th scope='col' className='text-center'>
                    Estoque
                  </th>
                </tr>
              </thead>
              <tbody>
                {produtos
                  .filter((produto) => {
                    const estoqueFiltrado = filtrarEstoqueDuplicados(produto.estoque);
                    const estoqueEstaZerado = filtraEstoqueZerado(
                      estoqueFiltrado,
                      produto.qtdProduto
                    );
                    const temEstoque = filtraEstoque(estoqueFiltrado, produto.qtdProduto);
                    const produtoPromocional = produto.encarte;
                    return (
                      (mostrarEstoqueZerado ? estoqueEstaZerado : true) &&
                      (mostrarEstoque ? temEstoque : true) &&
                      (mostrarProdutosPromocionais ? produtoPromocional : true)
                    );
                  })
                  .map((produto) => {
                    const estoqueFiltrado = filtrarEstoqueDuplicados(produto.estoque);
                    const estoqueEstaZerado = filtraEstoqueZerado(
                      estoqueFiltrado,
                      produto.qtdProduto
                    );
                    return (
                      <tr
                        key={produto.idProduto}
                        style={{ backgroundColor: estoqueEstaZerado ? '#f7b7b7' : 'transparent' }}
                      >
                        <td className='text-center' style={{ marginTop: '20px' }}>
                          {produto.imagem ? (
                            <img
                              src={produto.imagem}
                              alt={produto.produto}
                              style={{ width: '100px', height: 'auto' }}
                            />
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td className='text-center' style={{ marginTop: '20px' }}>
                          <b>{produto.produto || 'N/A'}</b>
                          {produto.pedidoCompra.length > 0 && (
                            <div style={{ maxHeight: '300px' }}>
                              <table
                                className='detalhesPedidoCompra table table-secondary table-sm table-striped'
                                style={{ width: '100%', marginTop: '20px' }}
                              >
                                <thead>
                                  <tr>
                                    <th scope='col' style={{ textAlign: 'start' }}>
                                      <b>Status Pedido</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end' }}>
                                      <b>Quant. Pedidos</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end' }}>
                                      <b>Quant. Produtos</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end', width: '15%' }}>
                                      <b>Previsao de Entrega</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end', width: '25%' }}>
                                      <b>Transportadora</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end' }}>
                                      <b>Nota Fiscal</b>
                                    </th>
                                    <th scope='col' style={{ textAlign: 'end', width: '25%' }}>
                                      <b> Cliente</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {produto.pedidoCompra
                                    .sort((a, b) => b.idPedidoStatus - a.idPedidoStatus)
                                    .map((itemPedidoCompra, index) => (
                                      <tr key={index}>
                                        <td style={{ padding: '10px', textAlign: 'start' }}>
                                          {itemPedidoCompra.pedidoStatus}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {itemPedidoCompra.qtdPedidos}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {itemPedidoCompra.qtdProdutos}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {formatarData(itemPedidoCompra.dataPrevisaoEntrega)}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {itemPedidoCompra.idTransportadora} -{' '}
                                          {itemPedidoCompra.transportadoraFantasia}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {itemPedidoCompra.numeroNotaFiscal}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'end' }}>
                                          {itemPedidoCompra.idCliente} -{' '}
                                          {itemPedidoCompra.clienteFantasia}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </td>

                        {produto.encarte === true ? (
                          <td className='text-center' style={{ color: 'blue', marginTop: '20px' }}>
                            <span>(Preço Promocional)</span>
                            <br />
                            {formatarPreco(produto.precoUnitario) || 'N/A'}
                          </td>
                        ) : (
                          <td className='text-center' style={{ color: 'green', marginTop: '20px' }}>
                            {formatarPreco(produto.precoUnitario) || 'N/A'}
                          </td>
                        )}

                        <td className='text-center'>
                          {formatarNumero(produto.qtdProduto) || 'N/A'}
                        </td>
                        <td className='text-center'>
                          <table className='table table-sm table-striped'>
                            <thead>
                              <tr>
                                <th scope='col'>
                                  <b>WMS</b>
                                </th>
                                <th scope='col'>
                                  <b>Estoque</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {estoqueFiltrado.map((itemEstoque, index) => (
                                <tr key={index}>
                                  <td>{itemEstoque.wms}</td>
                                  <td
                                    style={{
                                      color:
                                        itemEstoque.EstoqueProduto.toString() === '0.0'
                                          ? 'red'
                                          : '',
                                    }}
                                  >
                                    {parseInt(itemEstoque.EstoqueProduto.toString())}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export { ConsultaEstoqueWMS };
