import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {Table, TabContainer} from 'react-bootstrap';
import {useFormik} from 'formik';
import options from '../../selectOptions/useOptionsFormularios';
import {sendConsultor, updateConsultor} from '../cadastro_requests/cadastro_requests';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import PageTitulo from '../../components/Pagetitulo';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const cadastroValidationSchema = Yup.object().shape({
  nomeConsultor: Yup.string().required('Por favor, informe um nome para o Consultor'),
  contato: Yup.string().required('Por favor, infome o contato do Consultor'),
});

const initialValues = {
  nomeConsultor: '',
  cpf: '',
  contato: '',
  idConsultor: '',
};

export function CadastroConsultores() {
  const {id} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        if (!values.cpf) {
          Swal.fire({
            icon: 'info',
            title: 'Por favro, infor um CPF para o Consultor',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          return;
        }

        if (!values.idConsultor) {
          Swal.fire({
            icon: 'info',
            title: 'Por favro, infor um CPF para o Consultor',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          return;
        }

        //     if (permissoesFormulario.length === 0) {
        //         Swal.fire({
        //           icon: 'info',
        //           title: 'Por favor, adicione pelo menos um formulário de acesso.',
        //           confirmButtonText: 'Ok'
        //         });
        //         setLoading(false);
        //         return;
        //     }

        //    const permissaoFormulario =  permissoesFormulario;

        if (id) {
          const response = await updateConsultor(
            id,
            values.nomeConsultor,
            values.cpf,
            values.contato,
            Number(values.idConsultor)
          );
          if (response.status === 201) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Consultor atualizado com sucesso!',
              reverseButtons: true,
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/perfis-listagem');
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao atualizar o Consultor, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o Consultor. Por favor, tente novamente.');
          }
          //atualiza Perfil
        } else {
          //cria novo Perfil
          const response = await sendConsultor(
            values.nomeConsultor,
            values.cpf,
            values.contato,
            Number(values.idConsultor)
          );
          if (response.status === 201) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Consultor cadastrado com sucesso!',
              reverseButtons: true,
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/perfis-listagem');
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o Consultor, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o Consultor. Por favor, tente novamente.');
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar o Consultor',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        }
      }
    },
  });

  const handleStatusUsuarioChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  const showToast = (title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: 'info',
      title: title,
    });
  };

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className='text-light-dark mb-10'>
        <PageTitulo id={id} tipoFormulario='Consultor' />

        <div className='mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h5>Informações Gerais</h5>
          <div style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'>Nome Consultor(a) *</label>
            <input
              type='text'
              placeholder='Insira o nome do consultor'
              {...formik.getFieldProps('nomeConsultor')}
              className={clsx(
                'form-control form-control-sm -smbg-transparent',
                {'is-invalid': formik.touched.nomeConsultor && formik.errors.nomeConsultor},
                {
                  'is-valid': formik.touched.nomeConsultor && !formik.errors.nomeConsultor,
                }
              )}
              onChange={(event) => {
                //const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('nomeConsultor', event.target.value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.nomeConsultor && formik.errors.nomeConsultor && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nomeConsultor}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-2'>
            <label className='form-label'>CPF *</label>
            <InputMask
              mask='999.999.999-99'
              placeholder='Insira seu CPF'
              {...formik.getFieldProps('cpf')}
              className='form-control form-control-sm'
            />
            {formik.touched.cpf && formik.errors.cpf && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cpf}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Contato *</label>
            <input
              type='text'
              placeholder='Insira o contato'
              {...formik.getFieldProps('contato')}
              className={clsx(
                'form-control form-control-sm bg-transparent',
                {'is-invalid': formik.touched.contato && formik.errors.contato},
                {
                  'is-valid': formik.touched.contato && !formik.errors.contato,
                }
              )}
              onChange={(event) => {
                //const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('contato', event.target.value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.contato && formik.errors.contato && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contato}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>ID Consultor *</label>
            <input
              type='text'
              placeholder='Insira o contato'
              {...formik.getFieldProps('idConsultor')}
              className='form-control form-control-sm'
              onChange={(event) => {
                //const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('idConsultor', event.target.value); // Atualizar o valor do campo nome
              }}
            />
          </div>
        </div>
      </div>
      {/* <div>
        <div className='row'>
          <div className='col-sm-2'>
            <button type='submit' className='btn btn-bg-success' id='post_user_submit'>
              <span className='indicator-label'>{id ? 'Atualizar' : 'Cadastrar'}</span>
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <div className='col-sm-2'>
            <button
              type='button'
              className='btn btn-bg-danger'
              id='back'
              onClick={() => navigate('/perfis-listagem')}
            >
              <span className='indicator-label'>Voltar</span>
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div> */}
      <div className='d-flex gap-8'>
        <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
          Cadastrar
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn-cadastro btn-danger'
          id='back'
          onClick={() => navigate('/perfis-listagem')}
        >
          Voltar
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
