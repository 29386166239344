import { useState, useEffect } from 'react';


interface ModalListProps<T> {
  title?: string;
  isVisible?: boolean;
  toggleModal?: () => void;
  columns: Array<{
    Header: string;
    accessor?: keyof T;
    Cell?: (item: T) => JSX.Element;
  }>;
  isForm?: boolean;
  showFooter?: boolean;
  form?: JSX.Element;
  onFormSubmit?: boolean; // Callback para ações no formulário
  resetFormik?: () => void; // Callback para reset do Formik
  isParamRotinaData: T[];
}

const ModalList = <T extends { id?: number }>({
  title = 'Modal Listagem',
  isVisible = false,
  toggleModal = () => { },
  columns,
  isForm = false,
  showFooter = true,
  form,
  onFormSubmit,
  resetFormik,
  isParamRotinaData,
}: ModalListProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItems(isParamRotinaData);
  }, [isParamRotinaData]);

  const handleCloseModal = () => {
    toggleModal();
    resetFormik?.();
  };

  return (
    <div>
      {isVisible && (
        <div className='modal show d-block' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{title}</h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              {isForm && form}
              <div className='modal-body'>
                {loading ? (
                  <p>Carregando...</p>
                ) : (
                  <>
                    <table className='table table-striped text-center mx-auto'>
                      <thead>
                        <tr>
                          {columns.map((column, index) => (
                            <th key={index}>{column.Header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 ? (
                          items.map((item) => (
                            <tr key={item.id}>
                              {columns.map((column, index) => (
                                <td key={index}>
                                  {column.Cell
                                    ? column.Cell(item)
                                    : column.accessor
                                      ? String(item[column.accessor])
                                      : null}
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={columns.length}>Sem Dados</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
              {!isForm && showFooter && (
                <div className='modal-footer'>
                  <button className='btn btn-secondary' onClick={handleCloseModal}>
                    Fechar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalList;
