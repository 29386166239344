import {useEffect, useMemo, useState} from 'react';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, OverlayTrigger, Row as RowForm, Tooltip} from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
  DescriptionComponent,
  editLogisticaItemSeparacaoValidationSchema,
  FooterForm,
  IItemsSeparacao,
  AsyncSelectProduct,
  ISelectOption,
} from '../../../../../shared';
import {smoothScrollToBottom} from '../../../../../shared/core';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {addLogisticaSeparacaoItem} from '../../../cadastro_requests/cadastro_requests';
import {
  getItemsBySeparacaoId,
  getItemSeparacaoById,
  putLogisticaSeparacaoItem,
} from '../../../../listagem/listagem_requests/listagem_requests';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import './separacao-items-styles.scss';

type Props = {
  idSeparacao: number | string;
  idPrevenda: number | string;
  qtdItems: number | string;
  isSeqEntregaP?: number | string;
};

const SeparacaoItemTable: React.FC<Props> = ({idSeparacao, idPrevenda, isSeqEntregaP}) => {
  const [isSeqEntrega, setIsSeqEntrega] = useState<number | string>();
  const [isItemsSeparacaoData, setIsItemsSeparacaoData] = useState<IItemsSeparacao[]>([]);
  const separacaoListagemItemsColumns: Column<IItemsSeparacao>[] = [
    {
      Header: 'Id Pedido',
      accessor: 'idPrevenda',
      Cell: ({value}) => (
        <div style={{marginLeft: '5px'}} className='text-end'>
          {value}
        </div>
      ),
    },
    {
      Header: 'Id do Produto',
      accessor: 'idProduto',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'ID do Ambiente',
      accessor: 'idAmbiente',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Ambiente',
      accessor: 'ambiente',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Ambiente Complemento',
      accessor: 'ambienteComplemento',
      Cell: ({value}) => (
        <div className='text-start'>
          {typeof value === 'string' && value.length > 30
            ? value.slice(0, 30) + '...'
            : value || '...'}
        </div>
      ),
    },
    {
      Header: 'Quantidade Solicitada',
      accessor: 'qtdSolicitada',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Quantidade Atendida',
      accessor: 'qtdAtendida',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Saldo',
      accessor: 'saldo',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Preço Unitário',
      accessor: 'precoUnitario',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },

    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => {
        let statusText;
        switch (value) {
          case 'CANCELADO':
            statusText = 'CANCELADO';
            break;
          case 'ABERTO':
            statusText = 'ABERTO';
            break;
          case 'CONCLUIDO':
            statusText = 'CONCLUÍDO';
            break;
          default:
            statusText = 'DESCONHECIDO';
        }

        return <div className='text-start'>{statusText}</div>;
      },
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div style={{textAlign: 'left', marginRight: 20, marginLeft: 0}}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-edit-${row.original.id}`}>
                {row.original.seqEntrega
                  ? 'Não é possível realizar esta ação porque os items foram separados para logística.'
                  : 'Editar'}
              </Tooltip>
            }
          >
            <span className='d-inline-block'>
              <button
                onClick={() => handleOnEdit(row.original.id!)}
                className='btn btn-success btn-sm bi bi-pencil'
                disabled={row.original.seqEntrega ? true : false}
                style={{
                  opacity: row.original.seqEntrega ? 0.5 : 1,
                  pointerEvents: row.original.seqEntrega ? 'none' : 'auto',
                }}
              />
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const [isSeparacaoItemId, setIsItemSeparacaoId] = useState<string | number>('');
  const [isActionSeparacaoItem, setIsActionSeparacaoItem] = useState<'edit' | 'add' | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoadingItemSeparacao, setIsLoadingItemSeparacao] = useState<boolean>();

  const [isSelectAsyncProduct, setIsSelectAsyncProduct] = useState<ISelectOption | null>(null);
  const [selectedAmbienteAsync, setSelectedAmbienteAsync] = useState<ISelectOption | null>(null);

  const [isQtdItemsSeparacao, setIsQtdItemsSeparacao] = useState<number>(0);

  const [isSync, setIsSync] = useState<boolean>(false);

  const data = useMemo(() => isItemsSeparacaoData, [isItemsSeparacaoData]);
  const columns = useMemo(() => separacaoListagemItemsColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  //Ação de editar item da separação
  const handleOnEdit = (id: string | number) => {
    getItemSeparacaoData(id);
    setIsItemSeparacaoId(id);

    setIsActionSeparacaoItem('edit');
    smoothScrollToBottom();
  };

  //Ação de adicionar um novo item da separação
  const handleOnAdd = () => {
    formik.setValues({
      ambienteComplemento: '',
      idAmbiente: 0,
      precoUnitario: 0,
      qtdAtendida: 0,
      qtdSolicitada: 0,
      saldo: 0,
      status: 'ABERTO',
      idLogisticaSeparacao: 0,
      idPrevenda: 0,
      idProduto: 0,
    });

    setSelectedAmbienteAsync(null);

    setIsActionSeparacaoItem('add');
    smoothScrollToBottom();
  };

  //Setando os valores padrões do form
  const initialValues = {
    qtdSolicitada: 0,
    qtdAtendida: 0,
    saldo: 1,
    status: 'ABERTO',
    idAmbiente: 0,
    ambienteComplemento: '',
    precoUnitario: 0,
    idLogisticaSeparacao: 0,
    idProduto: 0,
    idPrevenda: 0,
  };

  const formik = useFormik({
    initialValues,
    // Validação condicional de schemas, add para a ação de adição, e edit para a ação de add
    validationSchema: editLogisticaItemSeparacaoValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingItemSeparacao(true);
      if (isActionSeparacaoItem === 'add') {
        if (!isSelectAsyncProduct || isSelectAsyncProduct.value == 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: 'A inserção do produto é obrigatória',
          });
          setSubmitting(false);
          setIsLoadingItemSeparacao(false);
          return;
        }
        try {
          await addLogisticaSeparacaoItem({
            ...values,
            idLogisticaSeparacao: idSeparacao,

            idProduto: isSelectAsyncProduct?.value !== 0 ? isSelectAsyncProduct?.value : undefined,
            idAmbiente: selectedAmbienteAsync?.value,
            ambiente: selectedAmbienteAsync?.label,
            seqEntrega: isSeqEntrega,
          });

          Swal.fire({
            icon: 'success',
            title: 'Item cadastrado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: false,
          });

          getItemsBySeparacaoData(idSeparacao);
          setIsActionSeparacaoItem(null);

          setSubmitting(false);
          setIsLoadingItemSeparacao(false);

          setSelectedAmbienteAsync(null);
          setIsSelectAsyncProduct(null);
        } catch (errors: any) {
          const {data, status} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoadingItemSeparacao(false);
        }
      } else {
        try {
          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar o item da separação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await putLogisticaSeparacaoItem(isSeparacaoItemId, {
              ...values,
              idAmbiente: selectedAmbienteAsync ? selectedAmbienteAsync.value : null,
            });

            Swal.fire({
              icon: 'success',
              title: 'Item da separação editado com sucesso!',
              showCancelButton: false,
              timer: 3000,
              timerProgressBar: false,
            });

            getItemsBySeparacaoData(idSeparacao);
            setIsActionSeparacaoItem(null);
          }

          setSubmitting(false);
          setIsLoadingItemSeparacao(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoadingItemSeparacao(false);
        }
      }
    },
  });

  //Get de items por separação
  const getItemsBySeparacaoData = async (id: string | number) => {
    setIsLoading(true);

    if (idSeparacao) {
      try {
        const response = await getItemsBySeparacaoId(id);

        const {data} = response;

        setIsSeqEntrega(data.seqEntrega);

        setIsItemsSeparacaoData(data.separacaoItens);

        const seqEntregaPrincipal = data.seqEntrega;

        const updatedSeparacaoItens = data.separacaoItens.map((item: IItemsSeparacao) => ({
          ...item,
          seqEntrega: item.seqEntrega ?? seqEntregaPrincipal, // injetando `seqEntrega` do objeto principal caso o valor do item seja `null`
        }));

        setIsItemsSeparacaoData(updatedSeparacaoItens);

        setIsSync(data.isSincronizado);

        setIsQtdItemsSeparacao(data.qtdItens);
        setIsLoading(false);
      } catch (errors: any) {
        const {error} = errors;

        const status = error.response.status;

        if (status === 401 || 403) {
          Swal.fire({
            icon: 'error',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          });
        }

        Swal.fire({
          icon: 'error',
          title: error,
          confirmButtonText: 'Ok',
        });
      }
    }
  };

  //Get de item da separação por id
  const getItemSeparacaoData = async (id: string | number) => {
    try {
      const response = await getItemSeparacaoById(id);

      const {data} = response;

      formik.setValues({
        ambienteComplemento: data.ambienteComplemento,
        idAmbiente: data.idAmbiente,
        precoUnitario: data.precoUnitario,
        qtdAtendida: data.qtdAtendida,
        qtdSolicitada: data.qtdSolicitada,
        saldo: data.saldo,
        status: data.status,
        idLogisticaSeparacao: data.idLogisticaSeparacao,
        idPrevenda: data.idPrevendaProduto,
        idProduto: data.idProduto,
      });

      setIsSync(data);

      if (data.ambiente) {
        loadOptionsAmbiente(data.ambiente);
      } else {
        setSelectedAmbienteAsync(null);
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  };

  //Load de items por separação
  useEffect(() => {
    getItemsBySeparacaoData(idSeparacao);
  }, []);

  //Setando a option do produto selecionado
  const handleAsyncSelect = (option: ISelectOption) => {
    setIsSelectAsyncProduct(option);

    const precoUnitario = option.label.precoUnitario;

    formik.setValues({
      ...formik.values,
      precoUnitario: precoUnitario,
    });
  };

  //Load de options de ambiente
  const loadOptionsAmbiente = async (inputValue: string) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaTipoAmbientePorNome/${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      setSelectedAmbienteAsync({
        label: `${data[0].idAmbiente} - ${data[0].ambiente}`,
        value: data[0].idAmbiente,
      });

      return data.map((item) => ({
        value: item.idAmbiente,
        label: `${item.idAmbiente} - ${item.ambiente}`,
      }));
    } catch (error) {}
  };

  //Setando
  const handleChangeAmbiente = (selectedOption: ISelectOption | null) => {
    if (selectedOption) {
      setSelectedAmbienteAsync(selectedOption);
    } else {
      setSelectedAmbienteAsync(null);
    }
  };

  return (
    <>
      <DescriptionComponent
        description='Listagem de Itens da Separação'
        buttonTitle='+ Adicionar'
        isButtonAction
        handleAction={() => handleOnAdd()}
        variantButton='warning'
        isDisabled={isSeqEntrega ? true : false}
        showTooltip
        tooltipText='Não é possível realizar esta ação porque os itens foram separados para logística.'
      />

      <div className='badge-container'>
        <div className='badge'>
          <p>
            Quantidade de Itens: <span>{isQtdItemsSeparacao.toLocaleString('pt-BR')}</span>
          </p>
        </div>
      </div>

      {typeof isSeqEntrega !== undefined && (
        <div className='table-responsive' style={{maxHeight: 500}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IItemsSeparacao>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IItemsSeparacao>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      <p> Separação Sem Items</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {isActionSeparacaoItem && (
        <>
          <DescriptionComponent
            description={
              isActionSeparacaoItem === 'edit'
                ? 'Edição de Item da Separação'
                : 'Cadastro de Item da Separação'
            }
            isSub
          />

          <form action='form-control-solid' onSubmit={formik.handleSubmit}>
            {isActionSeparacaoItem == 'add' ? (
              <RowForm>
                <Col md='12' className='col-12 mt-4'>
                  <label htmlFor='' className='form-label'>
                    Selecione o Produto:
                  </label>
                  <AsyncSelectProduct handleOptions={(option) => handleAsyncSelect(option!)} />
                </Col>
              </RowForm>
            ) : (
              <></>
            )}
            <RowForm className='mb-6 mt-6'>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Quantidade Solicitada:
                </label>
                <input
                  placeholder='Insira a quantidade solicitada:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('qtdSolicitada')}
                  className={`form-control ${
                    formik.touched.qtdSolicitada && formik.errors.qtdSolicitada ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    formik.setFieldValue('qtdSolicitada', inputValue);
                  }}
                />
                {formik.touched.qtdSolicitada && formik.errors.qtdSolicitada ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.qtdSolicitada}
                  </div>
                ) : null}
              </Col>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Quantidade Atendida:
                </label>
                <input
                  placeholder='Insira a quantidade atendida:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('qtdAtendida')}
                  className={`form-control ${
                    formik.touched.qtdAtendida && formik.errors.qtdAtendida ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    formik.setFieldValue('qtdAtendida', inputValue);
                  }}
                />
                {formik.touched.qtdAtendida && formik.errors.qtdAtendida ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.qtdAtendida}
                  </div>
                ) : null}
              </Col>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Saldo:
                </label>
                <input
                  placeholder='Insira o saldo:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('saldo')}
                  className={`form-control ${
                    formik.touched.saldo && formik.errors.saldo ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    formik.setFieldValue('saldo', inputValue);
                  }}
                />
                {formik.touched.saldo && formik.errors.saldo ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.saldo}
                  </div>
                ) : null}
              </Col>
              <Col md={3} className='col-12 mt-4'>
                <label className='form-label'>Status:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='CANCELADO'> CANCELADO </option>
                  <option value='ABERTO'> ABERTO </option>
                  <option value='CONCLUIDO'> CONCLUIDO </option>
                </select>
                {formik.touched.status && formik.errors.status ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.status}
                  </div>
                ) : null}
              </Col>
            </RowForm>
            <RowForm className='mb-6'>
              <Col md='4' className='col-12 mt-5'>
                <label htmlFor='' className='form-label'>
                  Ambiente
                </label>
                <AsyncSelect
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder={'Pesquise o ambiente'}
                  loadOptions={loadOptionsAmbiente}
                  isDisabled={false}
                  value={selectedAmbienteAsync}
                  isClearable
                  onChange={handleChangeAmbiente}
                />
              </Col>
              <Col md='4' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Complemento do Ambiente:
                </label>
                <input
                  placeholder='Insira o complemento do ambiente:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('ambienteComplemento')}
                  className={`form-control ${
                    formik.touched.ambienteComplemento && formik.errors.ambienteComplemento
                      ? 'is-invalid'
                      : ''
                  }`}
                />
                {formik.touched.ambienteComplemento && formik.errors.ambienteComplemento ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.ambienteComplemento}
                  </div>
                ) : null}
              </Col>
              <Col md='4' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Preço Unitário:
                </label>
                <input
                  placeholder='Insira o preço unitário:'
                  type='text'
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.precoUnitario || 0)}
                  className={`form-control ${
                    formik.touched.precoUnitario && formik.errors.precoUnitario ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formik.setFieldValue('precoUnitario', parseFloat(inputValue) / 100);
                  }}
                />

                {formik.touched.precoUnitario && formik.errors.precoUnitario ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.precoUnitario}
                  </div>
                ) : null}
              </Col>
            </RowForm>

            <FooterForm
              textActionSubmit={isActionSeparacaoItem === 'edit' ? 'Editar Item da Separação' : ''}
              isLoading={isLoadingItemSeparacao}
              redirectTo='logistica-separacao-listagem'
            />
          </form>
        </>
      )}
    </>
  );
};

export default SeparacaoItemTable;
