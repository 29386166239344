import * as Yup from 'yup';

export const cadastroLogisticaSeparacaoValidationSchema = Yup.object().shape({
  idPrevenda: Yup.number().required('Por favor, informe uma prevenda'),
  idLogisticaFluxo: Yup.number().required('Por favor, informe um fluxo'),
  status: Yup.string().required('Por favor, adicione um status'),
});

export const editLogisticaItemSeparacaoValidationSchema = Yup.object().shape({
  qtdSolicitada: Yup.number()
    .required('Por favor, informe uma quantidade solicitada'),
  
  qtdAtendida: Yup.number()
    .required('Por favor, informe a quantidade atendida')
    .test(
      'qtdAtendida-maior-que-qtdSolicitada',
      'A quantidade atendida não pode ser maior que a quantidade solicitada',
      function (value) {
        const { qtdSolicitada } = this.parent;
        return value <= qtdSolicitada;
      }
    ),

  saldo: Yup.number()
    .required('Por favor, informe o saldo do item')
    .test(
      'saldo-maior-que-qtdSolicitada',
      'O saldo não pode ser maior que a quantidade solicitada',
      function (value) {
        const { qtdSolicitada } = this.parent;
        return value <= qtdSolicitada;
      }
    ),

  status: Yup.string()
    .required('Por favor, informe o status do item'),
  
  precoUnitario: Yup.number()
    .required('Por favor, informe o preço unitário do item'),
});