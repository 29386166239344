import axios from "axios";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

export const API_CAMPANHA_PROMOCIONAL_POST = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/campanhaPromocional`
export const API_CAMPANHA_PROMOCIONAL_PUT = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/campanhaPromocional`
export const API_CAMPANHA_PROMOCIONAL_SEND = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/campanhaPromocional/sendG2`

export function postCampanhaPromocional(
    campanhaDados: any
){
    return axios.post(API_CAMPANHA_PROMOCIONAL_POST, campanhaDados)
}

export function putCampanhaPromocional(
    campanhaDados: any
){
    return axios.put(API_CAMPANHA_PROMOCIONAL_PUT, campanhaDados)
}

