import { Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

type Props = {
  isLoading?: boolean;
  redirectTo?: string;
  textActionSubmit?: string;
  isBack?: boolean;
  handleSubmit?: () => void;
  isDisabled?: boolean;
  showTooltip?: boolean;           // Nova prop para controlar o tooltip
  tooltipText?: string;            // Nova prop para definir o texto do tooltip
};

const FooterForm: React.FC<Props> = ({
  isLoading,
  redirectTo,
  handleSubmit,
  textActionSubmit,
  isBack = true,
  isDisabled,
  showTooltip = false,             // Valor padrão para não mostrar tooltip
  tooltipText = "Ação indisponível no momento.", // Texto padrão para o tooltip
}) => {
  const navigate = useNavigate();

  // Tooltip content usando o texto passado como prop
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <div className="d-flex gap-8 mt-8 flex-wrap flex-md-nowrap justify-content-md-end">
      {isBack ? (
        <button
          style={{ minWidth: '180px', fontSize: '14px' }}
          type="button"
          className="btn-cadastro btn-danger w-100 w-md-auto"
          id="back"
          onClick={() => navigate(`/${redirectTo}`)}
        >
          Voltar
        </button>
      ) : null}

      <OverlayTrigger
        placement="top"
        overlay={isDisabled && showTooltip ? renderTooltip : <></>}
      >
        <span className="d-inline-block">
          <button
            style={{
              minWidth: '180px',
              fontSize: '14px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: isDisabled ? '0.5' : '1',
              pointerEvents: isDisabled ? 'none' : 'auto',
            }}
            type="submit"
            className="btn-cadastro btn-bg-success w-100 w-md-auto"
            id="post_user_submit"
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : textActionSubmit ? (
              textActionSubmit
            ) : (
              'Cadastrar'
            )}
          </button>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default FooterForm;
