/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useCallback } from 'react';
import {
  CardsWidget1,
  CardsWidget2,
  CardsWidget3,
  CardsWidget4,
  EngageWidget1,
} from '../../../../_sysled/partials/widgets';
import { PageTitle } from '../../../../_sysled/layout/core';
import useOptionsEmpresasVinculadas from '../../selectOptions/useOptionsEmpresasVinculadas';
import { Button, Form, FormSelect, TabContainer, Table } from 'react-bootstrap';
import { ResumoGeralConsultores } from '../../../../_sysled/partials/widgets/tables/ResumoGeralConsutores';
import { useAuth } from '../../../modules/auth';
import Swal from 'sweetalert2';
import Chart from 'chart.js/auto';
import { DotLoader } from 'react-spinners';
import { getDashComercial } from '../dashboard_requests/dash_requests';
import { fetchOptionsProdutosGlobal } from '../../../pages/selectOptions/useGlobalOptionsProdutos';
import { fetchOptionsAmbienteGlobal } from '../../selectOptions/useOptionsAmbiente';
import { fetchOptionsAlmoxarifadoGlobal } from '../../useUtils/useBuscaAlmoxarifadoEmpresa';
//dateRanger imports
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
//date-fns imports
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { RangeType } from 'rsuite/esm/DatePicker/types';

const RelatorioVendas: FC<{ dashboardData: any }> = ({ dashboardData }) => (
  <>
    <div className='row g-5 gx-xl-10 mb-5'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget1
          className='h-md-50 mb-5 mb-xl-10'
          description='Quantidade de Vendas'
          color='#a3ff00'
          qtdVendas={dashboardData ? dashboardData.periodoAtual.resume.totalPedidos : ''}
          mesAnterior={dashboardData ? dashboardData.periodoMesAnterior.resume.totalPedidos : ''}
          anoAnterior={dashboardData ? dashboardData.periodoAnoAnterior.resume.totalPedidos : ''}
        />
        <CardsWidget2
          className='h-md-50 mb-5 mb-xl-10'
          description='Margem'
          color='#c9d1da'
          margem={
            dashboardData
              ? `${(dashboardData.periodoAtual.resume.margem * 100).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
              : ''
          }
          mrgMesAnterior={
            dashboardData
              ? `${(dashboardData.periodoMesAnterior.resume.margem * 100).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
              : ''
          }
          mrgAnoAnterior={
            dashboardData
              ? `${(dashboardData.periodoAnoAnterior.resume.margem * 100).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
              : ''
          }
        />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget3
          className='h-md-50 mb-5 mb-xl-10'
          description='Ticket Médio'
          color='#c9d1da'
          tktMedio={
            dashboardData
              ? `R$ ${dashboardData.periodoAtual.resume.totalticketMedio.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
              : ''
          }
          tktMesAnterior={
            dashboardData
              ? `R$ ${dashboardData.periodoMesAnterior.resume.totalticketMedio.toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          tktAnoAnterior={
            dashboardData
              ? `R$ ${dashboardData.periodoAnoAnterior.resume.totalticketMedio.toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
        />
        <CardsWidget4
          className='h-md-50 mb-5 mb-xl-10'
          description='Iluminação Decorativa'
          color='#000000'
          decorativos={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.decorativos.atual.valorTotal).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          dcrtMesAnterior={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.decorativos.mesAnterior.valorTotal).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          dcrtAnoAnterior={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.decorativos.anoAnterior.valorTotal).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
        />
      </div>
      <div className='col-xxl-6'>
        <EngageWidget1
          className='card-flush h-md-100'
          description='Faturamento'
          color=''
          faturamento={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.periodoAtual.resume.totalCaixa).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          ftrMesAnterior={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.periodoMesAnterior.resume.totalCaixa).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          ftrAnoAnterior={
            dashboardData
              ? `R$ ${parseFloat(dashboardData.periodoAnoAnterior.resume.totalCaixa).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`
              : ''
          }
          lojas={
            dashboardData && dashboardData.periodoAtual && dashboardData.periodoAtual.resultset
              ? dashboardData.periodoAtual.resultset
              : ''
          }
        />
      </div>
    </div>
    <div>
      <TabContainer>
        <Table className='my-0'>
          <ResumoGeralConsultores
            className='card-flush h-md-100'
            consultores={dashboardData ? dashboardData.consultores.resultset : []}
          />
        </Table>
      </TabContainer>
    </div>
  </>
);

const RelatorioVendasWrapper: FC = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [valueDateRange, setValueDateRange] = useState<DateRange>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<number>(-1);
  const { currentUser } = useAuth();
  const empresasVinculadasCodigos =
    currentUser?.empresasVinculadas?.map((empresa) => empresa.codigo) || [];
  const { options } = useOptionsEmpresasVinculadas(empresasVinculadasCodigos);
  const [dashboardData, setDashboardData] = useState(null);

  const handleDateRangeChange = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value) {
      setValueDateRange([value[0], value[1]]);
    }
  };

  const fetchFiltrarPeriodo = useCallback(async () => {
    setLoading(true);
    try {
      if (valueDateRange && empresaSelecionada !== -11) {
        const dataInicial = valueDateRange[0].toISOString().split('T')[0];
        const dataFinal = valueDateRange[1].toISOString().split('T')[0];

        let empresasParam;

        if (empresaSelecionada === -1) {
          empresasParam = empresasVinculadasCodigos;
        } else {
          empresasParam = [empresaSelecionada];
        }

        const response = await getDashComercial(dataInicial, dataFinal, empresasParam);

        if (response.status === 200) {
          setDashboardData(response.data.resultset);
        }
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: 'info',
          title: 'Por favor, selecione uma empresa e um intervalo de datas',
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.location.href = '/auth';
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao carregar relatório',
          text: 'Ocorreu um erro ao carregar os relatórios. Por favor, tente novamente mais tarde.',
          confirmButtonText: 'Ok',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [valueDateRange, empresaSelecionada, empresasVinculadasCodigos]);

  useEffect(() => {
    fetchFiltrarPeriodo();
    fetchOptionsAlmoxarifadoGlobal(currentUser);
    fetchOptionsAmbienteGlobal();
  }, []);

  //ATT AUTOMÁTICA DEFINIDA EM 5 MINUTOS *solicitação André (Gestor T.I)
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchFiltrarPeriodo();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [fetchFiltrarPeriodo]);


  return (
    <>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='text-dark fw-bolder'>Relatório Vendas</h1>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>

      <div className='mt-10 mb-6'>
        {/* <h3 className="text-dark fw-bolder mb-3">Selecione a empresa:</h3> */}
        <div className='row'>
          <div className='row mb-6 gap-4'>
            <div className='col-sm-3'>
              <Form.Select
                className='form-select form-select-sm'
                value={empresaSelecionada}
                onChange={(e) => setEmpresaSelecionada(Number(e.target.value))}
                name=''
                id=''
              >
                <option value='-11'>Selecione a loja</option>
                <option value='-1'> TODAS AS LOJAS </option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className='col-sm-2'>
              <DateRangePicker
                style={{ width: '290px' }}
                appearance='default'
                // value={valueDateRange}
                onChange={handleDateRangeChange}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Selecione um intervalo de datas'
              />
            </div>
            <div className='col-sm-3'>
              <Button className='btn-sm fw-bold btn-success' onClick={fetchFiltrarPeriodo}>
                Filtrar Período
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
          <DotLoader color='#07ef25' loading={loading} size={66} />
        </div>
      ) : (
        <RelatorioVendas dashboardData={dashboardData} />
      )}
    </>
  );
};

export { RelatorioVendasWrapper };
