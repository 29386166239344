/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../../helpers';
import AvatarConsultor from '../../../../app/pages/listagem/proposta-pedido/components/AvatarConsultor';

type Props = {
  className: string;
  consultores: Consultor[];
};

type Consultor = {
  consultor: string;
  idConsultor: string;
  margens: {
    margem: string;
  };
  qtdVendas: string;
  ticketMedio: string;
  totalVendas: string;
};

const formatarValor = (valor) => {
  // Converte o valor para um número, removendo pontos e substituindo vírgulas por pontos
  // Formata o valor para o formato desejado
  return `R$ ${Number(valor).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const formatarPorcentagem = (valor) => {
  // Converte o valor para um número e multiplica por 100 para obter a porcentagem
  const porcentagem = Number(valor) * 100;
  // Formata o valor para o formato desejado
  return `${porcentagem.toFixed(2)}%`;
};

const ResumoGeralConsultores: React.FC<Props> = ({ className, consultores }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Resumo Geral</span>
          <span className='text-muted mt-1 fw-semibold fs-6'>por Consultor</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
            <thead>
              <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                <th className='p-0 pb-3 min-w-175px text-start'>CONSULTOR</th>
                <th className='p-0 pb-3 min-w-100px text-end'>VENDAS</th>
                <th className='p-0 pb-3 min-w-100px text-end'>TICKET MEDIO</th>
                <th className='p-0 pb-3 min-w-125px text-end'>QTD VENDAS</th>
                <th className='p-0 pb-3 min-w-100px text-end'>MARGEM</th>
              </tr>
            </thead>
            <tbody>
              {consultores.map((consultor, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-40px me-3'>
                        <AvatarConsultor
                          idFuncionario={consultor.idConsultor}
                          funcionario={consultor.consultor}
                        />
                      </div>
                      <span className='text-gray-800 fw-bold mb-1 fs-6'>{consultor.consultor}</span>
                    </div>
                  </td>
                  <td className='text-end pe-0'>
                    <span id='vendasTotais' className='text-gray-600 fw-bold fs-6'>
                      {formatarValor(consultor.totalVendas)}
                    </span>
                  </td>
                  <td className='text-end pe-0'>
                    <span className='text-gray-600 fw-bold fs-6'>
                      {formatarValor(consultor.ticketMedio)}
                    </span>
                  </td>
                  <td className='pe-0'>
                    <div className='d-flex align-items-center justify-content-end'>
                      <span className='text-gray-600 fw-bold d-block fs-6'>
                        {consultor.qtdVendas}
                      </span>
                    </div>
                  </td>
                  <td className='text-end pe-0'>
                    <span className='text-gray-600 fw-bold fs-6'>
                      {formatarPorcentagem(consultor.margens.margem)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export { ResumoGeralConsultores };
