import React from "react";
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import FormControl from "./FormControl";

function FormContainer(){

    const validationSchema = Yup.object().shape({   
        nome: Yup.string()
            .min(4, 'O nome da empresa deve conter pelo menos 4 caracteres')
            .required('Por favor, informe um nome'),
    })

    const initialvalues = {
        nome: ""
    }

    return (
        <Formik initialValues={initialvalues} validationSchema={validationSchema} onSubmit={(values) => {console.log(values)}}>
            <Form className="form-control-solid">
                <div className='text-dark'>
                    <FormControl type="text" control="Text" name="nome" label="Nome"/>
                    <button className="btn btn-success btn-sm">Test</button>
                </div>
            </Form>
        </Formik>
    )
}

export default FormContainer;