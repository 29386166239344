import { Dropdown, ButtonGroup, Form } from 'react-bootstrap';
import './dropdown-multiple-choice.scss';
import { useState } from 'react';

const DropdownMultipleChoice = ({
  label,
  options,
  onSelect,
  selectedOptions,
  toggleDropdown,
  isOpen,
  disabled,
  isMultiSelect,
  canWrite
}) => {
  const [filter, setFilter] = useState(''); // Estado para armazenar o texto do filtro

  const handleSelect = (option) => {
    if (isMultiSelect) {
      if (selectedOptions.includes(option.value)) {
        onSelect(option.value, true); // remove
      } else {
        onSelect(option.value); // add
      }
    } else {
      onSelect(option.value);
    }
  };

  const handleRemove = (option) => {
    if (isMultiSelect) {
      onSelect(option, true);
    } else {
      onSelect(null);
    }
  };

  // Filtra as opções com base no texto inserido
  const filteredOptions = canWrite ?
    options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase()))
    : options;

  return (
    <div className={`dropdown-container`}>
      <label className='form-label'>
        {label}
      </label>
      <Dropdown
        as={ButtonGroup}
        show={isOpen}
        autoClose="outside"
        onToggle={toggleDropdown}
        className="w-100"
        disabled={disabled}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          className="form-control text-start no-caret no-arrow"
          disabled={disabled}
        >
          {isMultiSelect ? (
            selectedOptions.length === 0 ? (
              <span>Selecione</span>
            ) : (
              <span>
                {selectedOptions.map((opt) => (
                  <span key={opt} className="badge me-1">
                    {options.find(option => option.value === opt)?.label}
                    <button
                      type="button"
                      className="btn-close btn-close-white ms-2"
                      aria-label="Close"
                      onClick={() => handleRemove(opt)}
                    />
                  </span>
                ))}
              </span>
            )
          ) : (
            !selectedOptions ? (
              <span>Selecione</span>
            ) : (
              <span className="badge">
                {options.find(option => option.value === selectedOptions)?.label}
                <button
                  type="button"
                  className="btn-close btn-close-white ms-2"
                  aria-label="Close"
                  onClick={() => handleRemove(selectedOptions)}
                />
              </span>
            )
          )}
          <span style={{ marginLeft: 'auto' }}></span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {canWrite && ( // Campo de entrada se canWrite for true
            <Form.Control
              type="text"
              placeholder="Filtrar opções..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)} // Atualiza o filtro
              className="mb-2" // Adiciona uma margem inferior
            />
          )}
          {filteredOptions.map((option) => (
            <Dropdown.Item
              key={option.value}
              as="div"
              className={`d-flex align-items-center ${isMultiSelect ? (selectedOptions.includes(option.value) ? 'selected' : '') : (selectedOptions === option.value ? 'selected' : '')}`}
              onClick={() => handleSelect(option)}
              disabled={disabled}
            >
              <span>{option.label}</span>
              {isMultiSelect ? (
                selectedOptions.includes(option.value) && (
                  <span className="badge ms-auto">
                    Selecionado
                  </span>
                )
              ) : (
                selectedOptions === option.value && (
                  <span className="badge ms-auto">
                    Selecionado
                  </span>
                )
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownMultipleChoice;
