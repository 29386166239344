import { useEffect, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { ProductOption } from '../../../pages/cadastros/proposta-pedido/types/interface';
import icon_img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg';
import {IConsultor, ISelectOption } from '../../domain-types';
import './async-select-consultor-styles.scss';

type IProps = {
  handleOptions?: (product: ProductOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectConsultor: React.FC<IProps> = ({ handleOptions, inputOption, isDisabled, onKeyDown }) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedConsultorAsync, setSelectConsultorAsync] = useState<ISelectOption | null>(null);
  const [consultorDetails, setConsultorDetails] = useState<IConsultor[]>();

  const loadOptionConsultor = async (inputValue: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaNomeFuncionario/${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      setConsultorDetails(data);

      return data.map((item) => ({
        value: item.idFuncionario,
        label: item.idFuncionario,
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const consultor = consultorDetails?.find(
      (consultor) => consultor.idFuncionario === String(option.value)
    );

    if (consultor) {
      const imageUrl = `${process.env.REACT_APP_IMG_URL}/avatars/${consultor.idFuncionario}.jpg`;
      const imageNotFound = icon_img_not_found;

      return (
        <div className='container-message'>
          <img
            src={imageUrl}
            alt='Consultor Avatar'
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src = imageNotFound;
            }}
          />

          <div className='info-container'>
            <p>
              <span>{consultor.funcionario !== 'null' ? consultor.funcionario : 'SEM DADOS'}</span>
            </p>
            <p>
              CPF: <span>{consultor.cnpj !== 'null' ? consultor.cnpj : 'SEM DADOS'}</span>
            </p>
            <p>
              Contato: <span>{consultor.contato !== null ? consultor.contato : 'SEM DADOS'}</span>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedConsultorAsync && handleOptions) {
      handleOptions(selectedConsultorAsync);
    }
  }, [selectedConsultorAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionConsultor(inputOption.value);
      setSelectConsultorAsync(inputOption);
    } else if (inputOption == null) {
      setSelectConsultorAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o consultor'
      loadOptions={loadOptionConsultor}
      value={selectedConsultorAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectConsultorAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectConsultor;
