import {useEffect, useState} from 'react';
import {
  cadastroCategorizacaoValidationSchema,
  DescriptionComponent,
  FooterForm,
  ISelectOption,
} from '../../../shared';
import {useFormik} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  putCategorizacaoProduto,
  sendCategorizacaoProduto,
} from '../cadastro_requests/cadastro_requests';
import {getCategorizacaoProdutoById} from '../../listagem/listagem_requests/listagem_requests';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const CategorizacaoProdutosCadastroPage: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCategorizacaoProduto, setIsCategorizacaoProduto] = useState<ISelectOption | null>(null);
  const [isLabel, setIsLabel] = useState<string | null>('');
  const [selectKey, setSelectKey] = useState<number>(0);

  const initialValues = {
    titulo: '',
    descricao: '',
    status: 'ATIVO',
    modelo: 1,
    codigoClassificacao: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroCategorizacaoValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);

      if (id) {
        if (!isCategorizacaoProduto && formik.values.modelo > 1) {
          let model = Number(formik.values.modelo);
          let modelMessage: string; // Defina o tipo de `modelMessage` como `string`

          switch (model) {
            case 2:
              modelMessage = 'A inserção do seguimento é obrigatória';
              break;
            case 3:
              modelMessage = 'A inserção do Tipo de Produto é obrigatória';
              break;
            case 4:
              modelMessage = 'A inserção do grupo de categoria é obrigatória';
              break;
            default:
              modelMessage = 'Error';
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: modelMessage,
          });
          setSubmitting(false);
          setIsLoading(false);
          return;
        }

        try {
          await putCategorizacaoProduto(id, {
            ...values,
            idPai: isCategorizacaoProduto?.value,
          });
          Swal.fire({
            icon: 'success',
            title: 'Categorização editada com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de categorização',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/produto-categorizacao-listagem');
            },
          });
          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        if (!isCategorizacaoProduto && formik.values.modelo > 1) {
          let model = Number(formik.values.modelo);
          let modelMessage: string; // Defina o tipo de `modelMessage` como `string`

          switch (model) {
            case 2:
              modelMessage = 'A inserção do segmento é obrigatória';
              break;
            case 3:
              modelMessage = 'A inserção do Tipo de Produto é obrigatória';
              break;
            case 4:
              modelMessage = 'A inserção do grupo de categoria é obrigatória';
              break;
            default:
              modelMessage = 'Error';
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: modelMessage,
          });
          setSubmitting(false);
          setIsLoading(false);
          return;
        }

        try {
          const response = await sendCategorizacaoProduto({
            ...values,
            idPai: isCategorizacaoProduto?.value,
          });

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Categorização cadastrada com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de categorizacoes',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/produto-categorizacao-listagem');
              },
            });
          }
        } catch (error: any) {
          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const loadCategorizacaoById = async (id: string) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/${id}`;

      const response = await axios.get(url);

      const data = await response.data;

      setIsCategorizacaoProduto({
        value: data.id,
        label: `${data.titulo}${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.titulo} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsCategorizacao = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/listar?modelo=${
        formik.values.modelo - 1
      }&size=10000`;

      const response = await axios.get(url);
      const data = await response.data;

      const {content} = data;

      return content.map((item) => ({
        value: item.id,
        label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const handleChangeCategorizacao = (selectedOption: ISelectOption | null) => {
    setIsCategorizacaoProduto(selectedOption!);
  };

  useEffect(() => {
    const modelo = Number(formik.values.modelo);

    switch (modelo) {
      case 1:
        formik.setFieldValue('modeloPai', 1); // SEGMENTO
        break;
      case 2:
        formik.setFieldValue('modeloPai', 1); // SEGMENTO
        setIsLabel('Segmento:');
        loadOptionsCategorizacao();
        break;
      case 3:
        formik.setFieldValue('modeloPai', 2); // TIPO DE PRODUTO
        setIsLabel('Tipo de produto:');
        loadOptionsCategorizacao();
        break;
      case 4:
        formik.setFieldValue('modeloPai', 3); // GRUPO DE CATEGORIA
        loadOptionsCategorizacao();
        setIsLabel('Grupo de Categoria:');
        break;
      default:
        formik.setFieldValue('modeloPai', null);
        setIsLabel(null);
        break;
    }

    setSelectKey((prevKey) => prevKey + 1);
  }, [formik.values.modelo]);

  //Get de Categorização de Produtos por Id
  const getCategoriesById = async (id: number) => {
    try {
      const response = await getCategorizacaoProdutoById(id);

      const {data} = response;

      formik.setValues({
        descricao: data.descricao,
        modelo: data.modelo,
        status: data.status,
        titulo: data.titulo,
        codigoClassificacao: data.codigoClassificacao,
      });

      if (data.modelo > 1) {
        loadCategorizacaoById(data.idPai);
      }
    } catch (errors: any) {
      const {data, status} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  };

  //Call de Categorização de Produtos por Id
  useEffect(() => {
    if (id) {
      getCategoriesById(Number(id));
    }
  }, [id]);

  return (
    <>
      <DescriptionComponent
        description={id ? 'Editar Categorização de Produto' : 'Cadastrar Categorização de Produto'}
        redirectTo='/produto-categorizacao-listagem'
        withLink
        buttonTitle='Listagem de Categorização de Produtos'
      />

      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row className='col-12 mb-4'>
          <Col className='col-sm-4'>
            <label className='form-label'>Título:</label>
            <input
              type='text'
              placeholder='Digite o título da categorização'
              {...formik.getFieldProps('titulo')}
              className={`form-control ${
                formik.touched.titulo && formik.errors.titulo ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.titulo && formik.errors.titulo ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.titulo}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-4'>
            <label className='form-label'>Descrição:</label>
            <input
              type='text'
              placeholder='Digite a descrição da categorização'
              {...formik.getFieldProps('descricao')}
              className={`form-control ${
                formik.touched.descricao && formik.errors.descricao ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.descricao && formik.errors.descricao ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.descricao}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-4'>
            <label className='form-label'>Código Classificação:</label>
            <input
              type='number'
              placeholder='Digite o código de classificação'
              {...formik.getFieldProps('codigoClassificacao')}
              className={`form-control ${
                formik.touched.codigoClassificacao && formik.errors.codigoClassificacao ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.titulo && formik.errors.titulo ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.codigoClassificacao}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='col-12'>
          <Col className='col-sm-6'>
            <div>
              <label className='form-label'>Status:</label>
              <select
                {...formik.getFieldProps('status')}
                className={`form-control ${
                  formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                }`}
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-6'>
            <div>
              <label className='form-label'>Modelo:</label>
              <select
                {...formik.getFieldProps('modelo')}
                className={`form-control ${
                  formik.touched.modelo && formik.errors.modelo ? 'is-invalid' : ''
                }`}
              >
                <option value='1'>SEGMENTO</option>
                <option value='2'>TIPO DE PRODUTO</option>
                <option value='3'>GRUPO DE CATEGORIA</option>
                <option value='4'>CATEGORIA</option>
              </select>
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='col-12'>
          {formik.values.modelo > 1 ? (
            <Col className='col-sm-6 mt-4'>
              <label htmlFor='' className='form-label'>
                {isLabel}
              </label>
              <AsyncSelect
                key={selectKey}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder={isLabel}
                loadOptions={loadOptionsCategorizacao}
                isDisabled={false}
                value={isCategorizacaoProduto}
                isClearable
                onChange={handleChangeCategorizacao}
                defaultOptions
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Categorização' : 'Cadastrar Categorização'}
          redirectTo='produto-categorizacao-listagem'
        />
      </form>
    </>
  );
};

export default CategorizacaoProdutosCadastroPage;
