import Iframe from 'react-iframe';

const PDFView = ({ source }) => {
  return (
    <>
      <Iframe
        url={`/web/viewer.html?file=${source}`}
        position='relative'
        width='100%'
        id='myId'
        className='myClassname'
        height='100%'
        styles={{ height: '100vh' }}
      />
    </>
  );
};

export default PDFView;
