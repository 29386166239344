import React, { useEffect, useState } from "react";
import axios from 'axios'; // Importe o Axios

interface OptionType {
  value: number;
  label: string;
}

interface OptionTypeWithDesc extends OptionType {
  desc: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useOptionsFormularios = () => {
  const [options, setOptions] = useState<OptionTypeWithDesc[]>([]);

  useEffect(() => {
    async function fetchOptions() {
      try {
        const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios/optionsFormularios`);
        const data = response.data; // Axios retorna os dados diretamente na propriedade.data
        const formattedOptions = data.map(item => ({
          value: item.id,
          label: `${item.arquivo}: ${item.descricao}`,
          desc: item.descricao
        }));
        setOptions(formattedOptions);
      } catch (error) {
        console.error("Erro ao buscar opções:", error);
      }
    }
    fetchOptions();
  }, []);

  return { options };
};

export default useOptionsFormularios;