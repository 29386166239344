import {useEffect, useState} from 'react';
import axios from 'axios'; // Importe o Axios

interface OptionType {
  value: number;
  label: string;
}

interface OptionTypeWithDesc extends OptionType {
  desc: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useOptionsWorkflowAprovacao = () => {
  const [optionsAprovacao, setOptionsAprovacao] = useState<OptionTypeWithDesc[]>([]);

  useEffect(() => {
    async function fetchOptions() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowaprovacao/options`
        );
        const data = response.data;
        const formattedOptions = data.map((item) => ({
          value: item.id,
          label: `${item.titulo}`,
          desc: item.titulo,
        }));
        setOptionsAprovacao(formattedOptions);
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
      }
    }
    fetchOptions();
  }, []);

  return {optionsAprovacao};
};

export default useOptionsWorkflowAprovacao;
