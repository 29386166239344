import {useEffect, useState} from 'react';
import {DescriptionComponent, FooterForm} from '../../../shared/components';
import {useFormik} from 'formik';
import {
  cadastroFormularioValidationSchema,
  cadastroUnidadeMedidaValidationSchema,
} from '../../../shared';
import {Col, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {putUnidadeMedida, sendUnidadeMedida} from '../cadastro_requests/cadastro_requests';
import Swal from 'sweetalert2';
import {getUnidadeMedidaById} from '../../listagem/listagem_requests/listagem_requests';

const CadastroUnidade = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = {
    titulo: '',
    descricao: '',
    status: 'INATIVO',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroUnidadeMedidaValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      if (id) {
        try {
          await putUnidadeMedida(id, values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Unidade de medida editada com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de Unidade de Medida',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/produto-unidade-medida-listagem');
            },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          const response = await sendUnidadeMedida(values);

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Unidade de medida cadastrada com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de unidades de medida',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/produto-unidade-medida-listagem');
              },
            });
          }
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    getUnidadeMedidaDataById();
  }, []);

  const getUnidadeMedidaDataById = async () => {
    if (id) {
      try {
        const response = await getUnidadeMedidaById(id);

        const {data} = response;

        formik.setValues({
          titulo: data.titulo || '',
          descricao: data.descricao || '',
          status: data.status || 'ATIVO',
        });

        console.log(response);
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  return (
    <>
      <DescriptionComponent
        description={id ? 'Edição de Unidade de Medida' : 'Cadastro de Unidade de Medida'}
        withLink={true}
        buttonTitle='Listar Unidades de Medida'
        redirectTo='/produto-unidade-medida-listagem'
      />
      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row className='col-12 mb-4'>
          <Col className='col-sm-6'>
            <label className='form-label'>Titulo:</label>
            <input
              type='text'
              placeholder='Digite o título da unidade de medida'
              {...formik.getFieldProps('titulo')}
              className={`form-control ${
                formik.touched.titulo && formik.errors.titulo ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.titulo && formik.errors.titulo ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.titulo}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-6'>
            <div>
              <label className='form-label'>Status:</label>
              <select
                {...formik.getFieldProps('status')}
                className={`form-control ${
                  formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                }`}
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='col-12'>
          <Col className='col-6-sm'>
            <label className='form-label'>Descrição:</label>
            <textarea
              placeholder='Digite a descrição da unidade de medida'
              {...formik.getFieldProps('descricao')}
              className={`form-control ${
                formik.touched.descricao && formik.errors.descricao ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.descricao && formik.errors.descricao ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.descricao}
              </div>
            ) : null}
          </Col>
        </Row>
        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Unidade de Medida' : 'Cadastrar Unidade de Medida'}
          redirectTo='produto-unidade-medida-listagem'
        />
      </form>
    </>
  );
};

export default CadastroUnidade;
