export const items = [
  {
    id: 1,
    name: 'Dashboards',
    icon: 'ki-element-7',
    permissions: ['dashboardGerencial'],
    subMenu: [
      {
        id: 1,
        name: 'Comercial',
        permissions: ['dashboardGerencial'],
        subMnu: [
          {
            id: 1,
            name: 'Relatório de Vendas',
            permissions: ['dashboardGerencial'],
            path: '/relatorios/dashboards/comercial/relatorio-vendas',
          },
        ],
      },
    ],
  },

  {
    id: 2,
    name: 'Estoques',
    icon: 'ki-cube-2',
    permissions: ['estoqueConsulta'],
    subMenu: [
      {
        id: 1,
        name: 'Estoque (WMS)',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/consulta-estoque',
      },
    ],
  },

  {
    id: 3,
    name: 'Promoções/Encartes',
    icon: 'ki-discount',
    permissions: ['encartePromocional'],
    subMenu: [
      {
        id: 1,
        name: 'Criar Nova Promoção/Encarte',
        permissions: ['encartePromocional'],
        path: '/promocao-cadastro',
      },
      {
        id: 2,
        name: 'Pesquisar Promoção/Encarte',
        permissions: ['encartePromocional'],
        path: '/promocao-listagem',
      },
    ],
  },

  {
    id: 4,
    name: 'Vendas',
    icon: 'ki-shop',
    permissions: ['clientes', 'preVendas', 'parceiros'],
    subMenu: [
      {
        id: 1,
        name: 'Clientes',
        permissions: ['clientes'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['clientes'], path: '/cadastro-clientes'},
          {id: 2, name: 'Listar', permissions: ['clientes'], path: '/listagem-clientes'},
        ],
      },
      {
        id: 2,
        name: 'Propostas Comerciais',
        permissions: ['preVendas'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['preVendas'], path: '/propostas-comerciais'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['preVendas'],
            path: '/listagem-propostas-comerciais',
          },
        ],
      },
      {
        id: 3,
        name: 'Pedidos',
        permissions: ['preVendas'],
        subMnu: [{id: 1, name: 'Listar', permissions: ['preVendas'], path: '/listagem-pedidos'}],
      },
      {
        id: 4,
        name: 'Parceiros',
        path: '/vendas/profissionais',
        permissions: ['parceiros'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['parceiros'], path: '/cadastro-parceiros'},
          {id: 2, name: 'Listar', permissions: ['parceiros'], path: '/listagem-parceiros'},
        ],
      },
    ],
  },

  {
    id: 5,
    name: 'Logistica',
    icon: 'ki-delivery-2',
    permissions: ['logisticaExpedicao', 'logisticaFluxo'],
    subMenu: [
      {
        id: 1,
        name: 'Expedição',
        permissions: ['logisticaExpedicao'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaExpedicao'],
            path: '/logistica-expedicao-listagem',
          },
        ],
      },
      {
        id: 2,
        name: 'Fluxo Logístico',
        permissions: ['logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-fluxo-listagem',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-fluxo-cadastro',
          },
        ],
      },
      {
        id: 3,
        name: 'Fluxo Separação',
        permissions: ['logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-separacao-listagem',
          },
          {id: 2, name: 'Adicionar', permissions: ['logisticaFluxo'], path: '/logistica-separacao'},
        ],
      },
    ],
  },

  {
    id: 6,
    name: 'Workflow',
    icon: 'ki-element-1',
    subMenu: [
      {
        id: 1,
        name: 'Pendências',
        subMnu: [{id: 1, name: 'Listar', path: '/workflow-pendencia-listagem'}],
      },
    ],
  },

  {
    id: 7,
    name: 'Projetos',
    icon: 'ki-home-2',
    permissions: ['projetos'],
    subMenu: [
      {id: 1, name: 'Parceiro', permissions: ['projetos']},
      {id: 2, name: 'Etapas', permissions: ['projetos']},
      {id: 3, name: 'Épicos', permissions: ['projetos']},
    ],
  },

  {
    id: 8,
    name: 'Suprimentos',
    icon: 'ki-delivery-2',
    permissions: ['Produtos', 'suprimentos'],
    subMenu: [
      {id: 1, name: 'Ordem de Compra', permissions: ['suprimentos']},
      {id: 2, name: 'Ressuprimentos', permissions: ['suprimentos']},
      {
        id: 3,
        name: 'Produtos',
        permissions: ['Produtos'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['Produtos'], path: '/produto-cadastro'},
          {id: 2, name: 'Listar', permissions: ['Produtos'], path: '/produto-listagem'},
        ],
      },
      {
        id: 4,
        name: 'Categorização Produtos',
        permissions: ['Produtos'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['Produtos'], path: '/produto-categorizacao'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['Produtos'],
            path: '/produto-categorizacao-listagem',
          },
        ],
      },
    ],
  },

  {
    id: 9,
    name: 'Produção',
    icon: 'ki-briefcase',
    permissions: ['producao'],
    subMenu: [
      {id: 1, name: 'Ordem de Produção', permissions: ['producao']},
      {id: 2, name: 'Chão de Fábrica', permissions: ['producao']},
      {id: 3, name: 'Etapas', permissions: ['producao']},
      {id: 4, name: 'Eficiência de Produção', permissions: ['producao']},
      {id: 5, name: 'Parque/Capacidade', permissions: ['producao']},
    ],
  },

  {
    id: 10,
    name: 'Financeiro',
    icon: 'ki-dollar',
    permissions: ['financeiro'],
    subMenu: [
      {id: 1, name: 'Contas a Pagar', permissions: ['financeiro']},
      {id: 2, name: 'Contas a Receber', permissions: ['financeiro']},
      {id: 3, name: 'Grupo de Despesas', permissions: ['financeiro']},
    ],
  },
  // { id: 11, name: 'Sistema', icon: 'ki-setting-4', path: '/sistema' }
];
