import {useFormik} from 'formik';
import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchDataById} from '../services/FetchWorkflow';
import useOptionsFormularios from '../../selectOptions/useOptionsFormularios';
import clsx from 'clsx';
import {
  send_W_Aprovacao,
  update_W_Aprovacao,
} from '../../cadastros/cadastro_requests/cadastro_requests';
import '../style.css';
import {handleError, handleException, handleSuccess} from '../utils/Swal';

interface WorkflowAprovacaoType {
  id: number | undefined;
  idFormulario: string;
  titulo: string;
  status: string;
}

const WorkflowAprovacao = () => {
  const {id} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {options} = useOptionsFormularios();
  const [selectedFormulario, setSelectedFormulario] = useState<string>('');

  // const cadastroValidationSchema = Yup.object().shape({
  //   titulo: Yup.string().required('Por favor, informe um titulo para a solicitação'),
  //   id_formulario: Yup.string().required('Por favor, informe o formulário da solicitação'),
  //   status: Yup.string().required('Por favor, infome o status'),
  // });

  const initialValues: WorkflowAprovacaoType = {
    idFormulario: '',
    titulo: '',
    status: '',
    id: undefined,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    formik.setFieldValue(name, value);
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  function updateWorkflow(id, titulo, selectedFormulario, status) {
    return update_W_Aprovacao(id, titulo, selectedFormulario, status);
  }

  function createWorkflow(titulo, selectedFormulario, status) {
    return send_W_Aprovacao(titulo, selectedFormulario, status);
  }

  const formik = useFormik({
    initialValues,
    //validationSchema: cadastroValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        let response;

        if (id) {
          response = await updateWorkflow(
            id,
            values.titulo,
            selectedFormulario,
            Number(values.status)
          );
        } else {
          response = await createWorkflow(values.titulo, selectedFormulario, Number(values.status));
        }

        if (response.status === 201) {
          handleSuccess(
            id ? 'atualizada' : 'cadastrada',
            navigate,
            '/listagem-workflow-aprovacao',
            setSubmitting,
            setLoading
          );
        } else {
          handleError(setStatus, setSubmitting, setLoading);
        }
      } catch (error) {
        handleException(error, setSubmitting, setLoading);
      }
    },
  });

  const fetchAprovacao = async () => {
    if (id) {
      try {
        setLoading(true);
        const data = await fetchDataById<WorkflowAprovacaoType>('workflowaprovacao', id);

        if (data) {
          formik.setValues({
            ...formik.values,
            titulo: data.titulo,
            idFormulario: data.idFormulario,
            status: data.status,
          });

          if (data.idFormulario) {
            setSelectedFormulario(data.idFormulario);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAprovacao();
  }, [id]);

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className='row'>
        <div className='col-sm-5'>
          <h1 className='text-dark fw-bolder mb-5'>
            {id ? 'Edição de Workflow Aprovação' : 'Cadastro de Workflow Aprovação'}
          </h1>
        </div>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #000'}}></div>
        </div>
      </div>
      <div className='container-workflow mt-10 sm:gap-6'>
        <div
          className='col-sm-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Titulo da solicitação</label>
          <input
            type='text'
            placeholder='Insira o título'
            {...formik.getFieldProps('titulo')}
            value={formik.values.titulo}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.titulo && formik.errors.titulo},
              {
                'is-valid': formik.touched.titulo && !formik.errors.titulo,
              }
            )}
            onChange={handleChange}
          />
          {formik.touched.titulo && formik.errors.titulo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.titulo}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className='col-sm-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Selecione o formulário para aprovação</label>
          <select
            className={clsx(
              'form-select  bg-transparent',
              {'is-invalid': formik.touched.idFormulario && formik.errors.idFormulario},
              {
                'is-valid': formik.touched.idFormulario && !formik.errors.idFormulario,
              }
            )}
            placeholder='Selecione a página que a Permissão terá acesso'
            {...formik.getFieldProps('idFormulario')}
            value={selectedFormulario}
            onChange={(e) => {
              setSelectedFormulario(e.target.value);
              formik.setFieldValue('idFormulario', e.target.value);
            }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {formik.touched.idFormulario && formik.errors.idFormulario && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.idFormulario}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-sm-3'>
          <label className='form-label'>Status</label>
          <select
            className={clsx(
              'form-select  bg-transparent',
              {'is-invalid': formik.touched.status && formik.errors.status},
              {
                'is-valid': formik.touched.status && !formik.errors.status,
              }
            )}
            aria-label='Select example'
            value={formik.values.status}
            onChange={handleStatusChange}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'>Ativo</option>
            <option value='0'>Inativo</option>
          </select>
          {formik.touched.status && formik.errors.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.status}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex gap-8 mt-10'>
        <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
          {id ? 'Atualizar' : 'Cadastrar'}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn-cadastro btn-danger'
          id='back'
          onClick={() => navigate('/listagem-workflow-aprovacao')}
        >
          Voltar
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

export default WorkflowAprovacao;
