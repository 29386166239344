export const formatEndereco = (endereco) => {
  const enderecoParts = [
    endereco.endereco,
    endereco.enderecoNumero,
    endereco.enderecoComplemento,
    endereco.enderecoBairro,
    endereco.enderecoCidade,
    endereco.enderecoUF,
    endereco.cep,
    endereco.enderecoReferencia,
  ];

  return enderecoParts
    .filter(part => part && part.trim() !== '')
    .join(', ') || ' - ';
};