import { useEffect, useState } from "react";

interface ModalActionProps<T> {
  title?: string;
  isVisible?: boolean;
  toggleModal?: () => void;
  fetchItem: () => Promise<T>;
  renderContent: (item: T) => JSX.Element | null;
  resetFormik?: () => void; // Callback para reset do Formik
}

const ModalAction = <T extends {}>({
  title = "Modal com Ação",
  isVisible = false,
  toggleModal = () => { },
  fetchItem,
  renderContent,
resetFormik

}: ModalActionProps<T>) => {
  const [item, setItem] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const loadItem = async () => {
    setLoading(true);
    try {
      const fetchedItem = await fetchItem();
      setItem(fetchedItem);
    } catch (error) {
      console.error("Erro ao carregar item:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      loadItem();
    }
  }, [isVisible]);

  const handleCloseModal = () => {
    toggleModal();
    resetFormik?.(); // Reseta o formulário ao fechar
  };


  return (
    <div>
      {isVisible && (
        <div
          className="modal show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                {loading ? (
                  <p>Carregando...</p>
                ) : item ? (
                  renderContent(item)
                ) : (
                  <p>Erro ao carregar dados.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalAction;
