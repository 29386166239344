import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {Badge, Col, Row, Spinner} from 'react-bootstrap';
import {getProducts} from '../listagem_requests/listagem_requests';
import {useProduto} from '../../cadastros/produto/hooks/useProduto';
import img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg';
import {useFormik} from 'formik';
import {base64Prefix, smoothScrollToBottom} from '../../../shared/core';

interface Produto {
  id: number;
  img_produto_base64: string;
  codigo_sku: number;
  descricao: string;
  preco_venda: number;
  status: number;
  marca_id: string;
  marca_desc: string;
}

export function ListagemProduto() {
  const {setIdDoFormulario, navigate} = useProduto();
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = {
    page: '',
    size: '',
    idProduto: '',
    sku: '',
    descricao: '',
    marca: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);
      try {
        const response = await getProducts({
          descricao: values.descricao,
          idProduto: values.idProduto,
          marca: values.marca,
          sku: values.sku,
          page: 0,
          size: '1000',
        });

        const {content, totalPages} = response.data;

        setIsHasMore(isPage < totalPages - 1);

        setIsLoadingSearch(false);
        setSubmitting(false);
        setProdutos(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setSubmitting(false);
        setIsLoadingSearch(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(400);
  };

  const getProductsData = async (page: number = 0) => {
    setIsLoading(true);
    try {
      setLoading(true);
      const response = await getProducts({
        descricao: '',
        marca: '',
        size: '10',
        idProduto: '',
        sku: '',
        page: page,
      });

      const {content, totalPages} = response.data;

      setProdutos((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar produtos',
        text: 'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductsData(isPage);
  }, [isPage]);

  const formatStatus = (statusProduto) => {
    switch (statusProduto) {
      case 1:
        return (
          <Badge bg='success' text='light'>
            ATIVO
          </Badge>
        );
      case 2:
        return (
          <Badge bg='danger' text='light'>
            INATIVO
          </Badge>
        );
      case 9:
        return (
          <Badge bg='warning' text='light'>
            SINCRONIZADO
          </Badge>
        );
      default:
        return (
          <Badge bg='light' text='light'>
            N/A
          </Badge>
        );
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
  };

  const handleEditProduto = (id) => {
    setIdDoFormulario(id);
    navigate(`/produto-edicao/${id}`);
  };

  const onClickNovoCadastro = () => {
    setIdDoFormulario(null);
    navigate('/produto-cadastro');
  };

  const clearFilter = () => {
    getProductsData(0);
    formik.setValues({
      descricao: '',
      idProduto: '',
      marca: '',
      sku: '',
      page: '',
      size: '',
    });
  };

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Produtos
        </h1>
        <button
          onClick={onClickNovoCadastro}
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Novo Produto
        </button>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <Row className='col-12 mb-4'>
            <Col md='3' className='col-12 mt-2'>
              <label className='form-label'>Id do Produto:</label>
              <input
                type='text'
                placeholder='Pesquise pelo id do produto'
                {...formik.getFieldProps('idProduto')}
                className='form-control'
              />
            </Col>
            <Col md='3' className='col-12 mt-2'>
              <label className='form-label'>Código do Produto SKU:</label>
              <input
                type='text'
                placeholder='Pesquise pelo código de SKU'
                {...formik.getFieldProps('sku')}
                className='form-control'
              />
            </Col>
            <Col md='3' className='col-12 mt-2'>
              <label className='form-label'>Descrição do Produto:</label>
              <input
                type='text'
                placeholder='Pesquise pela descrição do produto'
                {...formik.getFieldProps('descricao')}
                className='form-control'
              />
            </Col>
            <Col md='3' className='col-12 mt-2'>
              <label className='form-label'>Marca do Produto:</label>
              <input
                type='text'
                placeholder='Pesquise pela marca do produto'
                {...formik.getFieldProps('marca')}
                className='form-control'
              />
            </Col>
          </Row>
          <div className='col-12 mt-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFilter()}
                className='btn btn-success form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-success form-control'
              >
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Imagem</th>
                <th className='text-center'>Descrição</th>
                <th className='text-center'>Cod. Fornecedor(SKU)</th>
                <th className='text-center'>Marca</th>
                <th className='text-center'>Preço Venda</th>
                <th className='text-center'>Ação</th>
              </tr>
            </thead>
            <tbody>
              {produtos.map((produto) => (
                <tr key={produto.id}>
                  <td className='text-center'>{produto.id}</td>
                  <td className='text-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <img
                        src={
                          produto.img_produto_base64
                            ? `${base64Prefix},${produto.img_produto_base64}`
                            : img_not_found
                        }
                        title={produto.descricao}
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '4px',
                          boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.4)',
                        }}
                      />
                    </div>
                  </td>
                  <td className='text-center'>
                    <div>{produto.descricao ? produto.descricao : 'nada'}</div>
                    <div>{formatStatus(produto.status)}</div>
                  </td>
                  <td className='text-center'>{produto.codigo_sku}</td>
                  <td className='text-center'>{produto.marca_id ? produto.marca_desc : '...'}</td>
                  <td className='text-center'>R$ {produto.preco_venda}</td>
                  <td className='text-center'>
                    <button
                      onClick={() => handleEditProduto(produto.id)}
                      className='btn btn-success btn-sm bi bi-pencil'
                      data-toggle='tooltip'
                      data-placement='top'
                      title={'Editar'}
                    ></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
