import axios from 'axios';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export async function fetchOptionsAmbienteGlobal() {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosAmbientesPrevenda`
    );
    const data = response.data;
    const formattedOptions = data.map((item) => ({
      value: item.idAmbiente,
      label: item.ambiente,
    }));
    localStorage.setItem('optionsAmbiente', JSON.stringify(formattedOptions));
  } catch (error) {
    console.error('Erro ao buscar ambientes:', error);
  }
}

export function getOptionsAmbientesFromStorage() {
  const storedOptions = localStorage.getItem('optionsAmbiente');
  return storedOptions ? JSON.parse(storedOptions) : [];
}
