type Props = {
  durationScroll?: number
}

export const smoothScrollToBottom = (durationScroll?: number) => {
  const scrollHeight = document.documentElement.scrollHeight;
  const currentScroll = window.scrollY;
  const distance = scrollHeight - currentScroll;
  const duration = durationScroll ? durationScroll : 700;
  let startTime = null;

  function animationStep(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime!;
    const percent = Math.min(progress / duration, 1);
    window.scrollTo(0, currentScroll + percent * distance);
    if (progress < duration) {
      requestAnimationFrame(animationStep);
    }
  }

  requestAnimationFrame(animationStep);
};

export const smoothScrollToTop = () => {
  const currentScroll = window.scrollY;
  const duration = 700;
  let startTime = null;

  function animationStep(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime!;
    const percent = Math.min(progress / duration, 1);
    window.scrollTo(0, currentScroll - percent * currentScroll);
    if (progress < duration) {
      requestAnimationFrame(animationStep);
    }
  }

  requestAnimationFrame(animationStep);
};
