import { useEffect, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { ProductOption } from '../../../pages/cadastros/proposta-pedido/types/interface';
import { ICliente, ISelectOption } from '../../domain-types';
import './async-select-cliente-styles.scss';
import { formatEndereco, truncateNome } from '../../../shared/core/adapters';

import useBuscaClienteCredito from '../../../pages/useUtils/useBuscaClienteCredito';

type IProps = {
  handleOptions?: (product: ProductOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectCliente: React.FC<IProps> = ({ handleOptions, inputOption, isDisabled, onKeyDown }) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedClienteAsync, setSelectClienteAsync] = useState<ISelectOption | null>(null);
  const [clienteDetails, setClienteDetails] = useState<ICliente[]>([]);

  const buscarSaldoCliente = useBuscaClienteCredito();

  const loadOptionCliente = async (inputValue: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaClienteNome/${inputValue}`;
      const response = await axios.get(url);
      const data = await response.data;

      setClienteDetails(data);

      return data.map((item) => ({
        value: item.idCliente,
        label: item.clienteFantasia || 'SEM DADOS',
      }));

    } catch (error) {
      console.error('Erro ao carregar opções de cliente:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const cliente = clienteDetails?.find(cliente => cliente.idCliente === String(option.value));

    if (cliente) {
      (document.getElementById('razaosocialDestino') as HTMLInputElement).value = (cliente.clienteFantasia || '').toUpperCase();
      (document.getElementById('cnpjDestino') as HTMLInputElement).value = cliente.cnpj || '';
      (document.getElementById('telefoneDestino') as HTMLInputElement).value = cliente.telefone || '';
      (document.getElementById('cepDestino') as HTMLInputElement).value = cliente.cep || '';
      (document.getElementById('enderecoDestino') as HTMLInputElement).value = (cliente.endereco || '').toUpperCase();
      (document.getElementById('bairroDestino') as HTMLInputElement).value = (cliente.enderecoBairro || '').toUpperCase();
      (document.getElementById('cidadeDestino') as HTMLInputElement).value = (cliente.enderecoCidade || '').toUpperCase();
      (document.getElementById('estadoDestino') as HTMLInputElement).value = (cliente.enderecoUF || '').toUpperCase();
      (document.getElementById('pontoReferenciaDestino') as HTMLInputElement).value = (cliente.enderecoReferencia || '').toUpperCase();
      (document.getElementById('complementoDestino') as HTMLInputElement).value = (cliente.enderecoComplemento || '').toUpperCase();
      (document.getElementById('numeroDestino') as HTMLInputElement).value = cliente.enderecoNumero || '';

      return (
        <div className='info-container cliente'>
          <p><span>{truncateNome(cliente.clienteFantasia) || ' - '}</span></p>
          <div className='columns'>
            <div className='column left'>
              <p>CPF: <span>{cliente.cnpj || ' - '}</span></p>
              <p>Endereço: <span>{formatEndereco(cliente)}</span></p>
              <p>Telefone: <span>{cliente.telefone || ' - '}</span></p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedClienteAsync && handleOptions) {
      handleOptions(selectedClienteAsync);
    }
  }, [selectedClienteAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionCliente(inputOption.value);
      setSelectClienteAsync(inputOption);
      buscarSaldoCliente(inputOption.value);
    } else {
      setSelectClienteAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Cliente'
      loadOptions={loadOptionCliente}
      value={selectedClienteAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectClienteAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
    />
  );
};

export default AsyncSelectCliente;
