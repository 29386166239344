import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
// import ReCAPTCHA from 'react-google-recaptcha';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(1, 'O campo não pode ser vazio')
    .required('Por favor, informe o login do usuário'),
  password: Yup.string()
    .min(8, 'Mínimo de 8 caracteres')
    .max(50, 'Maximum 50 symbols')
    .required('Por favor, informe a senha'),
});

const initialValues = {
  email: '',
  password: '',
};

const SYSLED_VERSION = process.env.REACT_APP_SYSLED_VERSION;

export function Login() {
  // const [recaptchaVal, setrecaptchaVal] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  // const [recaptchaToken, setRecaptchaToken] = useState('');
  // const [submitEnabled, setSubmitEnabled] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        //VERIFICAR CAPTCHA ANTES DE REALIZAR LOGIN//
        const { data: auth } = await login(values.email, values.password);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setCurrentUser(user);
        window.location.reload();
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);

        const erroResponse = error.response;
        const errorStatus = error.response.status;

        if (erroResponse) {
          if (errorStatus === 401) {
            setStatus(erroResponse.data.message);
          } else if (errorStatus === 403 || errorStatus === 400) {
            setStatus(
              'Informações de login incorretas! Em caso de dúvidas entre em contato com a T.I'
            );
          } else if (errorStatus === 404) {
            setStatus('Usuário sem Empresa Padrão definida! Entre em contato com a T.I');
          }
        }

        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form className='form' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-6'>Entrar</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Bem vindo! <br /> Informe seu login e senha para acessar o sistema
        </div>
      </div>
      {/* end::Heading */}
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text text-center font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}
      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Login</label>
        <input
          placeholder='Informe seu login'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
        <input
          placeholder='Informe sua senha'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      {/* <div className='mb-10 d-flex justify-content-center'>
        <ReCAPTCHA
          sitekey={'6LdNeqYpAAAAALRNaScFY03zQAIj9CCSdlYm6R4d'}
          // callback = {handleToken}
          onChange={(val) => setrecaptchaVal(val)}
        />
      </div> */}
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10 text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='mt-10'>
          <span>versão {SYSLED_VERSION}</span>
        </div>
      </div>
      {/* end::Action */}
    </form>
  );
}
