import React from "react";
import {CadastroEmpresas} from "./CadastroEmpresa"
import { PageTitle } from "../../../../_sysled/layout/core";
import { FC } from "react";

const FormCadEmpresa: FC = () => (
    <>
     {/* <PageTitle>Cadastro de Empresa</PageTitle> */}
     <CadastroEmpresas></CadastroEmpresas>
    </>
)

export {FormCadEmpresa};