import { useEffect, useState } from 'react';
import { DescriptionComponent, FooterForm } from '../../../shared/components';
import { useFormik } from 'formik';
import { cadastroFormularioValidationSchema } from '../../../shared';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editFormulario,
  getFormularios,
  sendFormulario,
} from '../cadastro_requests/cadastro_requests';
import Swal from 'sweetalert2';

const CadastroFormularios = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = {
    arquivo: '',
    descricao: '',
    status: 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroFormularioValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);

      if (id) {
        console.log('edit action');
        try {
          await editFormulario(id, values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Formulário editado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de formulários',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/formularios-listagem');
            },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          const response = await sendFormulario(values);
          console.log('cadastro action');

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Formulário cadastrado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de formulários',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/formularios-listagem');
              },
            });
          }
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    getForms();
  }, []);

  const getForms = async () => {
    if (id) {
      try {
        const response = await getFormularios(id);

        const { data } = response;

        formik.setValues({
          arquivo: data.arquivo || '',
          descricao: data.descricao || '',
          status: data.status || 0,
        });

        console.log(response);
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  return (
    <>
      <DescriptionComponent
        description={id ? 'Edição de Formulários' : 'Cadastro de Formulários'}
        withLink={true}
        buttonTitle='Listar Formulários'
        redirectTo='/formularios-listagem'
      />
      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row className='col-12 mb-4'>
          <Col className='col-sm-6'>
            <label className='form-label'>Arquivo:</label>
            <input
              type='text'
              placeholder='Digite o arquivo do formulário'
              {...formik.getFieldProps('arquivo')}
              className={`form-control ${formik.touched.arquivo && formik.errors.arquivo ? 'is-invalid' : ''
                }`}
            />
            {formik.touched.arquivo && formik.errors.arquivo ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.arquivo}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-6'>
            <div>
              <label className='form-label'>Status:</label>
              <select
                {...formik.getFieldProps('status')}
                className={`form-control ${formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
              >
                <option value='1'>Ativo</option>
                <option value='0'>Desativado</option>
              </select>
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='col-12'>
          <Col className='col-6-sm'>
            <label className='form-label'>Descrição:</label>
            <textarea
              placeholder='Digite a descrição do formulário'
              {...formik.getFieldProps('descricao')}
              className={`form-control ${formik.touched.descricao && formik.errors.descricao ? 'is-invalid' : ''
                }`}
            />
            {formik.touched.arquivo && formik.errors.arquivo ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.descricao}
              </div>
            ) : null}
          </Col>
        </Row>
        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Formulário' : 'Cadastrar Formulário'}
          redirectTo='formularios-listagem'
        />
      </form>
    </>
  );
};

export default CadastroFormularios;
