/* eslint-disable eqeqeq */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Column, ColumnInstance, Row, useTable } from 'react-table';
import Swal from 'sweetalert2';
import { ISkuResumo } from '../../../../shared';
import { getResumoSku } from '../../../listagem/listagem_requests/listagem_requests';
import { CustomRow } from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import './table-produto-proposta-styles.scss';

type Props = {
  className: string;
  infosProd: ProdutoInfo[];
  setInfosProd: any;
  selectedProductDetails: ProdutoDetalhes | null;
  onRemoveProduct: (index: number) => void;
  onEditProduct: (e: any, infoProd: ProdutoInfo) => void;
  totalGeral: number;
  valorAReceber: number;
  setOrdenacao: (ordenacao: number) => void;
  ordenacao: number;
  setUpdateProduto: (update: boolean) => void;
  handleChangeSKU: (e: any, infoProd: ProdutoInfo) => void;
  idPedido?: number;
  isFluxoLogistico?: boolean;
};

interface ProdutoDetalhes {
  quantidade: number;
}

interface ProdutoInfo {
  ambiente: string;
  ordem: number;
  idUnico: number;
  idProduto: string;
  produto: string;
  idloja: number | null;
  idprevenda: string;
  descloja: string;
  precoPadrao: string | null;
  precoUnitario: string | null;
  precoCusto: number | null;
  margem: number | null;
  encarte: string;
  qtdProduto: string;
  imagem: string;
  dataprev: string;
  qtdComprado: string;
  estoqueTotal: number;
  date: string;
  hashDate: string;
  estoque: Estoque[];
  quantidade: number;
  total: number;
  ambienteDesc: string;
  ambienteId: number | null;
  ambienteComplemento: string;
  tipoEntregaDesc: string;
  tipoEntregaId: number | null;
  unidade: string;
  marca: string;
  tipoProduto: string;
  descontoProduto: number | null;
  obsproduto: string;
  obsStatusPrd: boolean;
  idTabelaDePrecoProduto: number | null;
  descTabelaDePrecoProduto: string;
  id?: number;
  ativaMargem: number | null;
  estoqueRestante: number;
}

interface Estoque {
  wms: string;
  CodigoProduto: number;
  EstoqueProduto: number;
}

const formatObs = (obsproduto) => {
  if (obsproduto) {
    return (
      <Badge bg='info' style={{ color: 'white' }}>
        {obsproduto}
      </Badge>
    );
  }
};

export const TableProdutosPrevenda: React.FC<Props> = ({
  className,
  infosProd,
  setInfosProd,
  onRemoveProduct,
  onEditProduct,
  totalGeral,
  valorAReceber,
  setOrdenacao,
  ordenacao,
  setUpdateProduto,
  handleChangeSKU,
  idPedido,
  isFluxoLogistico
}) => {
  const [search, setSearch] = useState('');
  const [filteredProdutos, setFilteredProdutos] = useState<ProdutoInfo[]>([]);
  const [mostrarEstoqueInsuficiente, setMostrarEstoqueInsuficiente] = useState(0);
  const [selectedProduto, setSelectedProduto] = useState<string>('');
  const [valor, setValor] = useState<string>('');

  const [mostrarSemEstoque, setMostrarSemEstoque] = useState(false);
  const [ambientesPorProdutos, setAmbientesPorProdutos] = useState(false);

  useEffect(() => {
    if (ordenacao === 1) {
      sortInfosProd(1);
    } else if (ordenacao === 0) {
      sortInfosProd(0);
    }
  }, [ordenacao]);

  const handleToggleSemEstoque = () => {
    setMostrarSemEstoque(!mostrarSemEstoque);
  };


  const handleToggleAmbientesPorProduto = () => {
    setAmbientesPorProdutos(!ambientesPorProdutos);
  };

  const handleProdutoChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduto(event.target.value);
  };

  const handleValorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/[^0-9.,]/g, '');
    setValor(numericValue);
  };

  const handleVoltarPreco = () => {
    setUpdateProduto(true);
    const selectedInfoProd = infosProd.find((prod) => prod.idProduto === selectedProduto);

    if (selectedInfoProd && selectedInfoProd.precoPadrao) {
      const updatedInfosProd = infosProd.map((prod) =>
        prod.idProduto === selectedProduto
          ? {
            ...prod,
            precoUnitario: prod.precoPadrao,
            precoLiquido: prod.precoPadrao
              ? parseFloat(prod.precoPadrao) * (1 - (prod.descontoProduto ?? 0) / 100)
              : 0,
            total:
              prod.quantidade *
              ((parseFloat(prod.precoPadrao ?? '0') ?? 0) *
                (1 - (prod.descontoProduto ?? 0) / 100)),
            margem: calculoMargem(prod.total, prod.precoCusto, prod.quantidade),
          }
          : prod
      );

      setInfosProd(updatedInfosProd);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Valor padrão aplicado ao produto selecionado com sucesso!',
      });

      setSelectedProduto('');
      setValor('');
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'error',
        title: 'Selecione um Produto para voltar preço padrão.',
      });
      return;
    }
  };

  const handleAplicarTodos = () => {
    setUpdateProduto(true);
    const selectedInfoProd = infosProd.find((prod) => prod.idProduto === selectedProduto);
    const valorFormatado = valor.replace(',', '.');

    if (
      selectedInfoProd &&
      selectedInfoProd.precoPadrao &&
      parseFloat(valorFormatado) >= parseFloat(selectedInfoProd.precoPadrao || '0')
    ) {
      const updatedInfosProd = infosProd.map((prod) =>
        prod.idProduto === selectedProduto
          ? {
            ...prod,
            precoUnitario: valorFormatado,
            precoLiquido: parseFloat(valorFormatado) * (1 - (prod.descontoProduto ?? 0) / 100),
            total:
              prod.quantidade *
              (parseFloat(valorFormatado) * (1 - (prod.descontoProduto ?? 0) / 100)),
            margem: calculoMargem(prod.total, prod.precoCusto, prod.quantidade),
          }
          : prod
      );
      setInfosProd(updatedInfosProd);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Valor aplicado ao produto selecionado com sucesso!',
      });

      setSelectedProduto('');
      setValor('');
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'error',
        title: 'O preço unitário não pode ser menor que o preço padrão.',
      });
      return;
    }
  };

  const handleRemoverAmbiente = () => {
    setUpdateProduto(true);

    const updatedInfosProd = infosProd.map((prod) =>
      selectedProduto === "todos" || prod.ambienteId === Number(selectedProduto)
        ? {
          ...prod,
          ambiente: '',
          ambienteComplemento: '',
          ambienteDesc: '',
          ambienteId: null,
        }
        : prod
    );

    Swal.fire({
      icon: 'info',
      title: 'Informação',
      text: selectedProduto === "todos"
        ? 'Você tem certeza que deseja remover o ambiente de todos os produtos?'
        : 'Você tem certeza que deseja remover o ambiente do produto selecionado?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Removido!', 'O ambiente foi removido com sucesso.', 'success');
        setInfosProd(updatedInfosProd);
        setSelectedProduto('');
      } else {
        Swal.fire('Cancelado!', 'Ação cancelada.', 'error');
      }
    });
  };



  const uniqueProdutos: ProdutoInfo[] = infosProd.reduce((acc: ProdutoInfo[], current) => {
    const x = acc.find((item) => item.idProduto === current.idProduto);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const uniqueAmbiente: ProdutoInfo[] = infosProd
    .reduce((acc: ProdutoInfo[], current) => {
      const exists = acc.some(item => item.ambienteId === current.ambienteId);
      if (!exists && current.ambienteId !== null) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

  const includesInNormalized = (search: string, element: string): boolean => {
    return element
      ?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        search
          ?.toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      );
  };

  useEffect(() => {
    if (infosProd) {
      setFilteredProdutos(infosProd);
    }
  }, [infosProd]);

  useEffect(() => {
    if (search !== '') {
      setFilteredProdutos(
        infosProd?.filter(
          (produto: any) =>
            includesInNormalized(search, produto.produto) ||
            produto.idProduto.toString().includes(search) ||
            includesInNormalized(search, produto.ambienteDesc)
        )
      );
    } else {
      setFilteredProdutos(infosProd);
    }
  }, [search]);

  function fomatarPrecoUnitario(precoUnitario) {
    const precoUnit = parseFloat(precoUnitario ?? '0').toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `R$${precoUnit}`;
  }

  function formatarPreco(preco) {
    const precoComDuasCasas = Number(preco).toFixed(2);

    const precoPtBr = Number(precoComDuasCasas).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `R$${precoPtBr} `;
  }

  function formatarPrecoDesconto(preco, desconto) {
    const precoComDesconto = preco - preco * (desconto / 100);

    const precoPtBr = Number(precoComDesconto).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `R$${precoPtBr} `;
  }

  function formatarPrecoSemCifra(preco) {
    const precoPtBr = Number(preco).toLocaleString('pt-BR');
    return `${precoPtBr} `;
  }

  function removePrefix(str: string): string {
    const index = str.indexOf(' - ');
    if (index !== -1) {
      return str.substring(index + 3);
    }
    return str;
  }

  const formatarEstoque = (estoque) => {
    if (!estoque) return '0';
    const wms = estoque.wms || '';
    const quantidade = estoque.EstoqueProduto !== null ? Math.floor(estoque.EstoqueProduto) : '0';
    return `${wms}: ${quantidade} `;
  };

  function handleSort() {
    // sortInfosProd(2)
    const infosProdClone = [...infosProd];
    const temp = infosProdClone[dragProduto.current];
    infosProdClone[dragProduto.current] = infosProdClone[draggedOverProduto.current];
    infosProdClone[draggedOverProduto.current] = temp;
    setInfosProd(infosProdClone);
  }

  const dragProduto = useRef<number>(0);
  const draggedOverProduto = useRef<number>(0);

  function handleTouchStart(index: number) {
    dragProduto.current = index;
  }

  function handleTouchMove(index: number) {
    draggedOverProduto.current = index;
  }

  function handleTouchEnd() {
    handleSort();
  }

  function truncarDuasCasasDecimais(numero: number): number {
    const fator = Math.pow(10, 2);
    return Math.floor(numero * fator) / fator;
  }

  const calculoMargem = (valorVenda, valorCusto, quantidade) => {
    if (infosProd) {
      const precoVenda = valorVenda;
      const precoCusto = valorCusto * quantidade;

      const diferencaPrecos = precoVenda - precoCusto;
      const calculoMargem = (diferencaPrecos / precoVenda) * 100;
      const valorTruncado = truncarDuasCasasDecimais(calculoMargem);

      return valorTruncado;
    }
  };

  const sortInfosProd = (int) => {
    setOrdenacao(int);

    const sortedInfosProd = [...infosProd];

    if (int === 0) {
      sortedInfosProd.sort((a, b) => {
        const aOrdem = Number(a.ordem) || Number.MAX_SAFE_INTEGER;
        const bOrdem = Number(b.ordem) || Number.MAX_SAFE_INTEGER;

        return aOrdem - bOrdem;
      });
    } else if (int === 1) {
      sortedInfosProd.sort((a, b) => {
        const aAmbiente = a.ambiente ? a.ambiente.toLowerCase().trim() : '';
        const bAmbiente = b.ambiente ? b.ambiente.toLowerCase().trim() : '';

        const aComplemento = a.ambienteComplemento
          ? a.ambienteComplemento.toLowerCase().trim()
          : '';
        const bComplemento = b.ambienteComplemento
          ? b.ambienteComplemento.toLowerCase().trim()
          : '';

        const aConcat = `${aAmbiente} ${aComplemento}`.trim();
        const bConcat = `${bAmbiente} ${bComplemento}`.trim();

        if (!aConcat && !bConcat) return 0;
        if (!aConcat) return 1;
        if (!bConcat) return -1;

        return aConcat.localeCompare(bConcat);
      });
    }

    setInfosProd(sortedInfosProd);
  };

  const handleEstoque = () => {
    setMostrarEstoqueInsuficiente((prevState) => (prevState === 0 ? 1 : 0));
  };

  useEffect(() => {
    if (mostrarEstoqueInsuficiente) {
      setFilteredProdutos(
        infosProd?.filter((produto) => produto.estoqueTotal < produto.quantidade)
      );
    } else {
      setFilteredProdutos(infosProd);
    }
  }, [mostrarEstoqueInsuficiente]);

  const handleRemoveProduct = (ordem) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Não, cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        setUpdateProduto(true);
        onRemoveProduct(ordem);
        Swal.fire('Removido!', 'O produto foi removido com sucesso.', 'success');
      }
    });
  };

  //Regras de negócios referente ao resumo de SKU

  const [isSkuResumoData, setIsResumoSkuData] = useState<ISkuResumo[]>([]);
  const [isActiveSection, setIsActiveSection] = useState('lancamentoProduto');
  const [isInnerFlow, setIsInnerFlow] = useState<boolean | string>(false)

  const resumoSkuColumns: Column<ISkuResumo>[] = [
    {
      Header: 'Id do Produto',
      accessor: 'idproduto',
      Cell: ({ value }) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      Cell: ({ value, row }) => (
        <div
          className='text-start'
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          {value}

          {row.original.isProdutoParcial ? (
            <Badge style={{ color: '#fff', marginTop: '10px' }} bg='info'>
              PARCIAL
            </Badge>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      Header: 'Tipo de Entrega',
      accessor: 'tipoEntrega',
      Cell: ({ value }) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Qtd',
      accessor: 'quantidade',
      Cell: ({ value }) => <div className='text-end'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Qtd Parcial',
      accessor: 'quantidadeParcialSku',
      Cell: ({ value }) => <div className='text-end'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Saldo',
      accessor: 'saldo',
      Cell: ({ value }) => (
        <div className='text-end'>
          {value ? value : '...'}
        </div>
      ),
    },
    {
      Header: 'Total em Estoque',
      accessor: 'totalEstoque',
      Cell: ({ value }) => (
        <div className='text-end' style={{ marginRight: '10px' }}>
          {value ? value : '...'}
        </div>
      ),
    },
  ];

  const data = useMemo(() => isSkuResumoData, [isSkuResumoData]);
  const columns = useMemo(() => resumoSkuColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  //Get de Resumo de Sku
  const getResumoSkuData = async (id: number) => {
    try {
      const response = await getResumoSku(id);

      const { data } = response;

      setIsResumoSkuData(data);
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });
    }
  };

  //Action de Get de Resumo SKU
  useEffect(() => {
    if (idPedido) {
      getResumoSkuData(idPedido);
    }
  }, [isActiveSection]);

  //Verificação de id pedido para set de section
  useEffect(() => {
    if (Number.isNaN(idPedido) || idPedido == null || idPedido == undefined) {
      setIsActiveSection('lancamentoProduto');
    }
  }, [idPedido]);

  useEffect(() => {
    if (isFluxoLogistico) {
      setIsInnerFlow(isFluxoLogistico)
    }
  }, [isFluxoLogistico])

  return (
    <>
      <div className='container-nav'>
        <div className='btn-group custom-nav-tabs' role='group'>
          <button
            type='button'
            className={`btn btn-outline-secondary ${isActiveSection === 'lancamentoProduto' ? 'active' : ''
              }`}
            onClick={() => setIsActiveSection('lancamentoProduto')}
          >
            Lançamento de Produtos
          </button>
          <button
            disabled={Number.isNaN(idPedido) || !idPedido || idPedido == undefined || isInnerFlow === 'false' || !isInnerFlow}
            type='button'
            className={`btn btn-outline-secondary ${isActiveSection === 'consumoEstoque' ? 'active' : ''
              }`}
            onClick={() => setIsActiveSection('consumoEstoque')}
          >
            Resumo de SKU / Consumo de Estoque
          </button>
        </div>
      </div>

      {isActiveSection == 'lancamentoProduto' ? (
        <div className={`card ${className} `}>
          <div className='card-body py-3'>
            <div className='row'>
              <div className='col-md-3'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'none' : 'block'
                }}>
                <select
                  className='form-control'
                  value={selectedProduto}
                  onChange={handleProdutoChange}
                >
                  <option value=''>Selecione o Produto</option>
                  {uniqueProdutos.map((produto) => (
                    <option key={produto.idProduto} value={produto.idProduto}>
                      {produto.idProduto} - {produto.produto}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'block' : 'none'
                }}>
                <select
                  className='form-control'
                  value={selectedProduto}
                  onChange={handleProdutoChange}
                >
                  <option value=''>Selecione o Ambiente</option>
                  <option value="todos">TODOS</option>
                  {uniqueAmbiente.map((produto) => (
                    <option key={produto.ambienteId} value={produto.ambienteId ?? ''}>
                      {produto.ambienteDesc}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-2'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'none' : 'block'
                }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Digite o valor'
                  value={valor}
                  onChange={handleValorChange}
                />
              </div>

              <div className='col-md-2'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'none' : 'block'
                }}>
                <button
                  className='btn btn-info w-100 todos-produtos'
                  onClick={handleAplicarTodos}
                  type='button'
                  disabled={
                    totalGeral > 0 && valorAReceber <= 0
                      ? true
                      : false || (selectedProduto === '' && valor === '')
                  }
                >
                  Aplicar a Todos
                </button>
              </div>
              <div className='col-md-2'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'none' : 'block'
                }}>
                <button
                  className='btn btn-info w-100 todos-produtos'
                  onClick={handleVoltarPreco}
                  type='button'
                  disabled={
                    totalGeral > 0 && valorAReceber <= 0
                      ? true
                      : false || (selectedProduto === '' && valor !== '')
                  }
                >
                  Voltar preço padrão
                </button>
              </div>
              <div className='col-md-2'
                style={{
                  marginTop: '20px',
                  display: ambientesPorProdutos === true ? 'block' : 'none'
                }}>
                <button
                  className='btn btn-info w-100 todos-produtos'
                  onClick={handleRemoverAmbiente}
                  type='button'
                  disabled={
                    totalGeral > 0 && valorAReceber <= 0
                      ? true
                      : false || (selectedProduto === '' && valor !== '')
                  }
                >
                  Remover Ambiente
                </button>
              </div>
              <div className='col-md-1' style={{ marginTop: '10px' }}>
                <label htmlFor='' className='form-label' style={{ marginRight: '10px' }}>
                  {ambientesPorProdutos ? 'Produtos' : 'Ambientes'}
                </label>

                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckProcura'
                    checked={ambientesPorProdutos}
                    onChange={handleToggleAmbientesPorProduto}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckProcura'>
                    <b>{ambientesPorProdutos ? 'Sim' : 'Não'}</b>
                  </label>
                </div>
              </div>
              <div className='col-md-2' style={{ display: ambientesPorProdutos === true ? 'block' : 'none' }}></div>
              <div className='col-md-2' style={{ display: ambientesPorProdutos === true ? 'block' : 'none' }}></div>
              <div
                className='col-md-2 dropdown'
                style={{
                  marginTop: '20px', display: 'flex', justifyContent: 'flex-end'
                }}
              >
                <button
                  type='button'
                  className='btn btn-warning dropdown-toggle'
                  data-bs-toggle='dropdown'
                >
                  Ordenar por:
                </button>

                <ul className='dropdown-menu' style={{ zIndex: 50 }}>
                  <li>
                    <button
                      className='dropdown-item'
                      type='button'
                      onClick={() => {
                        sortInfosProd(0);
                        setUpdateProduto(true);
                      }}
                    >
                      INSERÇÃO
                    </button>
                  </li>

                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <button
                      className='dropdown-item'
                      type='button'
                      onClick={() => {
                        sortInfosProd(1);
                        setUpdateProduto(true);
                      }}
                    >
                      AMBIENTE
                    </button>
                  </li>
                </ul>
              </div>

              <div
                className='col-md-7'
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}
              >
                <input
                  className='form-control'
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    setSearch(e.target.value)
                  }
                  placeholder={`Pesquisar por: ID do Produto, Nome Produto, Ambiente`}
                />
              </div>

              <div
                className='col-md-4'
                style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
              >
                <label htmlFor='' className='form-label' style={{ marginRight: '10px' }}>
                  Mostrar produto sem estoque
                </label>

                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckProcura'
                    checked={mostrarSemEstoque}
                    onChange={handleToggleSemEstoque}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckProcura'>
                    <b>{mostrarSemEstoque ? 'Sim' : 'Não'}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                <thead>
                  <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                    <th className='p-0 pb-3 text-center'></th>
                    <th className='p-0 pb-3 text-center'>ID</th>
                    <th className='p-0 pb-3 text-center'>SKU</th>
                    <th className='p-0 pb-3 text-center'>Produto</th>
                    <th className='p-0 pb-3 text-center'>Qtd</th>
                    <th className='p-0 pb-3 text-center' style={{ width: '150px' }}>
                      Estoque
                    </th>
                    <th className='p-0 pb-3 text-center'>Preço Unit.</th>
                    <th className='p-0 pb-3 text-center'>Desc %</th>
                    <th className='p-0 pb-3 text-center'>Preço Liq.</th>
                    <th className='p-0 pb-3 text-center'>Total</th>
                    <th className='p-0 pb-3 text-center'>Margem</th>
                    <th className='p-0 pb-3 text-center'>Tipo Entrega</th>
                    <th className='p-0 pb-3 text-center'>Ambiente </th>
                    <th className='p-0 pb-3 text-center'>Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {(() => {
                    const estoquePorId = new Map<
                      number,
                      {
                        estoqueTotal: number;
                        quantidadeConsumidaPorUnico: Map<number, number>;
                        quantidadeConsumida?: number;
                      }
                    >();

                    filteredProdutos.forEach((produto) => {
                      if (!estoquePorId.has(Number(produto.idProduto))) {
                        estoquePorId.set(Number(produto.idProduto), {
                          estoqueTotal: produto.estoqueTotal,
                          quantidadeConsumidaPorUnico: new Map<number, number>(),
                        });
                      }
                    });

                    filteredProdutos.forEach((produto) => {
                      const estoqueInfo = estoquePorId.get(Number(produto.idProduto));

                      if (estoqueInfo) {
                        const quantidadeAtual =
                          estoqueInfo.quantidadeConsumidaPorUnico.get(produto.idUnico) || 0;
                        estoqueInfo.quantidadeConsumidaPorUnico.set(
                          produto.idUnico,
                          quantidadeAtual + Number(produto.quantidade)
                        );

                        estoqueInfo.quantidadeConsumida = Array.from(
                          estoqueInfo.quantidadeConsumidaPorUnico.values()
                        ).reduce((acc: number, qtd: number) => acc + qtd, 0);
                      }
                    });

                    return filteredProdutos
                      .filter((infoProd) => {
                        const estoqueInfoAtual = estoquePorId.get(Number(infoProd.idProduto));
                        const estoqueRestante = estoqueInfoAtual
                          ? estoqueInfoAtual.estoqueTotal -
                          (estoqueInfoAtual.quantidadeConsumida ?? 0)
                          : 0;
                        const isEstoqueInsuficiente = estoqueRestante < 0;

                        if (mostrarSemEstoque) {
                          return isEstoqueInsuficiente;
                        }

                        return true;
                      })
                      .map((infoProd, index) => {
                        const estoqueInfoAtual = estoquePorId.get(Number(infoProd.idProduto));
                        const estoqueRestante = estoqueInfoAtual
                          ? estoqueInfoAtual.estoqueTotal -
                          (estoqueInfoAtual.quantidadeConsumida ?? 0)
                          : 0;
                        const isEstoqueInsuficiente = estoqueRestante < 0;
                        const quantidadeExcedente = Math.max(0, -estoqueRestante);

                        return (
                          <tr
                            key={index}
                            style={{
                              color: isEstoqueInsuficiente ? 'red' : 'inherit',
                              // cursor: 'pointer',
                              padding: '0 1rem',
                            }}
                          // draggable
                          // onDragStart={() => (dragProduto.current = index)}
                          // onDragEnter={() => (draggedOverProduto.current = index)}
                          // onDragEnd={handleSort}
                          // onDragOver={(e) => e.preventDefault()}
                          // onTouchStart={() => handleTouchStart(index)}
                          // onTouchMove={() => handleTouchMove(index)}
                          // onTouchEnd={handleTouchEnd}
                          >
                            <td
                              className='bi bi-grip-horizontal text-center'
                              style={{ color: '#86888a', fontSize: '16px' }}
                            ></td>
                            <td className='text-center'>{infoProd.ordem ?? infoProd.idUnico}</td>
                            <td className='text-center'>{infoProd.idProduto}</td>
                            <td className='text-center'>
                              <div>{infoProd.produto}</div>
                              <div>{formatObs(infoProd.obsproduto)}</div>
                            </td>
                            <td className='text-center'>{infoProd.quantidade}</td>
                            <td className='text-center'>
                              <div style={{ border: '1px solid grey' }}>
                                {isEstoqueInsuficiente ? (
                                  <div style={{ color: 'red', margin: '2px' }}>
                                    Estoque Insuficiente
                                    <Badge bg='info' style={{ color: 'white' }}>
                                      Excedente: {quantidadeExcedente}
                                    </Badge>
                                  </div>
                                ) : infoProd.estoque && infoProd.estoque.length > 0 ? (
                                  infoProd.estoque.map((estoque, index) => (
                                    <div key={index}>{formatarEstoque(estoque)}</div>
                                  ))
                                ) : (
                                  <div>Nenhum dado de estoque encontrado</div>
                                )}
                              </div>
                            </td>
                            <td className='text-center'>
                              {fomatarPrecoUnitario(infoProd.precoUnitario)}
                            </td>
                            <td className='text-center'>
                              {(infoProd.descontoProduto ?? 0).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              %
                            </td>
                            <td className='text-center'>
                              {formatarPrecoDesconto(
                                infoProd.precoUnitario,
                                infoProd.descontoProduto
                              )}
                            </td>
                            <td className='text-center'>{formatarPreco(infoProd.total)}</td>
                            <td className='text-center'>
                              {/* {infoProd.ativaMargem === 1 */}
                              {/* // ? $ */}
                              {calculoMargem(
                                infoProd.total,
                                infoProd.precoCusto,
                                infoProd.quantidade
                              ) !== null
                                ? calculoMargem(
                                  infoProd.total,
                                  infoProd.precoCusto,
                                  infoProd.quantidade
                                )?.toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : '0.00'}
                              %{/* // : 0} */}
                            </td>
                            <td className='text-center'>
                              {infoProd.tipoEntregaDesc} <br />
                              <br />
                              {infoProd.descloja && (
                                <Badge bg='secondary'>{infoProd.descloja}</Badge>
                              )}
                            </td>
                            {infoProd.ambienteDesc ? (
                              <td className='text-center'>
                                {removePrefix(infoProd.ambienteDesc)}
                                <br />-
                                <br />
                                {infoProd.ambienteComplemento}
                              </td>
                            ) : (
                              <th className='text-center'> - </th>
                            )}

                            <td
                              className='text-center'
                              data-toggle='tooltip'
                              data-placement='top'
                              title={
                                totalGeral > 0 && valorAReceber <= 0
                                  ? 'Não é possível editar, para executar essa ação é necessário remover o pagamento principal.'
                                  : ' '
                              }
                            >
                              <div className='dropdown position-static'>
                                <button
                                  className='btn btn-success btn-sm dropdown-toggle'
                                  data-bs-toggle='dropdown'
                                >
                                  Opções
                                </button>
                                <ul
                                  className='dropdown-menu'
                                  style={{
                                    position: 'absolute',
                                    zIndex: 50,
                                    top: '100%',
                                    left: '0',
                                  }}
                                >
                                  <li
                                    style={{
                                      cursor:
                                        totalGeral > 0 && valorAReceber <= 0
                                          ? 'not-allowed'
                                          : 'pointer',
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: 'orange',
                                        fontWeight: '600',
                                        transition: '0.3s',
                                      }}
                                      type='button'
                                      disabled={totalGeral > 0 && valorAReceber <= 0}
                                      className='dropdown-item'
                                      onClick={(e) => onEditProduct(e, infoProd)}
                                    >
                                      EDITAR
                                    </button>
                                  </li>
                                  <li
                                    style={{
                                      cursor:
                                        totalGeral > 0 && valorAReceber <= 0
                                          ? 'not-allowed'
                                          : 'pointer',
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: 'red',
                                        fontWeight: '600',
                                        transition: '0.3s',
                                      }}
                                      type='button'
                                      disabled={totalGeral > 0 && valorAReceber <= 0}
                                      className='dropdown-item'
                                      onClick={() => handleRemoveProduct(infoProd.ordem)}
                                    >
                                      EXCLUIR
                                    </button>
                                  </li>
                                  <li>
                                    <hr className='dropdown-divider' />
                                  </li>
                                  <li
                                    style={{
                                      cursor:
                                        totalGeral > 0 && valorAReceber <= 0
                                          ? 'not-allowed'
                                          : 'pointer',
                                    }}
                                  >
                                    <button
                                      style={{
                                        fontWeight: '600',
                                        transition: '0.3s',
                                      }}
                                      type='button'
                                      disabled={totalGeral > 0 && valorAReceber <= 0}
                                      className='dropdown-item'
                                      onClick={(e) => handleChangeSKU(e, infoProd)}
                                    >
                                      TROCAR SKU
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                  })()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ISkuResumo>, index: number) => (
                  <th
                    style={{ marginRight: '10px', paddingRight: '10px' }}
                    key={column.id}
                    className={index === 1 ? 'text-start' : 'text-end'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  const isRowRed =
                    row.original.quantidade == null &&
                    row.original.quantidadeParcialSku == null &&
                    row.original.saldo == null &&
                    row.original.totalEstoque == null;

                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        color: isRowRed ? 'red' : 'inherit',
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className='text-end'>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
