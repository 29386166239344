import clsx from 'clsx';
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers';
import {HeaderNotificationsMenu, HeaderUserMenu, Search} from '../../../partials';
import {useLayout} from '../../core';
import {useEffect, useState, ReactNode} from 'react';
import {Modal, Button} from 'react-bootstrap';
import './Navbar.scss';
import {useMessages} from '../../../../app/modules/auth/core/MessagesContextProvider';
import axios from 'axios';
import {Navigate, useNavigate} from 'react-router-dom';
import {Cache} from '../../../../app/shared/core';

const itemClass = 'ms-1 ms-md-4';
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px';
const userAvatarClass = 'symbol-35px';
const btnIconClass = 'fs-2';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;

const Navbar = () => {
  const navigate = useNavigate();
  const notification = Cache.get({key: 'unreadMessages'});
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState<
    {
      nomeUsuario: ReactNode;
      idUsuario: ReactNode;
      id: number;
      mensagem: string;
      headerlocation: string;
    }[]
  >([]);
  const [animateBell, setAnimateBell] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [isCountNotification, setIsCountNotification] = useState<number>(4);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {messages, fetchMessages} = useMessages();

  useEffect(() => {
    const checkForChanges = () => {
      const storedMessages = localStorage.getItem('unreadMessages');
      if (storedMessages) {
        const parsedMessages = JSON.parse(storedMessages);
        if (parsedMessages.content && parsedMessages.content.length > 0) {
          setNotifications(parsedMessages.content);
          setHasNotifications(true);
        } else {
          setHasNotifications(false);
        }
      }
    };

    checkForChanges();
    const changeIntervalId = setInterval(checkForChanges, 5000);

    return () => clearInterval(changeIntervalId);
  }, []);

  useEffect(() => {
    if (hasNotifications) {
      setAnimateBell(true);
    } else {
      setAnimateBell(false);
    }
  }, [hasNotifications]);

  useEffect(() => {
    const intervalId = setInterval(fetchMessages, 100000);
    return () => clearInterval(intervalId);
  }, [fetchMessages]);

  const handleNotificationClick = () => {
    handleShow();
  };

  const handleAccept = async (id) => {
    try {
      handleClose();

      navigate('/workflow-pendencia-listagem');

      await axios.put(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/mensagens/${id}/marcar-como-lida`
      );

      const storedMessages = localStorage.getItem('unreadMessages');
      if (storedMessages) {
        const parsedMessages = JSON.parse(storedMessages);

        const updatedContent = parsedMessages.content.filter((msg) => msg.id !== id);

        const updatedMessages = {
          ...parsedMessages,
          content: updatedContent,
        };

        localStorage.setItem('unreadMessages', JSON.stringify(updatedMessages));
        setNotifications(updatedContent);
      }
    } catch (error) {
      console.error('Erro ao marcar a mensagem como lida:', error);
    }
  };

  useEffect(() => {
    if(notification){
      setIsCountNotification(notification.content.length);
    }
  }, [hasNotifications, notification]);

  setTimeout(() => {}, 300);

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='app-navbar flex-shrink-0'>
          <div
            className={`bell-icon ${animateBell ? 'animate-bell' : ''}`}
            onClick={handleNotificationClick}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <a
              href='#'
              className='btn btn-icon pulse pulse-danger me-10 mb-10 a-noti'
              style={{margin: '0px', position: 'relative'}}
            >
              {animateBell ? (
                <div className='container-notification'>
                  <span className='notification-count'>{isCountNotification}</span>
                </div>
              ) : (
                <></>
              )}
              <i className={`bi bi-bell-fill fs-4 ${animateBell ? 'icon-danger' : ''}`}></i>
              {animateBell ? <span className='pulse-ring'></span> : <></>}
            </a>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Notificações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {notifications.length > 0 ? (
              <>
                {notifications.length > 1 ? (
                  <p>Você tem {notifications.length} novas mensagens.</p>
                ) : (
                  <p>Você tem {notifications.length} nova mensagem.</p>
                )}
                {notifications.map((notification) => (
                  <div className='notification-item' key={notification.id}>
                    <div className='notification-item'>
                      <div className='d-flex gap-3 px-3 py-2 border-bottom'>
                        <div className='position-relative'>
                          <img
                            alt=''
                            className='rounded-circle'
                            src={`${REACT_APP_IMG_URL}/avatars/-1.jpg`}
                            style={{width: '40px', height: '40px'}}
                          />
                          <span
                            className='badge bg-secondary position-absolute top-0 end-0 translate-middle'
                            style={{width: '20px', height: '20px', fontSize: '12px'}}
                          ></span>
                        </div>
                        <div className='d-flex flex-column flex-grow-1'>
                          <div className='mb-1'>
                            <a href='#' className='text-dark fw-bold'>
                              {notification.nomeUsuario}
                            </a>
                            <span className='text-muted'> {notification.mensagem} </span>
                          </div>
                          <div className='mt-4'>
                            <Button
                              variant='dark'
                              className='btn-sm'
                              onClick={() => handleAccept(notification.id)}
                            >
                              Ciente
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p>Não há notificações.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement="{'lg': 'bottom-end', 'sm': 'bottom-start'}"
        >
          <img src={`${REACT_APP_IMG_URL}/avatars/-1.jpg`} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export {Navbar};
