import {useNavigate, useParams} from 'react-router-dom';
import {
  AsyncSelectPrevenda,
  DescriptionComponent,
  FooterForm,
  ILogisticaFluxo,
  ISelectOption,
  ITipoEntrega,
} from '../../../shared';
import {Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import {
  editSeparacaoLogistica,
  getSeparacaoLogisticaById,
  sendSeparacaoLogistica,
} from '../cadastro_requests/cadastro_requests';
import SeparacaoItemTable from './components/separacao-items-table/separacao-items-table';

const LogisticaSeparacaoCadastroPage: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {id} = useParams();
  const [isIdPrevenda, setIsIdPrevenda] = useState<string | number>('');
  const [selectPrevenda, setSelectPrevenda] = useState<ISelectOption | null>(null);
  const [isQuantidadeItemsSeparacao, setIsQuantidadeItemsSeparacao] = useState<number | string>(0);
  const [selectFluxo, setSelectFluxo] = useState<ISelectOption | null>(null);
  const [isTipoEntrega, setIsTipoEntrega] = useState<ISelectOption | null>(null);
  const [isSeqEntrega, setIsSeqEntrega] = useState<string | string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues = {
    idPrevenda: 0,
    idLogisticaFluxo: 0,
    status: 'ABERTO',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      if (id) {
        setIsLoading(true);

        try {
          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar a Separação?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await editSeparacaoLogistica(id, {
              ...values,
              idLogisticaFluxo: selectFluxo?.value,
              idPrevenda: selectPrevenda?.value,
              status: String(values.status),
              tipoEntregaId: isTipoEntrega?.value,
            });

            Swal.fire({
              icon: 'success',
              title: 'Separação editada com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ir para Listagem de Separação de Logística',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/logistica-separacao-listagem');
              },
            });
          }
          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        if (!selectFluxo || !selectPrevenda) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: !selectFluxo
              ? 'A inserção do fluxo logístico é obrigatória'
              : 'A inserção do pedido é obrigatória',
          });
          setSubmitting(false);
          setIsLoading(false);
          return;
        }

        try {
          const response = await sendSeparacaoLogistica({
            ...values,
            idLogisticaFluxo: selectFluxo?.value,
            idPrevenda: selectPrevenda?.value,
            tipoEntregaId: isTipoEntrega?.value,
          });

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Seperação cadastrada com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ir para listagem de separacao logistica',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/logistica-separacao-listagem');
              },
            });
          }
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    getSeparacaoById();
  }, []);

  const loadLogisticaFluxo = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ILogisticaFluxo) => ({
        value: item.id,
        label: item.titulo,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadTipoEntrega = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: ITipoEntrega) => ({
        value: item.idTipoEntrega,
        label: item.tipoEntrega,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadLogisticaFluxoByid = async (id: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setSelectFluxo({label: data.titulo, value: data.id});

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadTipoEntregaById = async (id: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/tipoEntregaPorId/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsTipoEntrega({label: data.tipoEntrega, value: data.idTipoEntrega});

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const handleAsyncPrevenda = (selectedOption: ISelectOption) => {
    setSelectPrevenda(selectedOption);
    formik.setFieldValue('idPrevenda', selectedOption.value); // Atualiza o valor do campo idPrevenda
    formik.setFieldTouched('idPrevenda', true); // Marca o campo como tocado para exibir erros
  };

  const getSeparacaoById = async () => {
    if (id) {
      try {
        const response = await getSeparacaoLogisticaById(id);

        const {data} = response;

        formik.setValues({
          idLogisticaFluxo: data.idlogisticaFluxo,
          idPrevenda: data.idPrevenda,
          status: data.status,
        });

        setIsIdPrevenda(data.idPrevenda);
        loadLogisticaFluxoByid(data.idLogisticaFluxo);
        setIsQuantidadeItemsSeparacao(data.qtdItems);
        loadTipoEntregaById(data.tipoEntregaId);
        setIsSeqEntrega(data.seqEntrega);

        setSelectPrevenda({label: '', value: data.idPrevenda});
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  const [idParam, setIdParam] = useState<string | number | null>(null);

  const [isSeqEntregaBoolean, setIsSeqEntregaBoolean] = useState<boolean>(false);

  //Verificação para null states e button disable se id param inexistente
  useEffect(() => {
    if (isSeqEntrega) {
      setIsSeqEntregaBoolean(true);
    } else {
      setIsSeqEntregaBoolean(false);
    }
    if (!id) {
      setSelectFluxo(null);
      setIsTipoEntrega(null);
      setSelectPrevenda(null);
    }
  }, [id, isSeqEntrega]);

  useEffect(() => {
    getSeparacaoById();
  }, []);

  useEffect(() => {
    if (id) {
      setIdParam(id);
    } else {
      setIdParam(null);
    }

    console.log('id param: ', id);
    console.log('id param state: ', idParam);
  }, [id]);

  return (
    <>
      <DescriptionComponent
        description={id ? 'Edição de Separação Logística' : 'Cadastro de Separação Logística'}
        buttonTitle={'Listagem de Logística de Separação'}
        redirectTo='/logistica-separacao-listagem'
        withLink
      />

      <form action='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row className='mb-12'>
          <Col md={4} className='col-12 mt-4'>
            <label className='form-label'>Status:</label>
            <select
              {...formik.getFieldProps('status')}
              className={`form-control ${
                formik.touched.status && formik.errors.status ? 'is-invalid' : ''
              }`}
            >
              <option value='CANCELADO'> CANCELADO </option>
              <option value='ABERTO'> ABERTO </option>
              <option value='CONCLUIDO'> CONCLUÍDO </option>
            </select>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
          <Col md='4' className='col-12 mt-4'>
            <label htmlFor='' className='form-label'>
              Selecione o Fluxo Logístico:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Fluxo Logístico'
              loadOptions={loadLogisticaFluxo}
              value={selectFluxo}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setSelectFluxo(selectedOption);
                } else {
                  setSelectFluxo(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
            {formik.touched.idLogisticaFluxo && formik.errors.idLogisticaFluxo ? (
              <div className='invalid-feedback'>{formik.errors.idLogisticaFluxo}</div>
            ) : null}
          </Col>
          <Col md='4' className='col-12 mt-4'>
            <label htmlFor='' className='form-label'>
              Selecione o Tipo de Entrega:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o tipo de Entrega'
              loadOptions={loadTipoEntrega}
              value={isTipoEntrega}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setIsTipoEntrega(selectedOption);
                } else {
                  setIsTipoEntrega(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
            {formik.touched.idLogisticaFluxo && formik.errors.idLogisticaFluxo ? (
              <div className='invalid-feedback'>{formik.errors.idLogisticaFluxo}</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md='12' className='col-12 mt-4'>
            <label htmlFor='' className='form-label'>
              Selecione o Pedido:
            </label>
            <AsyncSelectPrevenda
              handleOptions={(option) => handleAsyncPrevenda(option!)}
              inputOption={selectPrevenda}
            />
            {formik.touched.idPrevenda && formik.errors.idPrevenda ? (
              <div className='invalid-feedback'>{formik.errors.idPrevenda}</div>
            ) : null}
          </Col>
        </Row>

        <FooterForm
          textActionSubmit={id ? 'Editar Separação' : 'Cadastrar'}
          redirectTo='logistica-separacao-listagem'
          isLoading={isLoading}
          isDisabled={isSeqEntregaBoolean && !!id}
          showTooltip
          tooltipText='Não é possível realizar esta ação porque a separação foi enviada para logística.'
        />
      </form>

      {id && (
        <>
          <SeparacaoItemTable
            idSeparacao={id}
            idPrevenda={isIdPrevenda}
            qtdItems={isQuantidadeItemsSeparacao && id}
            isSeqEntregaP={isSeqEntrega}
          />
        </>
      )}
    </>
  );
};

export default LogisticaSeparacaoCadastroPage;
