import React, {useState, useEffect} from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {Badge} from 'react-bootstrap'

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

interface Empresa {
  id: number
  fantasia: string
  cnpj: string
  email: string
  telefone: string
  codigo: number
  status: number
}

export function ListagemEmpresa() {
  const [empresas, setEmpresas] = useState<Empresa[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFiltro, setSearchFiltro] = useState('todos')

  useEffect(() => {
    setLoading(true)
    fetchEmpresas()
  }, [])

  const fetchEmpresas = async (search = '', filtro = '') => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas`,
        {
          params: {
            search: search,
            filtro: filtro,
          },
        }
      )

      if (response.status === 200) {
        setEmpresas(response.data.content)
      } else if (response.status === 204) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
          },
        })
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Empresas para essa pesquisa',
        })
        setEmpresas([])
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar empresas',
        text: 'Ocorreu um erro ao carregar as empresas. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (event) => {
    event.preventDefault()
    fetchEmpresas(searchTerm, searchFiltro)
  }

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>
      default:
        return <Badge bg='light'>N/A</Badge>
    }
  }

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Empresas</h1>
        <Link
          to='/cadastro-empresas'
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Nova Empresa
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row mt-8 gap-4'>
            <div className='col-sm-3'>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecione</option>
                <option value='nome'>Nome/Fantasia</option>
                <option value='cnpj'>CNPJ</option>
                <option value='codigo'>Código</option>
              </select>
            </div>
            <div className='col-sm-3'>
              <input
                type='text'
                placeholder='Pesquisar empresa'
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th>Id</th>
                <th>Nome/Fantasia</th>
                <th>CNPJ</th>
                <th>Email</th>
                <th>Código</th>
                <th>Status</th>
                <th className='text-center'>Editar</th>
              </tr>
            </thead>
            <tbody>
              {empresas.map((empresa) => (
                <tr key={empresa.id}>
                  <td>{empresa.id}</td>
                  <td>{empresa.fantasia}</td>
                  <td>{empresa.cnpj || 'N/A'}</td>
                  <td>{empresa.email || 'N/A'}</td>
                  <td>{empresa.codigo || 'N/A'}</td>
                  <td>{formatStatus(empresa.status) || 'N/A'}</td>
                  <td className='text-center'>
                    <Link to={`/editar-empresa/${empresa.id}`} className='btn btn-success btn-sm'>
                      Editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
